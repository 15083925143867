import { ChangeEventHandler, FC, useMemo } from "react";

import { GiAmericanFootballHelmet } from "react-icons/gi";

import { AdElementTeamAsset, stringIsOfUnionType } from "@adflow/types";
import { Box, Flex, Heading, Icon, Select, Text } from "@chakra-ui/react";
import { useStore } from "../../../hooks/useStore";
import { TeamAssetGlobalControls } from "./TeamAssetGlobalControls";
import DynamicContentTemplateInput from "../DynamicContentTemplateInput";

type Props = {
  selectedElement: AdElementTeamAsset;
};

const validValues = [
  "HOME_ASSET",
  "AWAY_ASSET",
  "COMPETITOR_ASSET",
  "OPPONENT_ASSET"
] as const;

const TeamAssetElementControls: FC<Props> = ({ selectedElement }) => {
  const { updateElementData, teamAssetsDisplay, dataSources } = useStore(
    state => ({
      dataSources: state.dataSources,
      updateElementData: state.updateElementData,
      teamAssetsDisplay: state.displayOptions.teamAssets
    })
  );

  const handleSourceChange: ChangeEventHandler<HTMLSelectElement> = evt => {
    const value = evt.target.value;

    if (stringIsOfUnionType(value, validValues)) {
      updateElementData(selectedElement.id, {
        ...selectedElement.data,
        source: value
      });
    }
  };

  const options = useMemo(() => {
    if (
      dataSources.some(ds => ds.type === "EVENT" || ds.type === "NEW_EVENT")
    ) {
      return [
        { value: "HOME_ASSET", label: "Home Asset" },
        { value: "AWAY_ASSET", label: "Away Asset" }
      ];
    } else if (dataSources.some(ds => ds.type === "EVENT_COMPETITOR_FOCUSED")) {
      return [
        { value: "HOME_ASSET", label: "Home Asset" },
        { value: "AWAY_ASSET", label: "Away Asset" },
        { value: "COMPETITOR_ASSET", label: "Competitor Asset" },
        { value: "OPPONENT_ASSET", label: "Opponent Asset" }
      ];
    }
    return [
      { value: "HOME_ASSET", label: "Home Asset" },
      { value: "AWAY_ASSET", label: "Away Asset" }
    ];
  }, [dataSources]);

  return (
    <Flex direction='column'>
      <Flex alignItems='center' mb='6'>
        <Icon as={GiAmericanFootballHelmet} fontSize='xl' mr='2' />
        <Heading as='h2' size='md' noOfLines={1}>
          {selectedElement.name}
        </Heading>
      </Flex>
      <Heading fontSize='lg' fontWeight='semibold' mb='2'>
        Source
      </Heading>
      <Select
        placeholder='Select option'
        value={selectedElement.data.source ?? ""}
        onChange={handleSourceChange}
      >
        {options.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Select>
      {teamAssetsDisplay.type === "CUSTOM" && (
        <Box mt={4}>
          <DynamicContentTemplateInput selectedElement={selectedElement} />
          <Text mt={2} fontStyle={"italic"} fontSize={"sm"}>
            Provide a template string for your custom operator assets. E.g.
            sportradar/&#123;COMPETITOR_ID&#125;_left
          </Text>
        </Box>
      )}
      <TeamAssetGlobalControls />
    </Flex>
  );
};

TeamAssetElementControls.displayName = "TeamAssetElementControls";

export default TeamAssetElementControls;
