import { FC } from "react";

type Props = object;

const EditorSidebarView: FC<Props> = () => {
  return <>Hello</>;
};

EditorSidebarView.displayName = "EditorSidebarView";

export default EditorSidebarView;
