import { FC } from "react";

import { LuImage } from "react-icons/lu";

import { AdElementImage } from "@adflow/types";
import { Flex, Heading, Icon, Text } from "@chakra-ui/react";
import DynamicContentTemplateInput from "./DynamicContentTemplateInput";
import { useFlag } from "@unleash/proxy-client-react";
type Props = {
  selectedElement: AdElementImage;
};

const ImageElementControls: FC<Props> = ({ selectedElement }) => {
  return (
    <Flex direction='column'>
      <Flex alignItems='center' mb='6'>
        <Icon as={LuImage} fontSize='xl' mr='2' />
        <Heading as='h2' size='md' noOfLines={1}>
          {selectedElement.name}
        </Heading>
      </Flex>
      <DynamicContentTemplateInput selectedElement={selectedElement} />
      <Text mt={2} fontStyle={"italic"} fontSize={"sm"}>
        Provide a link to the image you want to display in the ad. You can use
        the dynamic content template to insert dynamic data into the image URL.
        For example:
        https://cdn.fresh8.co/assets/operator-jerseys/tipico-us/&#123;FOCUS_COMPETITOR_ID-CF&#125;_Left.png
      </Text>
    </Flex>
  );
};

ImageElementControls.displayName = "ImageElementControls";

export default ImageElementControls;
