import { InMemoryCache, ApolloClient } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client"; // tsconfig was already updated to deal with this error
import Cookies from "js-cookie";
import env from "./BrandKit/utils/env";

const link = createUploadLink({
  uri: env.VITE_CREATIVE_HUB_API_URL,
  headers: {
    Authorization: `bearer ${Cookies.get("token")} paid-social:test:read`,
    "Apollo-Require-Preflight": "true"
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link
});

export default client;
