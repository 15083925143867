import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure
} from "@chakra-ui/react";
import { FC, Ref } from "react";

export interface DeleteConfirmationProps {
  onConfirm: () => void;
  confirmationText: string;
  TriggerTarget: FC<{ onClick: () => void }>;
}

const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
  onConfirm,
  confirmationText,
  TriggerTarget
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onToggle}
      onClose={onClose}
      placement='right'
    >
      <PopoverTrigger>
        <TriggerTarget onClick={onToggle} />
      </PopoverTrigger>
      <DeleteConfirmationPopoverContent
        onClose={onClose}
        onConfirm={onConfirm}
        confirmationText={confirmationText}
      />
    </Popover>
  );
};

export interface DeleteConfirmationPopoverContentProps {
  confirmationText: string;
  onClose: () => void;
  onConfirm: () => void;
  initialFocusRef?: Ref<HTMLButtonElement>;
}

export const DeleteConfirmationPopoverContent: FC<
  DeleteConfirmationPopoverContentProps
> = ({ confirmationText, onClose, onConfirm, initialFocusRef }) => (
  <Portal>
    <PopoverContent data-testid='deleteConfirmation'>
      <PopoverHeader fontWeight='semibold' color='black'>
        Confirmation
      </PopoverHeader>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverBody color='black'>{confirmationText}</PopoverBody>
      <PopoverFooter display='flex' justifyContent='flex-end'>
        <ButtonGroup size='sm'>
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button
            ref={initialFocusRef}
            colorScheme='red'
            onClick={onConfirm}
            data-testid='confirmDeleteElement'
          >
            Delete
          </Button>
        </ButtonGroup>
      </PopoverFooter>
    </PopoverContent>
  </Portal>
);

DeleteConfirmation.displayName = "DeleteConfirmation";

export default DeleteConfirmation;
