import {
  IMarketingCloudUser,
  SportradarEnforcer,
  SportradarEnforcerFactory,
  ActionValidationMiddleware,
  ACTION_NAME
} from "@ads/iam-library";

let cachedEnforcer: SportradarEnforcer;

export async function canCreate(user: IMarketingCloudUser): Promise<boolean> {
  const actionValidator = await createActionValidator(user);

  return await actionValidator.validateAction({
    actionName: ACTION_NAME.CREATE,
    domainName: "creative-hub",
    resourceType: "",
    userId: user.getCognitoId()
  });
}

export async function canEdit(user: IMarketingCloudUser): Promise<boolean> {
  const actionValidator = await createActionValidator(user);

  return await actionValidator.validateAction({
    actionName: ACTION_NAME.EDIT,
    domainName: "creative-hub",
    resourceType: "",
    userId: user.getCognitoId()
  });
}

export async function createActionValidator(
  user: IMarketingCloudUser
): Promise<ActionValidationMiddleware> {
  const enforcer = await getCachedEnforcer(user);
  return new ActionValidationMiddleware(enforcer);
}

export async function getCachedEnforcer(
  user: IMarketingCloudUser
): Promise<SportradarEnforcer> {
  if (cachedEnforcer) {
    return cachedEnforcer;
  }
  cachedEnforcer =
    await new SportradarEnforcerFactory().createFromMarketingCloudUser(user);
  return cachedEnforcer;
}
