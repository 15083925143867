import { Box } from "@mui/material";
import React from "react";

function usePreviewFont(family: string, src: File | string, variant?: string) {
  const fontNameWithFamily = variant ? `${family}-${variant}` : family;

  React.useEffect(() => {
    let fontface: FontFace;

    async function load() {
      const font =
        typeof src === "string" ? `url(${src})` : await src.arrayBuffer();

      fontface = new FontFace(`preview ${fontNameWithFamily}`, font);

      document.fonts.add(fontface);

      fontface?.load();
    }

    load();

    return () => {
      if (fontface) {
        document.fonts.delete(fontface);
      }
    };
  }, [family, fontNameWithFamily, src, variant]);

  return { family: fontNameWithFamily };
}

export function FontPreviewBox(props: { fontFile: File }) {
  const { family } = usePreviewFont(
    props.fontFile.name.split(".")[0],
    props.fontFile
  );

  return (
    <Box
      data-testid={`${family}-preview`}
      sx={{
        boxSizing: "border-box",
        width: "64px",
        justifySelf: "center",
        alignSelf: "center",
        fontFamily: `preview ${family}`,
        fontSize: "30px",
        backgroundColor: "#D9D9D9",
        padding: "10px",
        aspectRatio: "1/1",
        textAlign: "center"
      }}
    >
      Aa
    </Box>
  );
}

export function FontPreviewInline(props: {
  family: string;
  variant: string;
  src: string;
}) {
  const { family } = usePreviewFont(props.family, props.src, props.variant);

  return (
    <Box
      data-testid={`${props.family}-${props.variant}-preview`}
      sx={{
        fontSize: "14px",
        padding: "4px 6px",
        borderRadius: "4px",
        maxWidth: "150px",
        textAlign: "center",
        backgroundColor: "#D9D9D9",
        fontFamily: `preview ${family}`
      }}
    >
      {props.variant}
    </Box>
  );
}
