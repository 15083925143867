import { FC, useMemo } from "react";

import { PiBoundingBox } from "react-icons/pi";

import { AdElementVector, AdElementVectorColor } from "@adflow/types";
import {
  Flex,
  Heading,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import VectorElementColorControl from "./VectorElementColorControl";
import { useStore } from "../../../hooks/useStore";

type Props = {
  selectedElement: AdElementVector;
};

const VectorElementControls: FC<Props> = ({ selectedElement }) => {
  const store = useStore(state => ({
    updateElementData: state.updateElementData,
    customFonts: state.customFonts
  }));

  const updateFillColor = (fill: AdElementVectorColor) => {
    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      color: {
        ...selectedElement.data.color,
        fill
      }
    });
  };

  const updateStrokeColor = (stroke: AdElementVectorColor) => {
    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      color: {
        ...selectedElement.data.color,
        stroke
      }
    });
  };

  const stroke = useMemo(() => {
    return selectedElement.data.color.stroke;
  }, [selectedElement]);

  const fill = useMemo(() => {
    return selectedElement.data.color.fill;
  }, [selectedElement]);

  const defaultIndex = useMemo(() => {
    if (!fill && stroke) {
      return 1;
    }
    return 0;
  }, [fill, stroke]);
  return (
    <Flex direction='column'>
      <Flex alignItems='center' mb='6'>
        <Icon as={PiBoundingBox} fontSize='xl' mr='2' />
        <Heading as='h2' size='md' noOfLines={1}>
          {selectedElement.name}
        </Heading>
      </Flex>
      <Tabs variant='enclosed' defaultIndex={defaultIndex}>
        <TabList>
          <Tab isDisabled={!fill}>Fill Color</Tab>
          <Tab isDisabled={!stroke}>Stroke Color</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {fill && (
              <VectorElementColorControl
                vectorColor={fill}
                onChange={updateFillColor}
              />
            )}
          </TabPanel>
          <TabPanel>
            {stroke && (
              <VectorElementColorControl
                vectorColor={stroke}
                onChange={updateStrokeColor}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

VectorElementControls.displayName = "VectorElementControls";

export default VectorElementControls;
