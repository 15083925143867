import { FC, PropsWithChildren } from "react";

import { createGenericContext } from "@adflow/utils";
import { Console, FontFace } from "@adflow/types";
import { ConsoleAPIGraph } from "@adflow/graphqlgen";

type APIClientContext = {
  listTeams: () => Promise<Array<Console.Entity>>;
  listTeamBrands: (teamId: string) => Promise<Array<Console.Entity>>;
  listTeamFonts: ({
    team
  }: {
    team: string;
  }) => Promise<{ fontFaces: Array<FontFace> }>;
  getBrand: (
    brandId: string
  ) => Promise<ConsoleAPIGraph.GetBrandQuery["brand"]>;
};

const [useAPIClient, APIClientContextProvider] =
  createGenericContext<APIClientContext | null>();

const Provider: FC<PropsWithChildren<APIClientContext>> = ({
  children,
  ...value
}) => {
  return (
    <APIClientContextProvider value={value}>
      {children}
    </APIClientContextProvider>
  );
};

export { Provider, useAPIClient };
export type { APIClientContext as APIClient };
