import { FC } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import Button from "../ThemedButton";

export interface SaveConfirmationProps {
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export const SaveConfirmation: FC<SaveConfirmationProps> = ({
  onConfirm,
  isOpen,
  onClose,
  isLoading
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid='confirmSave'>
        <ModalHeader>Confirm Save</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to save and redirect to the index page?
        </ModalBody>
        <ModalFooter>
          <Button
            testId='cancelButton'
            muiButtonProps={{
              onClick: onClose,
              color: "secondary",
              sx: { mr: "1rem" }
            }}
          >
            Cancel
          </Button>
          <Button
            testId='confirmButton'
            isLoading={isLoading}
            muiButtonProps={{
              color: "primary",
              onClick: onConfirm,
              sx: { minHeight: "36.5px" }
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
