import { FC, useCallback, useEffect, useRef, useState } from "react";

import { LuPencil } from "react-icons/lu";

import {
  Box,
  chakra,
  Flex,
  Icon,
  IconButton,
  Input,
  Text,
  Tooltip,
  useOutsideClick
} from "@chakra-ui/react";

interface Props {
  name: string;
  setName: (name: string) => void;
  bgColour: string;
  setBgColour: (colour: string) => void;
}

const EditableHeading: FC<Props> = ({
  name,
  setName,
  bgColour,
  setBgColour
}) => {
  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);

  const editModeOn = useCallback(() => {
    setIsEditing(true);
  }, []);

  const editModeOff = useCallback(() => {
    setIsEditing(false);
  }, []);

  const toggleEditMode = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  useOutsideClick({
    ref: ref,
    handler: editModeOff
  });

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === name) {
        return;
      }
      if (
        event.target.value === "New Ad Template" ||
        event.target.value === ""
      ) {
        setBgColour("red.100");
      } else {
        setBgColour("");
      }
      setName(event.target.value);
    },
    [setBgColour, name, setName]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== "Enter") {
        return;
      }
      setIsEditing(false);
    },
    []
  );

  const handleBlur = useCallback(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <Tooltip label={"rename"} placement='bottom'>
      <Flex
        ref={ref}
        maxW='20vw'
        overflow='hidden'
        alignItems='center'
        borderRadius={3}
        backgroundColor={bgColour}
      >
        {isEditing ? (
          <Box>
            <Input
              id='editable-heading-input'
              data-testid='templateNameInput'
              ref={inputRef}
              minW={0}
              px={2}
              py={0}
              minWidth='0'
              fontSize='2xl'
              fontWeight='bold'
              fontFamily='heading'
              tabIndex={0}
              value={name}
              placeholder={"New Ad Template"}
              onKeyDown={handleKeyDown}
              onChange={handleNameChange}
              onBlur={handleBlur}
            />
          </Box>
        ) : (
          <Text
            onClick={editModeOn}
            data-testid='updatedName'
            px='2'
            fontSize='2xl'
            fontWeight='bold'
            fontFamily='heading'
            maxW='100%'
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
          >
            {name || "New Ad Template"}
          </Text>
        )}
        {
          <IconButton
            onClick={toggleEditMode}
            data-testid='updateTemplateName'
            ml='1'
            size='sm'
            aria-label='Enter a template name'
            icon={<Icon as={LuPencil} />}
            backgroundColor={bgColour}
          />
        }
      </Flex>
    </Tooltip>
  );
};

export default chakra(EditableHeading);
