import getConfigMen from "./getConfigMen";
import getConfigWomen from "./getConfigWomen";
import { Hex, LayerId, Type, Gender, Layers } from "./types";

const DEFAULT_FILL = "ffffff";

const hex = (color: string): Hex => {
  if (!color) {
    return `#${DEFAULT_FILL}`;
  }
  if (color.startsWith("#")) {
    return `#${color.substring(1)}`;
  }
  return `#${color}`;
};

export const layers: Layers = [
  {
    id: "base"
  },
  {
    id: "sleevelong"
  },
  {
    id: "sleeve"
  },
  {
    id: "stripes"
  },
  {
    id: "horizontalstripes"
  },
  {
    id: "squares"
  },
  {
    id: "split"
  }
];

export default function getSvgParts({
  fill,
  type,
  gender,
  layer
}: {
  fill?: string;
  type?: Type;
  gender?: Gender;
  layer?: LayerId;
}): JSX.Element | undefined {
  if (!fill || !layer || !type || !gender) {
    return undefined;
  }

  const hexFill = hex(fill);
  const genderConfig =
    gender === "women" ? getConfigWomen(hexFill) : getConfigMen(hexFill);

  const configType = genderConfig[type];
  if (!configType) {
    return undefined;
  }
  return configType[layer];
}
