import { AdElementText } from "@adflow/types";
import { FormLabel, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useStore } from "../../../../hooks/useStore";

type Props = {
  textElement: AdElementText;
};

export const isDayPresent = (textElement: AdElementText) => {
  if (!textElement.sources[0]) return false;
  return textElement.data.content.includes(
    `${textElement.sources[0].sourceId}:DAY}`
  );
};

const DayFormatControls: FC<Props> = ({ textElement }) => {
  const s = useStore(state => ({
    displayOptions: state.displayOptions,
    setDisplayOptions: state.setDisplayOptions
  }));

  const handleUpdateDayFormat = useCallback(
    (value: string) => {
      let dayFormat = s.displayOptions.dateTimeFormat?.dayFormat;
      if (dayFormat == null) {
        dayFormat = "long";
      }

      if (value !== "short" && value !== "long") return;
      dayFormat = value;
      s.setDisplayOptions("dateTimeFormat", {
        ...s.displayOptions.dateTimeFormat,
        dayFormat: dayFormat
      });
      console.log(s.displayOptions.dateTimeFormat?.dayFormat);
    },
    [s]
  );

  return (
    <>
      <Stack direction='row' mb={2}>
        <FormLabel
          htmlFor='time-format'
          mb={"0"}
          style={{ fontVariant: "all-small-caps" }}
        >
          Day
        </FormLabel>
        <RadioGroup
          value={s.displayOptions.dateTimeFormat?.dayFormat || "long"}
          id='time-format'
          ml={"auto"}
          mr={0}
          onChange={handleUpdateDayFormat}
        >
          <Stack direction='row'>
            <Radio value='short'>Abbreviated</Radio>
            <Radio value='long'>Full</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </>
  );
};

DayFormatControls.displayName = "DayFormatControls";

export default DayFormatControls;
