import { AdElementText } from "@adflow/types";
import {
  Checkbox,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch
} from "@chakra-ui/react";
import { ChangeEvent, FC, useCallback } from "react";
import { useStore } from "../../../../hooks/useStore";

type Props = {
  textElement: AdElementText;
};

export const isTimePresent = (textElement: AdElementText) => {
  if (!textElement.sources[0]) return false;
  return textElement.data.content.includes(
    `${textElement.sources[0].sourceId}:TIME}`
  );
};

const TimeFormatControls: FC<Props> = ({ textElement }) => {
  const s = useStore(state => ({
    displayOptions: state.displayOptions,
    setDisplayOptions: state.setDisplayOptions
  }));

  const handleUpdateTimeFormat = useCallback(
    (value: string) => {
      let timeFormat = s.displayOptions.dateTimeFormat?.timeFormat;
      if (timeFormat == null) {
        timeFormat = "hour24";
      }

      if (value !== "hour12" && value !== "hour24") return;
      timeFormat = value;
      s.setDisplayOptions("dateTimeFormat", {
        ...s.displayOptions.dateTimeFormat,
        timeFormat: timeFormat
      });
    },
    [s]
  );

  const handleUpdateShowTimezone = useCallback(
    (enabled: boolean) => {
      s.setDisplayOptions("dateTimeFormat", {
        ...s.displayOptions.dateTimeFormat,
        showTimezone: enabled
      });
    },
    [s]
  );

  return (
    <>
      <FormLabel htmlFor='time-format' mb={"0"} fontWeight={"semibold"}>
        Time Format
      </FormLabel>
      <Stack direction='row' mb={2} justifyContent={"space-between"}>
        <RadioGroup
          value={s.displayOptions.dateTimeFormat?.timeFormat || "hour24"}
          id='time-format'
          onChange={handleUpdateTimeFormat}
        >
          <Stack direction='row'>
            <Radio value='hour12'>12-hour</Radio>
            <Radio value='hour24'>24-hour</Radio>
          </Stack>
        </RadioGroup>
        <Stack direction='row' alignItems='center'>
          <FormLabel fontWeight={"normal"} mb={"0"}>
            Include timezone?
          </FormLabel>
          <Switch
            isChecked={s.displayOptions.dateTimeFormat?.showTimezone || false}
            onChange={evt => handleUpdateShowTimezone(evt.target.checked)}
          />
        </Stack>
      </Stack>
    </>
  );
};

TimeFormatControls.displayName = "TimeFormatControls";

export default TimeFormatControls;
