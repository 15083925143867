import { Stack, Typography, Box } from "@mui/material";
import { BrandKitGQL } from "@adflow/graphqlgen";
import { FontsUploadForm } from "./fontsUploadForm";
import { FontsTable } from "./fontsTable";
import { useQuery } from "@apollo/client";

import usePermissionToUpload from "../../hooks/permissionToUpload";

export function FontLibrary() {
  const {hasCreatePermissions, isUploadToDefault} = usePermissionToUpload();

  const { loading, data, refetch } = useQuery(
    BrandKitGQL.GET_ALL_FONTS_BY_OWNER
  );

  const rows = data?.getFontFamiliesByOwner ?? [];
  return (
    <Box flex='1'>
      <Typography variant='h4' fontWeight={"bold"} marginBlockEnd={3}>
        Font Library
      </Typography>
      <Stack gap={2} marginBlockEnd={3}>
        <FontsTable rows={rows} loading={loading}/>
      </Stack>
      <FontsUploadForm onSave={refetch} allowed={hasCreatePermissions} isUploadToDefault={isUploadToDefault}/>
    </Box>
  );
}
