import { IMarketingCloudUser, MarketingCloudUser } from "@ads/iam-library";

export class User {
  private _authentication: null;

  private _cognitoId: null;

  private _holdingIds: number[];

  private _businessEntityIds: number[];

  private _roles: string[];

  private _policies: string[][];

  private _allowedApplications: string[];

  private _internalRole: null;

  canAccess(appName: string): boolean {
    return this._allowedApplications.includes(appName);
  }

  constructor(
    holdingIds: number[],
    businessEntityIds: number[],
    roles: string[],
    policies: string[][],
    allowedApplications: string[]
  ) {
    this._authentication = null;
    this._cognitoId = null;
    this._holdingIds = holdingIds;
    this._businessEntityIds = businessEntityIds;
    this._roles = roles;
    this._policies = policies;
    this._allowedApplications = allowedApplications;
    this._internalRole = null;
  }

  isAdmin(): boolean {
    return this._roles.includes("sr-admin");
  }

  getCognitoId(): string {
    return "";
  }

  getHoldingIds(): number[] {
    return this._holdingIds;
  }

  getBusinessEntityIds(): number[] {
    return this._businessEntityIds;
  }

  getRoles(): string[] {
    return this._roles;
  }

  getPolicies(): string[][] {
    return this._policies;
  }

  getAllowedApplications(): string[] {
    return this._allowedApplications;
  }

  getInternalRole(): unknown {
    return this._internalRole;
  }

  getId(): number {
    return 0;
  }

  getName(): string {
    return "";
  }

  getFamilyName(): string {
    return "";
  }

  getEmail(): string {
    return "";
  }
}

// This is for local development only and only if it is running outside Marketing Cloud

export function persistToSessionStorage(user: IMarketingCloudUser): void {
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function readFromSessionStorage(): IMarketingCloudUser | null {
  const user = sessionStorage.getItem("user");
  if (user) {
    const parsedUser = JSON.parse(user);
    return new MarketingCloudUser(parsedUser);
  }
  return null;
}

export function userToRequestContextAuthorizationHeader(
  user: IMarketingCloudUser
): string {
  const requestContext = {
    principalId: user.getCognitoId(),
    integrationLatency: 0,
    roles: JSON.stringify(user.getRoles()),
    policies: JSON.stringify(user.getPolicies()),
    holdings: JSON.stringify(user.getHoldingIds()),
    businessEntities: JSON.stringify(user.getBusinessEntityIds()),
    allowedApplications: JSON.stringify(user.getAllowedApplications())
  };
  return JSON.stringify(requestContext);
}
