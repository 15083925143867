import superjson from "superjson";
import Cookies from "js-cookie";
import type { AppRouter } from "@adflow/api";
import {
  CreateTRPCProxyClient,
  createTRPCProxyClient,
  httpBatchLink
} from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { APIClientContext as FigmaAPIClientContext } from "@adflow/figma";
import { APIClientContext as ShadowBrandAPIClientContext } from "@adflow/shadow-brand";
import { APIClientContext as MarketingCloudAPIClientContext } from "@adflow/marketing-cloud";
import { QueryClient } from "@tanstack/react-query";

import templateEnv from "./env";
import env from "../../env";
import { useState } from "react";

export const trpc = createTRPCReact<AppRouter>();

export function useTRPCReact(_requestContextAuthorizationHeader?: string) {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState<ReturnType<typeof trpc.createClient>>(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: `${templateEnv.apiURL}/trpc`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include"
            });
          },
          headers: () => {
            const headers: { [key: string]: string } = {
              Authorization: getAuthorizationHeader()
            };
            if (_requestContextAuthorizationHeader) {
              headers["X-Event-Request-Context-Authorizer"] =
                _requestContextAuthorizationHeader;
            }
            return headers;
          }
        })
      ]
    })
  );

  return { trpcClient, queryClient };
}

export class TRPCClient {
  private static _requestContextAuthorizationHeader = "";
  static setRequestContextAuthorizationHeader(header: string) {
    this._requestContextAuthorizationHeader = header;
  }

  static client: CreateTRPCProxyClient<AppRouter> =
    createTRPCProxyClient<AppRouter>({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: `${templateEnv.apiURL}/trpc`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include"
            });
          },
          headers: () => {
            const headers: { [key: string]: string } = {
              Authorization: getAuthorizationHeader()
            };
            if (env.localMode) {
              headers["X-Event-Request-Context-Authorizer"] =
                this._requestContextAuthorizationHeader;
            }
            return headers;
          }
        })
      ]
    });
  static figmaClient: FigmaAPIClientContext.APIClient = {
    getComponentImage: this.client.figma.getComponentImages.query,
    getComponents: this.client.figma.listComponentsForFile.query,
    getExportableComponentAdElements:
      this.client.figma.listComponentNodes.query,
    listSavedComponents: this.client.figma.figmaComponentsList.query,
    upsertFigmaComponent: this.client.figma.upsertFigmaComponent.mutate
  };

  static consoleClient: ShadowBrandAPIClientContext.APIClient = {
    listTeams: this.client.consoleAPIGraph.listTeams.query,
    listTeamBrands: this.client.consoleAPIGraph.listTeamBrands.query,
    getBrand: this.client.consoleAPIGraph.getBrand.query,
    listTeamFonts: this.client.consoleAPIGraph.listFonts.query
  };

  static marketingCloudClient: MarketingCloudAPIClientContext.APIClient = {
    listAdvertisers: async () => {
      const result = await this.client.marketingCloud.listAdvertisers.query();

      if (Array.isArray(result)) {
        return result;
      }

      return result.advertisers.map(r => ({
        _id: r.id,
        name: r.name,
        type: r.type
      }));
    }
  };
}

function getAuthorizationHeader(): string {
  const cookie = Cookies.get("token");
  if (!cookie) {
    console.warn("Cookie 'token' not found");
    return "";
  }
  return `bearer ${cookie} paid-social:test:read`;
}
