import { FC, PropsWithChildren, useCallback, useState } from "react";

import { useAPIClient } from "../../context/apiClient";
import { useComponentImportDispatch } from "../../context/componentImport";
import { FigmaComponent } from "../../types";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  useBoolean
} from "@chakra-ui/react";
import { Nullable } from "@adflow/types";

import { StepProps } from "./";

export type StepOneSubmit = (
  fileId: Nullable<string>,
  components: Nullable<Array<FigmaComponent>>,
  thumbnailURL: Nullable<string>,
  error: Nullable<Error>
) => void;

const isValidFileId = (fileId?: string) => fileId != null && fileId !== "";

const ComponentImportStepOne: FC<PropsWithChildren<StepProps>> = ({
  children,
  onClose
}) => {
  const client = useAPIClient();
  const [text, setText] = useState<string>("");
  const [submitted, setSubmitted] = useBoolean();
  const [loading, setLoading] = useBoolean();
  const dispatch = useComponentImportDispatch();

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    async evt => {
      evt.preventDefault();
      setSubmitted.on();
      let fileId = text;
      if (!isValidFileId(text)) {
        return;
      }

      if (
        text.startsWith("https://www.figma.com/file/") ||
        text.startsWith("https://figma.com/file/") ||
        text.startsWith("https://figma.com/design/") ||
        text.startsWith("https://www.figma.com/design/")
      ) {
        const parts = text.split("/");

        if (parts.length < 5 || parts[4] === "") {
          dispatch({
            type: "error",
            error: "Could not extract Figma file ID from URL"
          });
          return;
        }

        fileId = parts[4];
      }

      try {
        setLoading.on();
        const { components, thumbnailURL } = await client.getComponents({
          fileId
        });
        const response = await client.listSavedComponents({
          fileId: fileId
        });

        dispatch({
          type: "update-previously-imported",
          payload: {
            listSavedComponents: response.components
          }
        });
        dispatch({
          type: "step-one",
          payload: {
            thumbnailURL: thumbnailURL,
            components: components,
            fileId: fileId
          }
        });
      } catch {
        dispatch({
          type: "error",
          error: "An unexpected error occurred"
        });
      } finally {
        setLoading.off();
      }
    },
    [setSubmitted, text, dispatch, setLoading, client]
  );

  const isError = !isValidFileId(text) && submitted;

  return (
    <>
      <ModalBody>
        <FormControl isInvalid={isError}>
          <FormLabel>Figma File ID or URL</FormLabel>
          <Input
            data-testid='stepOneInput'
            type='text'
            required
            onChange={evt => {
              setText(evt.target.value);
            }}
            placeholder='https://figma.com/file/your-figma-file/'
          />
          {isError && (
            <FormErrorMessage>
              A Valid file ID or URL is required
            </FormErrorMessage>
          )}
        </FormControl>
        <Box pt={2}>{children}</Box>
      </ModalBody>
      <ModalFooter>
        <Button
          data-testid='closeButton'
          variant='ghost'
          mr={3}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          colorScheme='blue'
          onClick={handleSubmit}
          isLoading={loading}
          data-testid='getComponents'
        >
          Get Components
        </Button>
      </ModalFooter>
    </>
  );
};

ComponentImportStepOne.displayName = "ComponentImportStepOne";

export default ComponentImportStepOne;
