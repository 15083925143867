import { AdElementText } from "@adflow/types";
import { Heading, Flex, Select, Box } from "@chakra-ui/react";
import { FC, useMemo, useRef, useState } from "react";
import { useStore } from "../../../hooks/useStore";
import { shallow } from "zustand/shallow";

type Props = {
  selectedElement: AdElementText;
};

const FontSelectionControls: FC<Props> = ({ selectedElement }) => {
  const store = useStore(
    state => ({
      updateElementData: state.updateElementData,
      customFonts: state.customFonts,
      standardFonts: state.standardFonts
    }),
    shallow
  );

  const originalFont = useRef(selectedElement.data.style.fontFamily);

  const availableFonts = useMemo(() => {
    const fontsArray: string[] = [];
    // A font may be used that isn't available in system fonts
    if (
      originalFont.current &&
      originalFont.current !== "" &&
      !store.standardFonts.includes(originalFont.current)
    ) {
      fontsArray.push(originalFont.current);
    }

    // Add common / system fonts
    fontsArray.push(...store.standardFonts);
    document.fonts.forEach(fontFace => {
      if (!fontsArray.includes(fontFace.family)) {
        fontsArray.push(fontFace.family);
      }
    });

    // Add custom fonts
    store.customFonts.forEach(f => {
      if (!fontsArray.includes(f.fontFamily)) {
        fontsArray.push(f.fontFamily);
      }
    });

    return fontsArray;
  }, [store.customFonts, store.standardFonts]);

  const [selectedFont, setSelectedFont] = useState<string | undefined>(
    originalFont.current || ""
  );

  const handleFontChange = (font: string) => {
    setSelectedFont(font);
    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      style: {
        ...selectedElement.data.style,
        fontFamily: font
      }
    });
  };

  const isCustomFont = (fontFamily: string) => {
    return store.customFonts.some(f => f.fontFamily === fontFamily);
  };
  return (
    <Box mt={5}>
      <Heading fontSize='md' fontWeight='semibold'>
        Font
      </Heading>
      <Flex alignItems='center' mt={2}>
        <Select
          data-testid='selectFont'
          placeholder='Select Font'
          value={selectedFont}
          onChange={event => handleFontChange(event.target.value)}
        >
          {availableFonts.map(font => {
            return (
              <option key={font} value={font}>
                {font}
                {store.standardFonts.includes(font)
                  ? ""
                  : isCustomFont(font)
                  ? " (Custom)"
                  : " (Not installed)"}
              </option>
            );
          })}
        </Select>
      </Flex>
    </Box>
  );
};

FontSelectionControls.displayName = "FontSelectionControls";

export default FontSelectionControls;
export type { Props as FontSelectionControlsProps };
