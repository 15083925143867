import { Divider, Stack, Typography } from "@mui/material";
import { FontLibrary } from "../components/FontLibrary";
import { Flex } from "@chakra-ui/react";

export function Index() {
  return (
    <Stack flex='1'>
      <Flex direction='column' pl='48px' pr='48px' pt='19px' pb='28px'>
        <Flex justifyContent='space-between'>
          <Typography data-testid='brandkitPageTitle' variant='h1'>
            Brand Kit
          </Typography>
        </Flex>
        <Divider
          sx={{
            paddingTop: "24px"
          }}
        />
      </Flex>

      <Flex pl='48px' pr='48px'>
        <FontLibrary />
      </Flex>
    </Stack>
  );
}
