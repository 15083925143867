import { AdElementRenderProps } from "@adflow/ad";

export type OverlayProps = {
  // Probably want to update this type. This component doesn't
  // require a lot of the props on an AdTemplate.
  adElements: Array<AdElementRenderProps>;
  selectedId?: string | null;
};

export type PreviewOverlayElement = {
  id: string;
  type: string;
  position: {
    x: number;
    y: number;
  };
  size: {
    height: number;
    width: number;
  };
};

export function getElementIdsAndPositions(
  adElements: AdElementRenderProps[]
): Record<string, PreviewOverlayElement> {
  return adElements.reduce((acc, el) => {
    acc[el.id] = adElementToPreviewElement(el);
    return acc;
  }, {} as Record<string, PreviewOverlayElement>);
}

function adElementToPreviewElement(
  adElement: AdElementRenderProps
): PreviewOverlayElement {
  return {
    id: adElement.id,
    type: adElement.type,
    position: adElement.position,
    size: adElement.size
  };
}
