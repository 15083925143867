import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { FaBookmark, FaShapes } from "react-icons/fa";
import { GiAmericanFootballHelmet } from "react-icons/gi";
import { LuImage, LuType } from "react-icons/lu";
import { PiBoundingBox, PiSparkle } from "react-icons/pi";

import { useAPIClient } from "../../context/apiClient";
import {
  useComponentImportDispatch,
  useComponentImportState
} from "../../context/componentImport";
import {
  AdElement,
  AdElementImage,
  AdElementTeamAsset,
  AdElementText,
  AdElementVector,
  Datasources,
  isImageElement,
  isSVGElement,
  isTeamAssetElement,
  isTextElement,
  isVectorElement
} from "@adflow/types";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  ModalBody,
  ModalFooter,
  Skeleton,
  Stack,
  Switch,
  Text,
  Tooltip,
  UseRadioProps,
  useBoolean,
  useRadio,
  useRadioGroup
} from "@chakra-ui/react";
import { LuExternalLink } from "react-icons/lu";

import { EditorStoreContext } from "@adflow/editor";
import React from "react";
import { StepProps } from "./";

export type StepThreeSubmit = (
  customElements: Array<AdElement>,
  thumbnailURL: string,
  dataSourceTypes?: Array<Datasources.SourceType>
) => void;

interface StepThreeProps extends StepProps {
  onSubmit: StepThreeSubmit;
}

const mapDataPointToTeamAssetSource = (
  dataPointName: string
):
  | "HOME_ASSET"
  | "AWAY_ASSET"
  | "COMPETITOR_ASSET"
  | "OPPONENT_ASSET"
  | undefined => {
  switch (dataPointName) {
    case "HOME_JERSEY":
    case "HOME_BADGE":
      return "HOME_ASSET";
    case "AWAY_JERSEY":
    case "AWAY_BADGE":
      return "AWAY_ASSET";
    case "COMPETITOR_JERSEY":
    case "COMPETITOR_BADGE":
      return "COMPETITOR_ASSET";
    case "OPPONENT_JERSEY":
    case "OPPONENT_BADGE":
      return "OPPONENT_ASSET";
    default:
      return undefined;
  }
};

const mapDataPointToDisplayAssetType = (
  dataPointName: string
): "JERSEY" | "BADGE" | undefined => {
  switch (dataPointName) {
    case "HOME_JERSEY":
    case "AWAY_JERSEY":
    case "COMPETITOR_JERSEY":
    case "OPPONENT_JERSEY":
      return "JERSEY";
    case "HOME_BADGE":
    case "AWAY_BADGE":
    case "COMPETITOR_BADGE":
    case "OPPONENT_BADGE":
      return "BADGE";
    default:
      return undefined;
  }
};

const ComponentImportStepThree: FC<PropsWithChildren<StepThreeProps>> = ({
  onClose,
  onSubmit
}) => {
  const ctx = useContext(EditorStoreContext);
  const state = useComponentImportState();
  const [teamAssetElIds, setTeamAssetElIds] = useState<Array<string>>([]);
  const [isSaved, setIsSaved] = useState(false);
  const dispatch = useComponentImportDispatch();
  const client = useAPIClient();

  const toggleSaveComponent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSaved(e.target.checked);
    },
    []
  );

  const suggestedDataSources = useMemo(
    () =>
      Datasources.suggestDataSources([
        ...state.elements.filter(isTextElement),
        ...state.elements.filter(isImageElement)
      ]),
    [state.elements]
  );

  const dataSourceTypes = useMemo(() => {
    return suggestedDataSources.map(ds => {
      return ds[0].type;
    });
  }, [suggestedDataSources]);

  const [mappingEnabled, { toggle: toggleDataMapping }] = useBoolean(
    suggestedDataSources.length > 0
  );

  const [checkedElements, setCheckedElements] = useState(
    [...state.elements]
      .map(el => {
        return { selected: el.type === "SVG", ...el };
      })
      .sort((a, b) => {
        if (a.selected && !b.selected) return -1;
        if (!a.selected && b.selected) return 1;
        // Sort by type >> name
        return a.type.localeCompare(b.type) || a.name.localeCompare(b.name);
      })
      // HACK: Text elements must always be layered on above images. The above
      // sort will not guarantee this.
      .map(el => ({
        ...el,
        selected:
          suggestedDataSources.some(dps => dps.some(dp => dp.elId === el.id)) ||
          el.type === "SVG"
      }))
  );

  const lookupDataSource = useCallback(
    (elId: string) => {
      const dataPoints = suggestedDataSources
        // find sources that contain the current element
        .find(dps => dps.some(dp => dp.elId === elId))
        // find the data point for that element
        ?.filter(dp => dp.elId === elId);

      // If no data points are found, return the element as is
      if (!dataPoints) {
        return {};
      }
      const dataPoint = dataPoints[0];

      // Find the data source that matches the type of the data point
      const dataSource = ctx.dataSources.find(ds => ds.type === dataPoint.type);
      if (!dataSource) {
        return {};
      }
      return { dataSource, dataPoint };
    },
    [suggestedDataSources]
  );

  const mapTeamAssetElement = useCallback(
    (el: AdElementImage) => {
      let autoImportOverrides = { data: {} };
      if (mappingEnabled) {
        const { dataSource, dataPoint } = lookupDataSource(el.id);
        if (dataSource && dataPoint) {
          autoImportOverrides = {
            data: {
              content: "",
              source: mapDataPointToTeamAssetSource(dataPoint.dataPointName)
            }
          };
        }
      }
      const val: AdElementTeamAsset = {
        ...el,
        type: "TEAM_ASSET",
        data: {
          ...el.data,
          ...autoImportOverrides.data
        }
      };
      return val;
    },
    [mappingEnabled]
  );

  const mapTextElement = useCallback(
    (el: AdElementText) => {
      let autoImportOverridesData = {};
      let autoImportOverridesSources: {
        sourceId: string;
        name: Datasources.AllowableDataPoints;
      }[] = [];

      if (mappingEnabled) {
        const { dataSource, dataPoint } = lookupDataSource(el.id);
        if (dataSource && dataPoint) {
          autoImportOverridesData = {
            content: `{${dataSource.id}:${dataPoint.dataPointName}}`
          };
          autoImportOverridesSources = [
            {
              sourceId: dataSource.id,
              name: dataPoint.dataPointName as Datasources.AllowableDataPoints
            }
          ];
        }
      }
      const val: AdElementText = {
        ...el,
        data: {
          ...el.data,
          ...autoImportOverridesData
        },
        sources: [...el.sources, ...autoImportOverridesSources]
      };
      return val;
    },
    [mappingEnabled]
  );

  const getDisplayOptionsAssetType = useCallback((elements: AdElement[]) => {
    const matches = elements.filter(el => isTeamAssetElement(el));
    if (!matches) {
      return;
    }
    return matches
      .map(el => {
        const { dataPoint } = lookupDataSource(el.id);
        if (dataPoint) {
          return mapDataPointToDisplayAssetType(dataPoint.dataPointName);
        }
      })
      .filter(x => x)
      .pop();
  }, []);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    async evt => {
      evt.preventDefault();
      const svgElements = state.elements.filter(isSVGElement);
      const textElements = state.elements.filter(isTextElement);
      const imageElements = state.elements.filter(isImageElement);
      const teamAssetElements = state.elements.filter(isTeamAssetElement);
      const vectorElements = state.elements.filter(isVectorElement);

      const filteredElements = checkedElements
        .filter(el => el.selected)
        .map(el => {
          // no need to retain the selected state
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { selected, ...rest } = el;

          if (isTextElement(rest)) {
            return mapTextElement(rest);
          } else if (isImageElement(rest)) {
            const isTeamAsset = teamAssetElIds.includes(rest.id);
            return isTeamAsset ? mapTeamAssetElement(rest) : rest;
          } else if (isVectorElement(rest)) {
            return mapVectorElement(rest, svgElements[0].data.svg);
          } else {
            return rest;
          }
        });

      if (mappingEnabled) {
        const assetType = getDisplayOptionsAssetType(filteredElements);
        if (assetType) {
          ctx.store?.getState().setDisplayOptions("teamAssets", {
            type: assetType,
            fallback: "NONE"
          });
        }
      }
      onSubmit(
        filteredElements,
        state.componentThumbnailURL,
        mappingEnabled ? dataSourceTypes : []
      );
      if (!isSaved) {
        return;
      }

      if (!svgElements.length) {
        console.warn("expected at least one SVG element but received 0 ");
        return;
      }
      if (!textElements.length) {
        console.warn("expected at least one text element but received 0 ");
        return;
      }
      if (!imageElements.length) {
        console.warn("expected at least one text element but received 0 ");
        return;
      }
      const svgElement = svgElements[0];
      const svgString = svgElement.data.svg;
      try {
        await client.upsertFigmaComponent({
          figmaId: svgElement.id,
          fileId: state.fileId,
          name: svgElement.name,
          svg: svgString,
          svgUrl: state.previewURL,
          thumbnail: state.componentThumbnailURL,
          svgElement,
          textElements,
          imageElements,
          teamAssetElements,
          vectorElements
        });
      } catch (error) {
        dispatch({
          type: "error",
          error: "An unexpected error occurred"
        });
      }
    },
    [
      state.elements,
      state.componentThumbnailURL,
      state.fileId,
      state.previewURL,
      checkedElements,
      onSubmit,
      dataSourceTypes,
      isSaved,
      teamAssetElIds,
      mappingEnabled,
      suggestedDataSources,
      ctx.dataSources,
      client,
      dispatch
    ]
  );

  const onChangeHandle = useCallback(
    (inputIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedElements(
        checkedElements.map((el, idx) => {
          if (inputIndex === idx) {
            return { ...el, selected: e.target.checked };
          }
          return el;
        })
      );
    },
    [checkedElements]
  );

  useEffect(() => {
    if (!mappingEnabled) {
      return;
    }
    checkedElements.forEach(el => {
      const match = suggestedDataSources.find(dps =>
        dps.some(dp => dp.elId === el.id)
      );
      if (!match) {
        return;
      }
      match.forEach(dp => {
        setTeamAssetElIds(prev => [
          ...prev.filter(id => id !== dp.elId),
          dp.elId
        ]);
      });
      return;
    });
    return;
  }, [suggestedDataSources, checkedElements, mappingEnabled]);

  const handleToggleImgElType = (elId: string, newType: string) => {
    const elIsTeamAssetEl = teamAssetElIds.includes(elId);

    if (newType === "teamasset" && !elIsTeamAssetEl) {
      setTeamAssetElIds(prev => [...prev, elId]);
      return;
    }

    if (newType === "img" && elIsTeamAssetEl) {
      setTeamAssetElIds(prev => prev.filter(id => id !== elId));
    }
  };

  const onToggleMapping = useCallback(
    (selected: boolean) => {
      console.log({ selected, suggestedDataSources });
      setCheckedElements(
        checkedElements.map(el => {
          const match = suggestedDataSources.find(dps =>
            dps.some(dp => dp.elId === el.id)
          );
          if (match) {
            return { ...el, selected: selected };
          }
          return el;
        })
      );
      toggleDataMapping();
    },
    [checkedElements, toggleDataMapping, suggestedDataSources]
  );

  const getImageElementType = useCallback(
    (elId: string) => {
      if (!mappingEnabled) {
        return;
      }
      const match = suggestedDataSources.find(dps =>
        dps.some(dp => dp.elId === elId)
      );
      if (!match) {
        return;
      }
      return match.map(dp => mapDataPointToTeamAssetSource(dp.dataPointName))
        ? "teamasset"
        : "img";
    },
    [suggestedDataSources, checkedElements, mappingEnabled]
  );

  return (
    <>
      <ModalBody display='flex' alignItems='flex-start' columnGap={5}>
        <Box>
          {state.previewURL && (
            <Image
              data-testid='loadedImage'
              w={300}
              src={state.previewURL}
              fallback={<Skeleton height={300} width={300} />}
            ></Image>
          )}
          {!state.savedComponentId && (
            <Checkbox mt={3} key='toggleSave' onChange={toggleSaveComponent}>
              <Box display='flex' alignItems='center'>
                <FaBookmark /> <Text ml={1}>Save this component for later</Text>
              </Box>
            </Checkbox>
          )}
        </Box>
        <Box flexDirection={"column"}>
          <Box
            borderWidth={1}
            borderRadius='md'
            px={4}
            py={3}
            flex={1}
            data-testid='selectElement'
          >
            <Heading as='h3' size='md'>
              Element Selection
            </Heading>
            <Text size='md' mb={3}>
              Select any additional elements you would like to create.
            </Text>
            <Stack
              direction='column'
              maxH={"300px"}
              overflowY={"scroll"}
              data-testid='elementsList'
            >
              {checkedElements.map((el, index) => {
                if (el.type === "SVG") {
                  return (
                    <Checkbox pl={1} key={el.id} defaultChecked isDisabled>
                      <Box display={"flex"} alignItems='center'>
                        <FaShapes /> <Text ml={2}>{el.name}</Text>
                      </Box>
                    </Checkbox>
                  );
                }
                if (el.type === "TEXT") {
                  return (
                    <Checkbox
                      pl={1}
                      key={el.id}
                      isChecked={el.selected}
                      onChange={onChangeHandle(index)}
                    >
                      <Box display={"flex"} alignItems='center'>
                        <Icon as={LuType}></Icon>
                        <Text ml={2} noOfLines={1} as='span'>
                          {el.name}
                          <Text
                            ml={2}
                            fontStyle='italic'
                            fontSize='xs'
                            as='span'
                          >
                            - &quot;{el.data.content}&quot;
                          </Text>
                        </Text>
                      </Box>
                    </Checkbox>
                  );
                }
                if (el.type === "IMAGE") {
                  return (
                    <Flex key={el.id} justifyContent='space-between'>
                      <Checkbox
                        pl={1}
                        isChecked={el.selected}
                        onChange={onChangeHandle(index)}
                      >
                        <Box display={"flex"} alignItems='center'>
                          {teamAssetElIds.includes(el.id) ? (
                            <GiAmericanFootballHelmet />
                          ) : (
                            <LuImage />
                          )}
                          <Text ml={2}>{el.name}</Text>
                        </Box>
                      </Checkbox>
                      <ImgElTypeRadioGroup
                        onChange={type => handleToggleImgElType(el.id, type)}
                        name={`${el.id}_assettype-toggle`}
                        val={getImageElementType(el.id)}
                      />
                    </Flex>
                  );
                }
                if (el.type === "VECTOR") {
                  return (
                    <Checkbox
                      pl={1}
                      key={el.id}
                      isChecked={el.selected}
                      onChange={onChangeHandle(index)}
                    >
                      <Box display={"flex"} alignItems='center'>
                        <PiBoundingBox />
                        <Text ml={2}>{el.name}</Text>
                      </Box>
                    </Checkbox>
                  );
                }
                return null;
              })}
            </Stack>
          </Box>
          <Box
            borderWidth={1}
            borderRadius='md'
            mt={2}
            px={4}
            py={3}
            flex={1}
            data-testid='selectElement'
          >
            <DataMappingMagic
              mappingEnabled={mappingEnabled}
              onToggleMapping={onToggleMapping}
              suggestedDataSources={suggestedDataSources}
            />
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          data-testid='closeButton'
          variant='ghost'
          mr={3}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          colorScheme='blue'
          onClick={handleSubmit}
          data-testid='finishSelection'
        >
          Finish
        </Button>
      </ModalFooter>
    </>
  );
};

ComponentImportStepThree.displayName = "ComponentImportStepThree";

export default ComponentImportStepThree;

const imgElOptions = [
  { value: "img", label: "Image" },
  { value: "teamasset", label: "Team Asset" }
];

const ImgElTypeRadioGroup: FC<{
  name: string;
  val?: "img" | "teamasset";
  onChange: (val: string) => void;
}> = ({ name, val, onChange }) => {
  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name,
    defaultValue: "img",
    onChange
  });

  useEffect(() => {
    if (val) {
      setValue(val);
    }
  }, [setValue, val]);

  const group = getRootProps();

  return (
    <HStack {...group}>
      {imgElOptions.map(({ value, label }) => {
        const radio = getRadioProps({ value });
        return (
          <Tooltip label={label} key={value}>
            <Box>
              <RadioCard {...radio}>
                {value === "img" ? <LuImage /> : <GiAmericanFootballHelmet />}
              </RadioCard>
            </Box>
          </Tooltip>
        );
      })}
    </HStack>
  );
};

const RadioCard: FC<PropsWithChildren<UseRadioProps>> = props => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        boxShadow='md'
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600"
        }}
        _focus={{
          boxShadow: "outline"
        }}
        px={2}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  );
};

type DataMappingMagicProps = {
  mappingEnabled: boolean;
  onToggleMapping: (val: boolean) => void;
  suggestedDataSources: Datasources.SuggestedDataSources;
};

const DataMappingMagic: FC<DataMappingMagicProps> = ({
  mappingEnabled,
  onToggleMapping,
  suggestedDataSources
}) => {
  const dataSourceNames = useMemo(() => {
    return suggestedDataSources.map(ds => {
      return ds[0].name;
    });
  }, [suggestedDataSources]);

  return (
    <>
      <Heading size='sm'>
        Data Mapping <Icon as={PiSparkle} />
      </Heading>
      {suggestedDataSources.length ? (
        <Text mb='0'>
          We matched{" "}
          <Badge colorScheme='blue'>{suggestedDataSources.length}</Badge>{" "}
          potential data sources for your creative -{" "}
          {dataSourceNames.map((v, idx) => (
            <React.Fragment key={v}>
              <Badge colorScheme='blue' textTransform='none'>
                {v}
              </Badge>
              {idx === dataSourceNames.length - 1 ? "" : ", "}
            </React.Fragment>
          ))}
          .
        </Text>
      ) : (
        <>
          <Text mb='0'>
            We couldn&apos;t find any potential dynamic elements in your
            creative. Element names should follow a{" "}
            <Link
              color='teal.500'
              href='https://marketing-cloud.sportradar.com/support/solutions/articles/77000515734-sportsbooks-integration-manual'
              isExternal
            >
              naming specification <Icon as={LuExternalLink} />
            </Link>{" "}
            to be detected.
          </Text>
        </>
      )}
      <FormControl display='flex' alignItems='center' mt={2}>
        <FormLabel htmlFor='data-mappings' mb='0'>
          Create data mappings?
        </FormLabel>
        <Switch
          id='data-mappings'
          colorScheme='teal'
          isChecked={mappingEnabled}
          disabled={suggestedDataSources.length === 0}
          onChange={evt => {
            onToggleMapping(evt.target.checked);
          }}
        />
      </FormControl>
    </>
  );
};

/**
 * This function takes in an AdElementVector and a string that contains a svg.
 * This svg contains all AdElements. We extract the Vector element and
 * effect filters. We also get the width and height from it and set it on the AdElementVector.
 * @param vectorElement
 * @param svgString
 * @returns AdElementVector with content, effectFilters and size(width, height) extracted from the svgString
 */
function mapVectorElement(
  vectorElement: AdElementVector,
  svgString: string
): AdElementVector {
  const parser = new DOMParser();

  const svgDocument = parser.parseFromString(svgString, "image/svg+xml");
  const vectorElementDoc = svgDocument.querySelector(
    '[id="' +
      vectorElement.name.replace(/["\\]/g, "\\$&").replace(/£/g, "Â£") +
      '"]'
  );

  if (!vectorElementDoc) {
    return vectorElement;
  }
  const { width, height } = getWidthAndHeightFromSVGElement(
    svgDocument,
    vectorElement
  );

  const filterStr = getFilterStringFromSVGElement(svgDocument, vectorElement);
  return {
    ...vectorElement,
    data: {
      ...vectorElement.data,
      effectFilters: filterStr,
      content: vectorElementDoc.outerHTML,
      viewBox: {
        width,
        height
      }
    }
  };
}

function getWidthAndHeightFromSVGElement(
  svgDocument: Document,
  vectorElement: AdElementVector
) {
  const width =
    parseFloat(svgDocument.querySelector("svg")?.getAttribute("width") || "") ||
    vectorElement.size.width;
  const height =
    parseFloat(
      svgDocument.querySelector("svg")?.getAttribute("height") || ""
    ) || vectorElement.size.height;
  return { width, height };
}

function getFilterStringFromSVGElement(
  svgDocument: Document,
  vectorElement: AdElementVector
) {
  const groupElement = svgDocument.querySelector(
    '[id="' +
      vectorElement.name.replace(/["\\]/g, "\\$&").replace(/£/g, "Â£") +
      '"]'
  );
  let filterStr = "";
  let filterId = "";
  filterId = groupElement
    ?.getAttribute("filter")
    ?.replace("url(#", "")
    .replace(")", "") as string;
  let filterElement;
  if (filterId) {
    filterElement = svgDocument.querySelector(
      '[id="' + filterId.replace(/["\\]/g, "\\$&").replace(/£/g, "Â£") + '"]'
    );
  }
  const serializer = new XMLSerializer();
  if (filterElement) {
    filterStr = serializer.serializeToString(filterElement);
  }
  return filterStr;
}

export const testExports = {
  mapDataPointToTeamAssetSource,
  mapDataPointToDisplayAssetType
};
