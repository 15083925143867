import { FC, PropsWithChildren } from "react";

import { createGenericContext } from "@adflow/utils";

type APIClientContext = {
  listAdvertisers: () => Promise<Array<any>>;
};

const [useAPIClient, APIClientContextProvider] =
  createGenericContext<APIClientContext | null>();

const Provider: FC<PropsWithChildren<APIClientContext>> = ({
  children,
  ...value
}) => {
  return (
    <APIClientContextProvider value={value}>
      {children}
    </APIClientContextProvider>
  );
};

export { Provider, useAPIClient };
export type { APIClientContext as APIClient };
