import { createContext } from "react";

import { createEditorStore } from "../store";
import { Datasources } from "@adflow/types";

const StoreContext = createContext<{
  dataSources: ReadonlyArray<Datasources.AllowableDataSource>;
  store: ReturnType<typeof createEditorStore> | null;
}>({ store: null, dataSources: [] });

StoreContext.displayName = "StoreContext";

export const Provider = StoreContext.Provider;
export const Consumer = StoreContext.Consumer;
export default StoreContext;
