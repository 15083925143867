import { FC } from "react";

type Props = {
  svgString: string;
};

const SVGElement: FC<Props> = ({ svgString = "" }) => {
  return <div dangerouslySetInnerHTML={{ __html: svgString }} />;
};

SVGElement.displayName = "SVGElement";

export default SVGElement;
