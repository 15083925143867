import { ChangeEventHandler, FC, useCallback, useEffect, useMemo } from "react";

import { useAPIClient } from "../../context/apiClient";
import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import { Console, FontFace as AdflowFontFace } from "@adflow/types";
import { useObjectState } from "@adflow/utils";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Tag
} from "@chakra-ui/react";

type Props = {
  brand: ConsoleAPIGraph.Brand | null;
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (brand: ConsoleAPIGraph.Brand, fonts: AdflowFontFace[]) => void;
};

type SelectShadowBrandState = {
  selectedTeamId: string;
  selectedTeamBrandId: string;
  teams: Console.Entity[];
  brands: Console.Entity[];
  brand: ConsoleAPIGraph.Brand | null;
  fonts: AdflowFontFace[];
};

type LoadingState = {
  teams: boolean;
  teamBrands: boolean;
  brand: boolean;
};

const SelectShadowBrand: FC<Props> = ({
  isOpen,
  handleClose,
  onSubmit,
  brand
}) => {
  const [state, setState] = useObjectState<SelectShadowBrandState>({
    selectedTeamId: brand ? brand.team || "" : "",
    selectedTeamBrandId: brand ? brand._id || "" : "",
    teams: [],
    brands: [],
    brand: brand || null,
    fonts: []
  });

  const [loading, setLoading] = useObjectState<LoadingState>({
    teams: false,
    teamBrands: false,
    brand: false
  });
  const apiContext = useAPIClient();

  useEffect(() => {
    async function fetchTeams() {
      const response = await apiContext.listTeams();
      setState({ teams: response });
      setLoading({ teams: false });
    }

    async function fetchBrand() {
      if (state.brand) {
        setLoading({ brand: true });
        const teamBrandsResult = await apiContext.listTeamBrands(
          state.brand.team || ""
        );

        setState({
          brands: teamBrandsResult
        });
        setLoading({ brand: false });
        if (state.selectedTeamBrandId !== null) {
          const brand = await apiContext.getBrand(state.selectedTeamBrandId);
          const result = await apiContext.listTeamFonts({
            team: state.selectedTeamId
          });

          setState({
            brand,
            fonts: result.fontFaces
          });
        }
      }
    }
    setLoading({ teams: true });

    fetchTeams();
    if (state.brand) {
      fetchBrand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTeamSelection = useCallback<
    ChangeEventHandler<HTMLSelectElement>
  >(
    async evt => {
      setLoading({ teamBrands: true });

      const teamId = evt.target.value;
      const teamBrands = await apiContext.listTeamBrands(teamId);
      setState({
        selectedTeamId: teamId,
        brands: teamBrands
      });

      setLoading({ teamBrands: false });
    },
    [apiContext, setLoading, setState]
  );

  const handleTeamBrandSelection = useCallback<
    ChangeEventHandler<HTMLSelectElement>
  >(
    async evt => {
      setState({ brand: null });
      setLoading({ brand: true });
      const teamBrandId = evt.target.value;
      const brand = await apiContext.getBrand(teamBrandId);
      if (!brand) {
        // TODO: deal with this later;
        return;
      }

      const result = await apiContext.listTeamFonts({
        team: state.selectedTeamId
      });

      setState({
        selectedTeamBrandId: teamBrandId,
        brand,
        fonts: result.fontFaces
      });
      setLoading({ brand: false });
    },
    [apiContext, setLoading, setState, state.selectedTeamId]
  );

  const handleSubmit = useCallback(() => {
    if (!state.brand) {
      return; //TODO: handle this
    }

    onSubmit(state.brand, state.fonts);
  }, [onSubmit, state.brand, state.fonts]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-testid='selectShadowBrandTitle'>
          Select a Shadow Brand
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select
            mb={4}
            data-testid='selectShadowBrandConsoleTeamDropdown'
            size='md'
            width={"50%"}
            required
            onChange={handleTeamSelection}
            value={state.selectedTeamId}
            icon={loading.teams ? <Spinner /> : undefined}
            placeholder='Please select a Console Team'
          >
            {state.teams.map(team => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </Select>
          <Select
            data-testid='selectShadowBrandTeamBrandDropdown'
            mb={4}
            size='md'
            width={"50%"}
            required
            onChange={handleTeamBrandSelection}
            value={state.selectedTeamBrandId}
            icon={loading.teamBrands ? <Spinner /> : undefined}
            placeholder='Please select a Team Brand'
            disabled={!state.brands.length}
          >
            {state.brands.map(teamBrand => (
              <option key={teamBrand._id} value={teamBrand._id}>
                {teamBrand.name}
              </option>
            ))}
          </Select>
          {/* {loading.brand && (
            <Flex justifyContent={"center"} height={"52px"}>
              <Spinner size={"sm"} />
            </Flex>
          )} */}
        </ModalBody>

        <ModalFooter>
          <Button
            data-testid='closeShadowBrandButton'
            variant='ghost'
            mr={3}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleSubmit}
            data-testid='confirmBrand'
            disabled={!state.brand}
          >
            Confirm selection
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

SelectShadowBrand.displayName = "SelectShadowBrand";

export default SelectShadowBrand;
export type { Props as SelectShadowBrandProps };

type TeamBrandingTagProps = {
  enabled: boolean;
  label: string;
};

const BrandingFeatureTag: FC<TeamBrandingTagProps> = ({ enabled, label }) => {
  return (
    <Tag
      size='md'
      colorScheme={enabled ? "blue" : "gray"}
      variant={enabled ? "solid" : "outline"}
      opacity={!enabled ? 0.5 : 1}
    >
      {label}
    </Tag>
  );
};

BrandingFeatureTag.displayName = "TeamBrandingTag";
