import { FC } from "react";
import { AdElementImage } from "@adflow/types";
import BaseballHat from "./BaseballHat";
import FootballHelmet from "./FootballHelmet";
import { Kit } from "./types";
import JerseyRealistic from "./JerseyRealistic";
import { Type } from "./jersey/types";

interface Props {
  position: AdElementImage["position"];
  size: AdElementImage["size"];
  kitProps: Kit;
  hasGradient?: boolean;
}

const sleeveType: Record<string, Type> = {
  shortSleeveJersey: "short_sleeves",
  noSleeveJersey: "no_sleeves",
  longSleeveJersey: "long_sleeves"
};

const TeamKit: FC<Props> = ({ position, size, kitProps, hasGradient }) => {
  if (kitProps.kind === "baseballHat") {
    return (
      <BaseballHat
        size={size}
        position={position}
        rotate={kitProps.rotate}
        colour={kitProps.colours}
      />
    );
  }

  if (kitProps.kind === "footballHelmet") {
    return (
      <FootballHelmet
        size={size}
        rotate={kitProps.rotate}
        position={position}
        colour={kitProps.colours}
      />
    );
  }

  const type = sleeveType[kitProps.kind];

  return (
    <JerseyRealistic
      type={type}
      size={size}
      position={position}
      colour={kitProps.colours}
      hasGradient={hasGradient}
    />
  );
};

TeamKit.displayName = "TeamKit";

export default TeamKit;
