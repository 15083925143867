import CircularProgress from "@mui/material/CircularProgress";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";

interface ButtonProps {
  muiButtonProps: MuiButtonProps;
  children: React.ReactNode;
  isLoading?: boolean;
  testId?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <MuiButton
      {...props.muiButtonProps}
      disableRipple={true}
      variant='contained'
      disabled={props.isLoading || props.muiButtonProps.disabled}
      data-testid={props.testId}
    >
      {props.isLoading ? (
        <CircularProgress size={20} sx={{ color: "white" }} />
      ) : (
        props.children
      )}
    </MuiButton>
  );
};
export default Button;
