import { Box, Heading, NumberInput, NumberInputField } from "@chakra-ui/react";
import { FC } from "react";
import { useStore } from "../../../hooks/useStore";
import { shallow } from "zustand/shallow";
import { AdElementText } from "@adflow/types";

type Props = {
  selectedElement: AdElementText;
};

const LineHeightInput: FC<Props> = ({ selectedElement }) => {
  const store = useStore(
    state => ({
      selectedElementId: state.selectedElementId,
      updateElementData: state.updateElementData
    }),
    shallow
  );

  const handleLineHeight = (lineHeight: string) => {
    const lineHeightNumber = parseFloat(lineHeight);
    const isValidHeight = lineHeightNumber > 1;
    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      style: {
        ...selectedElement.data.style,
        lineHeight: isValidHeight ? lineHeightNumber : 1
      }
    });
  };

  const handleArrows = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = event;
    if (key === "ArrowUp" || key === "ArrowDown") {
      handleLineHeight((target as HTMLInputElement).value);
    }
  };

  return (
    <Box>
      <Heading fontSize='md' fontWeight='semibold'>
        Line height
      </Heading>
      <NumberInput
        defaultValue={selectedElement.data.style.lineHeight || undefined}
        size='sm'
        width={20}
        variant='outline'
        mt={2}
        precision={0}
        min={1}
      >
        <NumberInputField
          onChange={e => handleLineHeight(e.target.value)}
          onKeyUp={handleArrows}
          data-testid='lineHeightInput'
        />
      </NumberInput>
    </Box>
  );
};

LineHeightInput.displayName = "LineHeightInput";

export default LineHeightInput;
export type { Props as LineHeightInputProps };
