import { NavLink } from "react-router-dom";
import TemplatesIcon from "./Icons/templates.svg";
import BrandKitIcon from "./Icons/brandKit.svg";
import CatalogueManagementIcon from "./Icons/catalogueManagement.svg";
import AdTagIcon from "./Icons/adTag.svg";
import "./index.css";
import { useFeatureFlags } from "@adflow/utils";
import { useContext } from "react";
import UserContext from "../UserProvider/contexts/UserContext";

interface IRoute {
  title: string;
  icon: string;
  link: string;
  enabled: boolean;
}

export default function SideNavigation() {
  const { flags: featureFlags } = useFeatureFlags();

  const { user } = useContext(UserContext);
  const isAdTagsEnabled = !(
    import.meta.env.VITE_BUILD_MODE === "prod" && !user?.isAdmin()
  );

  const routes: IRoute[] = [
    {
      title: "Templates",
      icon: TemplatesIcon,
      link: "/templates",
      enabled: true
    },
    {
      title: "Brand kit",
      icon: BrandKitIcon,
      link: "/brand-kit",
      enabled: true
    },
    {
      title: "Catalogues",
      icon: CatalogueManagementIcon,
      link: "/catalogues",
      enabled: true
    },
    {
      title: "Ad Tags",
      icon: AdTagIcon,
      link: "/ad-tags",
      enabled: isAdTagsEnabled
    }
  ];

  const renderRoute = (route: IRoute) => {
    return (
      <NavLink to={route.link} key={route.title}>
        <div className='sideBarItem'>
          <div className='sideBarIcon_container'>
            <img
              className='sideBarIcon'
              src={route.icon}
              alt='{route.title} icon'
            />
          </div>
          <div className='sideBarTitle'>{route.title}</div>
        </div>
      </NavLink>
    );
  };

  return (
    <div id='sidebar'>
      {routes.filter(route => route.enabled).map(renderRoute)}
    </div>
  );
}
