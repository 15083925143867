import { AdElementText, DynamicFont } from "@adflow/types";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Switch,
  Tooltip
} from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { LuInfo } from "react-icons/lu";
import { useStore } from "../../../hooks/useStore";
import { shallow } from "zustand/shallow";

type Props = {
  textElement: AdElementText;
};

const DynamicFontSizeControls: FC<Props> = ({ textElement }) => {
  // The max font size is calculated based on the height of the text element
  // This is to prevent the text from growing too large in Safari
  // The max font size is 20% larger so the auto fitting text does not under utilize the space
  const maxFontSize = useMemo(
    () => Math.round(textElement.size.height * 1.2),
    [textElement.size.height]
  );

  const s = useStore(
    state => ({
      updateElementData: state.updateElementData
    }),
    shallow
  );

  const handleUpdateMultiline = useCallback(
    (value: boolean) => {
      if (textElement.data.dynamicFont.enabled === true)
        s.updateElementData(textElement.id, {
          ...textElement.data,
          dynamicFont: {
            maxFontSize,
            minFontSize: 4,
            lineHeight: 1,
            multiLine: !!value,
            enabled: true
          }
        });
    },
    [maxFontSize, s, textElement.data, textElement.id]
  );

  const handleUpdateDynamicFont = useCallback(
    <K extends keyof DynamicFont>(key: K, value: DynamicFont[K]) => {
      if (key === "enabled" && value == false) {
        s.updateElementData(textElement.id, {
          ...textElement.data,
          dynamicFont: {
            enabled: false
          }
        });
        return;
      }

      if (key === "enabled" && value === true) {
        s.updateElementData(textElement.id, {
          ...textElement.data,
          dynamicFont: {
            maxFontSize,
            minFontSize: 4,
            lineHeight: 1,
            multiLine: false,
            enabled: true
          }
        });
        return;
      }

      s.updateElementData(textElement.id, {
        ...textElement.data,
        dynamicFont: {
          ...textElement.data.dynamicFont,
          [key]: value
        }
      });
    },
    [maxFontSize, s, textElement.data, textElement.id]
  );

  return (
    <>
      <FormControl display='flex' alignItems='center' mt={5} mb={1}>
        <FormLabel htmlFor='dynamic-font-size' mb={"0"}>
          Enable auto fitting text?
        </FormLabel>
        <Switch
          id='dynamic-font-size'
          mr={3}
          isChecked={textElement.data.dynamicFont.enabled}
          onChange={evt =>
            handleUpdateDynamicFont("enabled", evt.target.checked)
          }
        />
        <Tooltip label="The text will try to dynamically fill it's container.">
          <span>
            <LuInfo />
          </span>
        </Tooltip>
      </FormControl>
      {textElement.data.dynamicFont.enabled && (
        <FormControl
          display='flex'
          alignItems='center'
          pl={0}
          mt={1}
          mb={1}
          flex={1}
        >
          <Checkbox
            id='dynamic-break-words'
            mr={2}
            borderWidth={0}
            borderColor={"gray.600"}
            isChecked={textElement.data.dynamicFont.multiLine}
            onChange={evt =>
              handleUpdateMultiline((evt.target as HTMLInputElement).checked)
            }
          />
          <FormLabel fontSize={"12px"} htmlFor='dynamic-break-words' mb={"0"}>
            Allow text to break to multiple lines
          </FormLabel>
        </FormControl>
      )}
    </>
  );
};

DynamicFontSizeControls.displayName = "DynamicFontSizeControls";

export default DynamicFontSizeControls;
