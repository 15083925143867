import type { FC, PropsWithChildren } from "react";
import { useRef } from "react";

import { StoreApi } from "zustand";

import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import { AdElement, Datasources, type EditorState } from "@adflow/types";

import { Provider } from "../../contexts/EditorContext";
import { createEditorStore } from "../../store";

type Props = {
  dataSources: ReadonlyArray<Datasources.AllowableDataSource>;
  defaultStoreValues?: Partial<EditorState>;
  adElements?: Array<AdElement>;
  brand?: ConsoleAPIGraph.Brand;
  fonts?: Array<{
    fontFamily: string;
    weight: number;
    style: string;
    src: string;
  }>;
};

const EditorProvider: FC<PropsWithChildren<Props>> = ({
  children,
  dataSources,
  defaultStoreValues
}) => {
  const storeRef = useRef<StoreApi<EditorState> | null>(null);

  if (!storeRef.current) {
    storeRef.current = createEditorStore();
  }
  if (defaultStoreValues) {
    storeRef.current = createEditorStore(defaultStoreValues);
  }
  return (
    <Provider
      value={{
        store: storeRef.current,
        dataSources
      }}
    >
      {children}
    </Provider>
  );
};

EditorProvider.displayName = "EditorProvider";

export default EditorProvider;
