import { AdElementImage } from "@adflow/types";
import { Gender, OverlayType, Size, Type } from "./types";
import { LongSleevesMenAutoShadowOverlay } from "./longSleeves/men/auto/png/shadow";
import { NoSleevesMenAutoHighlightOverlay } from "./noSleeves/men/auto/png/highlight";
import { NoSleevesMenAutoShadowOverlay } from "./noSleeves/men/auto/png/shadow";
import { NoSleevesMenLargeHighlightOverlay } from "./noSleeves/men/large/png/highlight";
import { NoSleevesMenLargeShadowOverlay } from "./noSleeves/men/large/png/shadow";
import { NoSleevesMenMediumHighlightOverlay } from "./noSleeves/men/medium/png/highlight";
import { NoSleevesMenMediumShadowOverlay } from "./noSleeves/men/medium/png/shadow";
import { NoSleevesMenSmallHighlightOverlay } from "./noSleeves/men/small/png/highlight";
import { NoSleevesMenSmallShadowOverlay } from "./noSleeves/men/small/png/shadow";
import { NoSleevesWomenAutoHighlightOverlay } from "./noSleeves/women/auto/png/highlight";
import { NoSleevesWomenAutoShadowOverlay } from "./noSleeves/women/auto/png/shadow";
import { NoSleevesWomenLargeHighlightOverlay } from "./noSleeves/women/large/png/highlight";
import { NoSleevesWomenLargeShadowOverlay } from "./noSleeves/women/large/png/shadow";
import { NoSleevesWomenMediumHighlightOverlay } from "./noSleeves/women/medium/png/highlight";
import { NoSleevesWomenMediumShadowOverlay } from "./noSleeves/women/medium/png/shadow";
import { NoSleevesWomenSmallHighlightOverlay } from "./noSleeves/women/small/png/highlight";
import { NoSleevesWomenSmallShadowOverlay } from "./noSleeves/women/small/png/shadow";
import { LongSleevesMenLargeHighlightOverlay } from "./longSleeves/men/large/png/highlight";
import { LongSleevesMenLargeShadowOverlay } from "./longSleeves/men/large/png/shadow";
import { LongSleevesMenMediumHighlightOverlay } from "./longSleeves/men/medium/png/highlight";
import { LongSleevesMenMediumShadowOverlay } from "./longSleeves/men/medium/png/shadow";
import { LongSleevesMenSmallHighlightOverlay } from "./longSleeves/men/small/png/highlight";
import { LongSleevesMenSmallShadowOverlay } from "./longSleeves/men/small/png/shadow";
import { LongSleevesWomenAutoHighlightOverlay } from "./longSleeves/women/auto/png/highlight";
import { LongSleevesWomenAutoShadowOverlay } from "./longSleeves/women/auto/png/shadow";
import { LongSleevesWomenLargeHighlightOverlay } from "./longSleeves/women/large/png/highlight";
import { LongSleevesWomenLargeShadowOverlay } from "./longSleeves/women/large/png/shadow";
import { LongSleevesWomenMediumHighlightOverlay } from "./longSleeves/women/medium/png/highlight";
import { LongSleevesWomenMediumShadowOverlay } from "./longSleeves/women/medium/png/shadow";
import { LongSleevesWomenSmallHighlightOverlay } from "./longSleeves/women/small/png/highlight";
import { LongSleevesWomenSmallShadowOverlay } from "./longSleeves/women/small/png/shadow";
import { ShortSleevesMenAutoHighlightOverlay } from "./shortSleeves/men/auto/png/highlight";
import { ShortSleevesMenAutoShadowOverlay } from "./shortSleeves/men/auto/png/shadow";
import { ShortSleevesMenLargeHighlightOverlay } from "./shortSleeves/men/large/png/highlight";
import { ShortSleevesMenLargeShadowOverlay } from "./shortSleeves/men/large/png/shadow";
import { ShortSleevesMenMediumHighlightOverlay } from "./shortSleeves/men/medium/png/highlight";
import { ShortSleevesMenMediumShadowOverlay } from "./shortSleeves/men/medium/png/shadow";
import { ShortSleevesMenSmallHighlightOverlay } from "./shortSleeves/men/small/png/highlight";
import { ShortSleevesMenSmallShadowOverlay } from "./shortSleeves/men/small/png/shadow";
import { ShortSleevesWomenAutoHighlightOverlay } from "./shortSleeves/women/auto/png/highlight";
import { ShortSleevesWomenAutoShadowOverlay } from "./shortSleeves/women/auto/png/shadow";
import { ShortSleevesWomenLargeHighlightOverlay } from "./shortSleeves/women/large/png/highlight";
import { ShortSleevesWomenLargeShadowOverlay } from "./shortSleeves/women/large/png/shadow";
import { ShortSleevesWomenMediumHighlightOverlay } from "./shortSleeves/women/medium/png/highlight";
import { ShortSleevesWomenMediumShadowOverlay } from "./shortSleeves/women/medium/png/shadow";
import { ShortSleevesWomenSmallHighlightOverlay } from "./shortSleeves/women/small/png/highlight";
import { ShortSleevesWomenSmallShadowOverlay } from "./shortSleeves/women/small/png/shadow";
import { LongSleevesMenAutoHighlightOverlay } from "./longSleeves/men/auto/png/highlight";

const MAX_SMALL_SIZE = 48;
const MAX_MEDIUM_SIZE = 96;
const MAX_LARGE_SIZE = 200;

const imageMap: Partial<
  Record<
    Type,
    Partial<Record<Gender, Record<Size, Record<OverlayType, string>>>>
  >
> = {
  no_sleeves: {
    men: {
      auto: {
        highlight: NoSleevesMenAutoHighlightOverlay,
        shadow: NoSleevesMenAutoShadowOverlay
      },
      large: {
        highlight: NoSleevesMenLargeHighlightOverlay,
        shadow: NoSleevesMenLargeShadowOverlay
      },
      medium: {
        highlight: NoSleevesMenMediumHighlightOverlay,
        shadow: NoSleevesMenMediumShadowOverlay
      },
      small: {
        highlight: NoSleevesMenSmallHighlightOverlay,
        shadow: NoSleevesMenSmallShadowOverlay
      }
    },
    women: {
      auto: {
        highlight: NoSleevesWomenAutoHighlightOverlay,
        shadow: NoSleevesWomenAutoShadowOverlay
      },
      large: {
        highlight: NoSleevesWomenLargeHighlightOverlay,
        shadow: NoSleevesWomenLargeShadowOverlay
      },
      medium: {
        highlight: NoSleevesWomenMediumHighlightOverlay,
        shadow: NoSleevesWomenMediumShadowOverlay
      },
      small: {
        highlight: NoSleevesWomenSmallHighlightOverlay,
        shadow: NoSleevesWomenSmallShadowOverlay
      }
    }
  },
  long_sleeves: {
    men: {
      auto: {
        highlight: LongSleevesMenAutoHighlightOverlay,
        shadow: LongSleevesMenAutoShadowOverlay
      },
      large: {
        highlight: LongSleevesMenLargeHighlightOverlay,
        shadow: LongSleevesMenLargeShadowOverlay
      },
      medium: {
        highlight: LongSleevesMenMediumHighlightOverlay,
        shadow: LongSleevesMenMediumShadowOverlay
      },
      small: {
        highlight: LongSleevesMenSmallHighlightOverlay,
        shadow: LongSleevesMenSmallShadowOverlay
      }
    },
    women: {
      auto: {
        highlight: LongSleevesWomenAutoHighlightOverlay,
        shadow: LongSleevesWomenAutoShadowOverlay
      },
      large: {
        highlight: LongSleevesWomenLargeHighlightOverlay,
        shadow: LongSleevesWomenLargeShadowOverlay
      },
      medium: {
        highlight: LongSleevesWomenMediumHighlightOverlay,
        shadow: LongSleevesWomenMediumShadowOverlay
      },
      small: {
        highlight: LongSleevesWomenSmallHighlightOverlay,
        shadow: LongSleevesWomenSmallShadowOverlay
      }
    }
  },
  short_sleeves: {
    men: {
      auto: {
        highlight: ShortSleevesMenAutoHighlightOverlay,
        shadow: ShortSleevesMenAutoShadowOverlay
      },
      large: {
        highlight: ShortSleevesMenLargeHighlightOverlay,
        shadow: ShortSleevesMenLargeShadowOverlay
      },
      medium: {
        highlight: ShortSleevesMenMediumHighlightOverlay,
        shadow: ShortSleevesMenMediumShadowOverlay
      },
      small: {
        highlight: ShortSleevesMenSmallHighlightOverlay,
        shadow: ShortSleevesMenSmallShadowOverlay
      }
    },
    women: {
      auto: {
        highlight: ShortSleevesWomenAutoHighlightOverlay,
        shadow: ShortSleevesWomenAutoShadowOverlay
      },
      large: {
        highlight: ShortSleevesWomenLargeHighlightOverlay,
        shadow: ShortSleevesWomenLargeShadowOverlay
      },
      medium: {
        highlight: ShortSleevesWomenMediumHighlightOverlay,
        shadow: ShortSleevesWomenMediumShadowOverlay
      },
      small: {
        highlight: ShortSleevesWomenSmallHighlightOverlay,
        shadow: ShortSleevesWomenSmallShadowOverlay
      }
    }
  },
  american_football: {},
  baseball: {},
  ice_hockey: {}
};

function fitSize(size: AdElementImage["size"]): Size {
  if (size.height <= MAX_SMALL_SIZE && size.width <= MAX_SMALL_SIZE) {
    return "small";
  }
  if (size.height <= MAX_MEDIUM_SIZE && size.width <= MAX_MEDIUM_SIZE) {
    return "medium";
  }
  if (size.height <= MAX_LARGE_SIZE && size.width <= MAX_LARGE_SIZE) {
    return "large";
  }
  return "auto";
}

export default function getLayerImage(
  kind: Type,
  overlayType: OverlayType,
  gender: Gender,
  size: AdElementImage["size"]
): string {
  const kindMap = imageMap[kind];
  if (!kindMap) {
    return "";
  }
  const genderMap = kindMap[gender];
  if (!genderMap) {
    return "";
  }
  const sizeMap = genderMap[fitSize(size)];
  return sizeMap[overlayType];
}
