var __defProp = Object.defineProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};

// src/generated/console-api-graph/graphql.ts
var graphql_exports = {};
__export(graphql_exports, {
  GetBrandDocument: () => GetBrandDocument,
  GetBrandsListDocument: () => GetBrandsListDocument,
  GetTeamsListDocument: () => GetTeamsListDocument,
  GetUserFontsDocument: () => GetUserFontsDocument,
  getSdk: () => getSdk
});

// ../../node_modules/tslib/tslib.es6.mjs
var __assign = function() {
  __assign = Object.assign || function __assign2(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s)
        if (Object.prototype.hasOwnProperty.call(s, p))
          t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};

// ../../node_modules/graphql-tag/lib/index.js
import { parse } from "graphql";
var docCache = /* @__PURE__ */ new Map();
var fragmentSourceMap = /* @__PURE__ */ new Map();
var printFragmentWarnings = true;
var experimentalFragmentVariables = false;
function normalize(string) {
  return string.replace(/[\s,]+/g, " ").trim();
}
function cacheKeyFromLoc(loc) {
  return normalize(loc.source.body.substring(loc.start, loc.end));
}
function processFragments(ast) {
  var seenKeys = /* @__PURE__ */ new Set();
  var definitions = [];
  ast.definitions.forEach(function(fragmentDefinition) {
    if (fragmentDefinition.kind === "FragmentDefinition") {
      var fragmentName = fragmentDefinition.name.value;
      var sourceKey = cacheKeyFromLoc(fragmentDefinition.loc);
      var sourceKeySet = fragmentSourceMap.get(fragmentName);
      if (sourceKeySet && !sourceKeySet.has(sourceKey)) {
        if (printFragmentWarnings) {
          console.warn("Warning: fragment with name " + fragmentName + " already exists.\ngraphql-tag enforces all fragment names across your application to be unique; read more about\nthis in the docs: http://dev.apollodata.com/core/fragments.html#unique-names");
        }
      } else if (!sourceKeySet) {
        fragmentSourceMap.set(fragmentName, sourceKeySet = /* @__PURE__ */ new Set());
      }
      sourceKeySet.add(sourceKey);
      if (!seenKeys.has(sourceKey)) {
        seenKeys.add(sourceKey);
        definitions.push(fragmentDefinition);
      }
    } else {
      definitions.push(fragmentDefinition);
    }
  });
  return __assign(__assign({}, ast), { definitions });
}
function stripLoc(doc) {
  var workSet = new Set(doc.definitions);
  workSet.forEach(function(node) {
    if (node.loc)
      delete node.loc;
    Object.keys(node).forEach(function(key) {
      var value = node[key];
      if (value && typeof value === "object") {
        workSet.add(value);
      }
    });
  });
  var loc = doc.loc;
  if (loc) {
    delete loc.startToken;
    delete loc.endToken;
  }
  return doc;
}
function parseDocument(source) {
  var cacheKey = normalize(source);
  if (!docCache.has(cacheKey)) {
    var parsed = parse(source, {
      experimentalFragmentVariables,
      allowLegacyFragmentVariables: experimentalFragmentVariables
    });
    if (!parsed || parsed.kind !== "Document") {
      throw new Error("Not a valid GraphQL document.");
    }
    docCache.set(cacheKey, stripLoc(processFragments(parsed)));
  }
  return docCache.get(cacheKey);
}
function gql(literals) {
  var args = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }
  if (typeof literals === "string") {
    literals = [literals];
  }
  var result = literals[0];
  args.forEach(function(arg, i) {
    if (arg && arg.kind === "Document") {
      result += arg.loc.source.body;
    } else {
      result += arg;
    }
    result += literals[i + 1];
  });
  return parseDocument(result);
}
function resetCaches() {
  docCache.clear();
  fragmentSourceMap.clear();
}
function disableFragmentWarnings() {
  printFragmentWarnings = false;
}
function enableExperimentalFragmentVariables() {
  experimentalFragmentVariables = true;
}
function disableExperimentalFragmentVariables() {
  experimentalFragmentVariables = false;
}
var extras = {
  gql,
  resetCaches,
  disableFragmentWarnings,
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables
};
(function(gql_1) {
  gql_1.gql = extras.gql, gql_1.resetCaches = extras.resetCaches, gql_1.disableFragmentWarnings = extras.disableFragmentWarnings, gql_1.enableExperimentalFragmentVariables = extras.enableExperimentalFragmentVariables, gql_1.disableExperimentalFragmentVariables = extras.disableExperimentalFragmentVariables;
})(gql || (gql = {}));
gql["default"] = gql;
var lib_default = gql;

// src/generated/console-api-graph/graphql.ts
var GetBrandDocument = lib_default`
  query getBrand($id: ID) {
    brand(_id: $id) {
      _id
      name
      lastUpdatedBy {
        _id
        name
        teams {
          team
          manage
        }
      }
      team
      fonts
      createdBy {
        _id
        name
        teams {
          team
          manage
        }
      }
      createdAt
      backgrounds
      backgroundImageSets
      adTemplate {
        market {
          oddsButtonTemplate
        }
        potentialWinnings {
          stake
          currency
        }
        team {
          badge {
            borderColor
            filters {
              competitions
              operator
              sports
            }
            leagueLogos {
              competitions
              operator
            }
          }
          useAdditionalBrandingOptions
          useCustomAssets
          useHorseRacingSilks
          useTeamBadges
          useTeamColors
          useTeamJerseys
          useTeamBranding
        }
      }
    }
  }
`;
var GetBrandsListDocument = lib_default`
  query getBrandsList($teamId: String) {
    brands(query: { type: "social", team: $teamId }) {
      edges {
        node {
          _id
          name
        }
      }
    }
  }
`;
var GetTeamsListDocument = lib_default`
  query getTeamsList {
    teams(query: { features: { socialBrands: true } }) {
      edges {
        node {
          _id
          name
        }
      }
    }
  }
`;
var GetUserFontsDocument = lib_default`
  query getUserFonts($teamId: String) {
    userFonts(query: { team: $teamId, archived: false }) {
      edges {
        node {
          _id
          name
          family
          fontFamily
          url
        }
      }
    }
  }
`;
var defaultWrapper = (action, _operationName, _operationType) => action();
function getSdk(client, withWrapper = defaultWrapper) {
  return {
    getBrand(variables, requestHeaders) {
      return withWrapper(
        (wrappedRequestHeaders) => client.request(GetBrandDocument, variables, {
          ...requestHeaders,
          ...wrappedRequestHeaders
        }),
        "getBrand",
        "query"
      );
    },
    getBrandsList(variables, requestHeaders) {
      return withWrapper(
        (wrappedRequestHeaders) => client.request(GetBrandsListDocument, variables, {
          ...requestHeaders,
          ...wrappedRequestHeaders
        }),
        "getBrandsList",
        "query"
      );
    },
    getTeamsList(variables, requestHeaders) {
      return withWrapper(
        (wrappedRequestHeaders) => client.request(GetTeamsListDocument, variables, {
          ...requestHeaders,
          ...wrappedRequestHeaders
        }),
        "getTeamsList",
        "query"
      );
    },
    getUserFonts(variables, requestHeaders) {
      return withWrapper(
        (wrappedRequestHeaders) => client.request(GetUserFontsDocument, variables, {
          ...requestHeaders,
          ...wrappedRequestHeaders
        }),
        "getUserFonts",
        "query"
      );
    }
  };
}

// src/generated/console-api-graph/graphql.schema.ts
var graphql_schema_exports = {};
__export(graphql_schema_exports, {
  getBrandQuerySchema: () => getBrandQuerySchema,
  getBrandsListQuerySchema: () => getBrandsListQuerySchema,
  getTeamsListQuerySchema: () => getTeamsListQuerySchema,
  getUserFontsQuerySchema: () => getUserFontsQuerySchema,
  scalarsSchema: () => scalarsSchema
});
import { z } from "zod";
var scalarsSchema = z.object({
  ID: z.object({
    input: z.string(),
    output: z.string()
  }),
  String: z.object({
    input: z.string(),
    output: z.string()
  }),
  Boolean: z.object({
    input: z.boolean(),
    output: z.boolean()
  }),
  Int: z.object({
    input: z.number(),
    output: z.number()
  }),
  Float: z.object({
    input: z.number(),
    output: z.number()
  }),
  DateTime: z.object({
    input: z.any(),
    output: z.any()
  }),
  JSON: z.object({
    input: z.any(),
    output: z.any()
  })
});
var getBrandQuerySchema = z.object({
  __typename: z.literal("Query").optional(),
  brand: z.object({
    __typename: z.literal("Brand").optional(),
    _id: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
    team: z.string().optional().nullable(),
    fonts: z.any().optional().nullable(),
    createdAt: z.any().optional().nullable(),
    backgrounds: z.any().optional().nullable(),
    backgroundImageSets: z.any().optional().nullable(),
    lastUpdatedBy: z.object({
      __typename: z.literal("User").optional(),
      _id: z.string().optional().nullable(),
      name: z.string().optional().nullable(),
      teams: z.array(
        z.object({
          __typename: z.literal("UsersTeams").optional(),
          team: z.string().optional().nullable(),
          manage: z.boolean().optional().nullable()
        }).nullable()
      ).optional().nullable()
    }).optional().nullable(),
    createdBy: z.object({
      __typename: z.literal("User").optional(),
      _id: z.string().optional().nullable(),
      name: z.string().optional().nullable(),
      teams: z.array(
        z.object({
          __typename: z.literal("UsersTeams").optional(),
          team: z.string().optional().nullable(),
          manage: z.boolean().optional().nullable()
        }).nullable()
      ).optional().nullable()
    }).optional().nullable(),
    adTemplate: z.object({
      __typename: z.literal("AdTemplate").optional(),
      market: z.object({
        __typename: z.literal("AdTemplateMarket").optional(),
        oddsButtonTemplate: z.string().optional().nullable()
      }).optional().nullable(),
      potentialWinnings: z.object({
        __typename: z.literal("PotentialWinnings").optional(),
        stake: z.number().optional().nullable(),
        currency: z.string().optional().nullable()
      }).optional().nullable(),
      team: z.object({
        __typename: z.literal("AdTemplateTeam").optional(),
        useAdditionalBrandingOptions: z.boolean().optional().nullable(),
        useCustomAssets: z.boolean().optional().nullable(),
        useHorseRacingSilks: z.boolean().optional().nullable(),
        useTeamBadges: z.boolean().optional().nullable(),
        useTeamColors: z.boolean().optional().nullable(),
        useTeamJerseys: z.boolean().optional().nullable(),
        useTeamBranding: z.boolean().optional().nullable(),
        badge: z.object({
          __typename: z.literal("AdTemplateBadge").optional(),
          borderColor: z.string().optional().nullable(),
          filters: z.array(
            z.object({
              __typename: z.literal("BadgeFilters").optional(),
              competitions: z.array(z.string().nullable()).optional().nullable(),
              operator: z.string().optional().nullable(),
              sports: z.array(z.string().nullable()).optional().nullable()
            }).nullable()
          ).optional().nullable(),
          leagueLogos: z.array(
            z.object({
              __typename: z.literal("LeagueLogosBadgeFilters").optional(),
              competitions: z.array(z.string().nullable()).optional().nullable(),
              operator: z.string().optional().nullable()
            }).nullable()
          ).optional().nullable()
        }).optional().nullable()
      }).optional().nullable()
    }).optional().nullable()
  }).optional().nullable()
});
var getBrandsListQuerySchema = z.object({
  __typename: z.literal("Query").optional(),
  brands: z.object({
    __typename: z.literal("Brands").optional(),
    edges: z.array(
      z.object({
        __typename: z.literal("BrandEdge").optional(),
        node: z.object({
          __typename: z.literal("Brand").optional(),
          _id: z.string().optional().nullable(),
          name: z.string().optional().nullable()
        }).optional().nullable()
      }).nullable()
    ).optional().nullable()
  }).optional().nullable()
});
var getTeamsListQuerySchema = z.object({
  __typename: z.literal("Query").optional(),
  teams: z.object({
    __typename: z.literal("Teams").optional(),
    edges: z.array(
      z.object({
        __typename: z.literal("TeamEdge").optional(),
        node: z.object({
          __typename: z.literal("Team").optional(),
          _id: z.string().optional().nullable(),
          name: z.string().optional().nullable()
        }).optional().nullable()
      }).nullable()
    ).optional().nullable()
  }).optional().nullable()
});
var getUserFontsQuerySchema = z.object({
  __typename: z.literal("Query").optional(),
  userFonts: z.object({
    __typename: z.literal("UserFontConnection").optional(),
    edges: z.array(
      z.object({
        __typename: z.literal("UserFontEdge").optional(),
        node: z.object({
          __typename: z.literal("UserFont").optional(),
          _id: z.string(),
          name: z.string(),
          family: z.string(),
          fontFamily: z.string(),
          url: z.string()
        }).optional().nullable()
      }).nullable()
    ).optional().nullable()
  }).optional().nullable()
});

// src/generated/creative-hub-api/graphql.ts
var graphql_exports2 = {};
__export(graphql_exports2, {
  GetFontFamiliesByOwnerDocument: () => GetFontFamiliesByOwnerDocument,
  UploadOneFontFamilyDocument: () => UploadOneFontFamilyDocument
});
var GetFontFamiliesByOwnerDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "query", "name": { "kind": "Name", "value": "getFontFamiliesByOwner" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "getFontFamiliesByOwner" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "id" } }, { "kind": "Field", "name": { "kind": "Name", "value": "fonts" }, "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "variant" } }, { "kind": "Field", "name": { "kind": "Name", "value": "size" } }, { "kind": "Field", "name": { "kind": "Name", "value": "path" } }, { "kind": "Field", "name": { "kind": "Name", "value": "createdAt" } }] } }, { "kind": "Field", "name": { "kind": "Name", "value": "createdAt" } }, { "kind": "Field", "name": { "kind": "Name", "value": "name" } }, { "kind": "Field", "name": { "kind": "Name", "value": "updatedAt" } }] } }] } }] };
var UploadOneFontFamilyDocument = { "kind": "Document", "definitions": [{ "kind": "OperationDefinition", "operation": "mutation", "name": { "kind": "Name", "value": "uploadOneFontFamily" }, "variableDefinitions": [{ "kind": "VariableDefinition", "variable": { "kind": "Variable", "name": { "kind": "Name", "value": "data" } }, "type": { "kind": "NonNullType", "type": { "kind": "NamedType", "name": { "kind": "Name", "value": "FontFamilyUploadInput" } } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "uploadOneFontFamily" }, "arguments": [{ "kind": "Argument", "name": { "kind": "Name", "value": "data" }, "value": { "kind": "Variable", "name": { "kind": "Name", "value": "data" } } }], "selectionSet": { "kind": "SelectionSet", "selections": [{ "kind": "Field", "name": { "kind": "Name", "value": "name" } }] } }] } }] };

// graphql/creative-hub-api/brandkit-fonts.gql.ts
var brandkit_fonts_gql_exports = {};
__export(brandkit_fonts_gql_exports, {
  GET_ALL_FONTS_BY_OWNER: () => GET_ALL_FONTS_BY_OWNER,
  UPLOAD_ONE_FONT_FAMILY: () => UPLOAD_ONE_FONT_FAMILY
});

// src/generated/creative-hub-api/gql.ts
var documents = {
  "\n  query getFontFamiliesByOwner {\n    getFontFamiliesByOwner {\n      id\n      fonts {\n        variant\n        size\n        path\n        createdAt\n      }\n      createdAt\n      name\n      updatedAt\n    }\n  }\n": GetFontFamiliesByOwnerDocument,
  "\n  mutation uploadOneFontFamily($data: FontFamilyUploadInput!) {\n    uploadOneFontFamily(data: $data) {\n      name\n    }\n  }\n": UploadOneFontFamilyDocument
};
function gql2(source) {
  return documents[source] ?? {};
}

// graphql/creative-hub-api/brandkit-fonts.gql.ts
var GET_ALL_FONTS_BY_OWNER = gql2(
  /* GraphQL */
  `
  query getFontFamiliesByOwner {
    getFontFamiliesByOwner {
      id
      fonts {
        variant
        size
        path
        createdAt
      }
      createdAt
      name
      updatedAt
    }
  }
`
);
var UPLOAD_ONE_FONT_FAMILY = gql2(
  /* GraphQL */
  `
  mutation uploadOneFontFamily($data: FontFamilyUploadInput!) {
    uploadOneFontFamily(data: $data) {
      name
    }
  }
`
);
export {
  brandkit_fonts_gql_exports as BrandKitGQL,
  graphql_exports2 as BrandKitTypes,
  graphql_exports as ConsoleAPIGraph,
  graphql_schema_exports as ConsoleAPIGraphSchema
};
