import React, { useCallback, useContext, useMemo, useState } from "react";

import { shallow } from "zustand/shallow";

import { Datasources, isDisplayOptionsOddsType } from "@adflow/types";
import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberInput,
  NumberInputField,
  Select,
  Stack
} from "@chakra-ui/react";
import Box from "@mui/material/Box";
import {
  ActionMeta,
  Select as MultiSelect,
  MultiValue
} from "chakra-react-select";
import { EditorStoreContext } from "../../";
import { useStore } from "../../hooks/useStore";
import Button from "../ThemedButton";

type Option = {
  label: string;
  value: Datasources.SourceType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiDataSourcesForm = ({ firstFieldRef, onCancel }: any) => {
  const ctx = useContext(EditorStoreContext);

  const s = useStore(
    state => ({
      elements: state.elements,
      dataSources: state.dataSources,
      displayOptions: state.displayOptions,
      setDisplayOptions: state.setDisplayOptions,
      addDataSource: state.addDataSource,
      removeDataSource: state.removeDataSource,
      removeElementDataSource: state.removeElementDataSource
    }),
    shallow
  );
  const [selectedOptions, addOption, removeOption] =
    useSet<Datasources.SourceType>(new Set(s.dataSources.map(s => s.type)));

  const [displayOptionsOdds, setDisplayOptionsOdds] = useState({
    type: "decimalOdds" as const,
    ...s.displayOptions.odds
  });
  const templateOptions = useMemo(() => {
    return ctx.dataSources.filter(
      source => source.dataType === "TEMPLATE" && source.type !== "EVENT"
    );
  }, [ctx.dataSources]);

  const defaultTemplateOption = s.dataSources.find(
    ds => Datasources.getDataTypeFromSourceType(ds.type) === "TEMPLATE"
  )?.type;

  const marketOptions = useMemo(() => {
    const marketSources = ctx.dataSources.filter(
      source => source.dataType === "MARKET"
    );
    return marketSources.map(s => {
      return {
        value: Datasources.SourceTypeSchema.parse(s.type),
        label: Datasources.getFriendlySourceNameFromSourceType(s.type)
      };
    });
  }, [ctx.dataSources]);

  const defaultMarketOptions = useMemo(() => {
    return marketOptions.filter(mo => {
      if (s.dataSources.find(ds => ds.type === mo.value)) {
        return mo;
      }
    });
  }, [marketOptions, s.dataSources]);

  const handleChangeTemplate: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      evt => {
        const newVal = evt.target.value;
        selectedOptions.forEach(option => {
          if (
            Datasources.getDataTypeFromSourceType(option) === "TEMPLATE" &&
            option !== newVal
          ) {
            removeOption(option);
          }
        });

        if (newVal === "" || newVal == null) {
          return;
        }
        const parsedVal = Datasources.SourceTypeSchema.parse(newVal);
        addOption(parsedVal);
      },
      [addOption, removeOption, selectedOptions]
    );

  const handleChangeMarket = useCallback(
    (values: MultiValue<Option>, actionType: ActionMeta<Option>) => {
      if (
        actionType.action === "remove-value" ||
        actionType.action === "pop-value"
      ) {
        removeOption(actionType.removedValue.value);
      }
      if (actionType.action === "select-option" && actionType.option) {
        addOption(actionType.option.value);
      }
      if (actionType.action === "clear") {
        actionType.removedValues.forEach(option => {
          removeOption(option.value);
        });
      }
    },
    [addOption, removeOption]
  );

  const handleChangeOddsFormat: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(evt => {
      const oddsFormat = evt.target.value;
      if (isDisplayOptionsOddsType(oddsFormat)) {
        setDisplayOptionsOdds(prevVal => {
          if (oddsFormat === "potentialWinnings") {
            return { ...prevVal, type: oddsFormat, stake: 10, currency: "GBP" };
          }
          return { ...prevVal, type: oddsFormat, stake: null, currency: null };
        });
      }
    }, []);

  const setOddsStakeCurrency: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(evt => {
      const currency = evt.target.value;
      setDisplayOptionsOdds(prevVal => ({ ...prevVal, currency }));
    }, []);

  const setOddsStakeAmount: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(evt => {
      const stake = parseInt(evt.target.value);
      if (!isNaN(stake)) {
        setDisplayOptionsOdds(prevVal => ({ ...prevVal, stake }));
      }
    }, []);

  // Returns the static ID of a data source, if it exists
  const findIDofDatasource = (
    type: Datasources.SourceType
  ): string | undefined => {
    return (ctx.dataSources.find(ds => ds.type === type) || {}).id;
  };

  const onSave = () => {
    const selectedOptionsArray = Array.from(selectedOptions);

    // Add any new data sources
    selectedOptionsArray.forEach(option => {
      const dataSourceExists = s.dataSources.some(
        dataSource => dataSource.type === option
      );
      if (!dataSourceExists) {
        s.addDataSource(
          option,
          Datasources.getDataTypeFromSourceType(option),
          findIDofDatasource(option)
        );
      }
    });

    // Remove any data sources that are not selected
    s.dataSources.forEach(dataSource => {
      const optionExists = selectedOptionsArray.includes(dataSource.type);
      if (!optionExists) {
        s.removeDataSource(dataSource.id);
        // cleanup any data sources from elements
        s.elements.forEach(el => {
          s.removeElementDataSource(el.id, dataSource.id);
        });
      }
    });
    if (displayOptionsOdds) {
      s.setDisplayOptions("odds", displayOptionsOdds);
    }
    onCancel();
  };

  return (
    <Stack spacing={4} data-testid='configureDatasources'>
      <Heading size='md'>Configure Datasources</Heading>
      <Divider />
      <Heading size='sm'>Template Type</Heading>
      <Select
        data-testid='selectDatasource'
        ref={firstFieldRef}
        placeholder='Select Template Type'
        onChange={handleChangeTemplate}
        defaultValue={defaultTemplateOption}
      >
        {templateOptions.map(ds => (
          <option key={ds.name} value={ds.type}>
            {ds.name}
          </option>
        ))}
      </Select>
      <Divider />
      <Heading size='sm'>Market Types</Heading>
      <MultiSelect
        isMulti
        placeholder='Select Market Types'
        options={marketOptions}
        onChange={handleChangeMarket}
        defaultValue={defaultMarketOptions}
      ></MultiSelect>
      <Divider />
      <Heading size='sm'>Odds Format</Heading>
      <Select
        data-testid='oddsDisplay'
        placeholder='Select Odds Format'
        defaultValue={displayOptionsOdds?.type}
        onChange={handleChangeOddsFormat}
      >
        <option value='fractionalOdds'>Fractional</option>
        <option value='decimalOdds'>Decimal</option>
        <option value='europeanDecimalOdds'>European Decimal</option>
        <option value='americanOdds'>American</option>
        <option value='potentialWinnings'>Potential winnings</option>
      </Select>
      {displayOptionsOdds?.type === "potentialWinnings" ? (
        <Flex flexDirection='row' gap='3'>
          <FormControl flex='1'>
            <FormLabel>Stake</FormLabel>
            <NumberInput min={1} value={displayOptionsOdds.stake ?? ""}>
              <NumberInputField
                onChange={setOddsStakeAmount}
                data-testid='stakeAmountInput'
              />
            </NumberInput>
          </FormControl>
          <FormControl flex='1'>
            <FormLabel>Currency</FormLabel>
            <Select
              data-testid='currencySelect'
              value={displayOptionsOdds.currency ?? ""}
              onChange={setOddsStakeCurrency}
            >
              {currencies.map(c => (
                <option key={c.name} value={c.value}>
                  {c.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      ) : null}
      <Box display={"flex"} justifyContent={"flex-end"} gap={"1rem"}>
        <Button muiButtonProps={{ color: "secondary", onClick: onCancel }}>
          Cancel
        </Button>
        <Button
          testId='saveButton'
          muiButtonProps={{ color: "primary", onClick: onSave }}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
};

function useSet<T>(
  initialSet: Set<T> = new Set()
): [Set<T>, (value: T) => void, (value: T) => void] {
  const [set, setSet] = useState(initialSet);

  const add = useCallback((value: T) => {
    setSet(prevSet => new Set(prevSet).add(value));
  }, []);

  const remove = useCallback((value: T) => {
    setSet(prevSet => {
      const newSet = new Set(prevSet);
      newSet.delete(value);
      return newSet;
    });
  }, []);

  return [set, add, remove];
}

export default MultiDataSourcesForm;

const currencies = [
  {
    name: "GBP",
    value: "GBP"
  },
  {
    name: "EUR",
    value: "EUR"
  },
  {
    name: "USD",
    value: "USD"
  },
  {
    name: "BRL",
    value: "BRL"
  },
  {
    name: "INR",
    value: "INR"
  },
  {
    name: "NGN",
    value: "NGN"
  },
  {
    name: "KES",
    value: "KES"
  },
  {
    name: "GHS",
    value: "GHS"
  },
  {
    name: "ZMK",
    value: "ZMK"
  },
  {
    name: "TZS",
    value: "TZS"
  },
  {
    name: "CDF",
    value: "CDF"
  },
  {
    name: "MZN",
    value: "MZN"
  },
  {
    name: "MMK",
    value: "MMK"
  },
  {
    name: "ETB",
    value: "ETB"
  },
  {
    name: "RUB",
    value: "RUB"
  },
  {
    name: "BGN",
    value: "BGN"
  }
];
