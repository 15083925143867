import { createBrowserRouter } from "react-router-dom";
import { Router } from "@remix-run/router";
import { Root } from "./Root/main";

import { Index as TemplatesIndex } from "./Templates/pages/Index";
import Edit from "./Templates/pages/Edit";
import Create from "./Templates/pages/Create";
import Duplicate from "./Templates/pages/Duplicate";
import { Index as BrandKitIndex } from "./BrandKit/pages/Index";
import { Index as CatalogueManagementIndex } from "./CatalogueManagement";
import { Index as AdTagManagementIndex } from "./AdTag";

export function createBrowserRouterWrapper(basename: string): Router {
  return createBrowserRouter(
    [
      {
        path: "/",
        element: <Root />,
        children: [
          {
            path: "/templates//*",
            children: [
              { index: true, element: <TemplatesIndex /> },
              { path: "edit/:id", element: <Edit /> },
              { path: "create", element: <Create /> },
              { path: "duplicate/:id", element: <Duplicate /> }
            ]
          },
          {
            path: "/brand-kit//*",
            children: [{ index: true, element: <BrandKitIndex /> }]
          },
          {
            path: "/catalogues//*",
            children: [{ index: true, element: <CatalogueManagementIndex /> }]
          },
          {
            path: "/ad-tags//*",
            children: [{ index: true, element: <AdTagManagementIndex /> }]
          }
        ]
      }
    ],
    { basename }
  );
}
