import { useEditorStore } from "@adflow/editor";
import { Box } from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { PreviewOverlayElement } from "../../types";

export type Props = {
  adElement: PreviewOverlayElement;
  selectedId?: string | null;
  elementIndex: number;
};

const SelectOverlayElement: FC<Props> = ({ adElement, elementIndex }) => {
  const [inElement, setInElement] = useState(false);
  const s = useEditorStore(state => ({
    setSelectedElement: state.setElementSelected
  }));

  const handleClick = useCallback(() => {
    s.setSelectedElement(adElement.id);
  }, [adElement, s]);

  const handleMouseEnter = useCallback(() => {
    setInElement(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setInElement(false);
  }, []);

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      key={adElement.id}
      style={{
        left: adElement?.position.x,
        top: adElement?.position.y,
        width: adElement?.size.width,
        height: adElement?.size.height,
        border: inElement ? "2px solid rgb(0, 92, 255)" : undefined,
        cursor: inElement ? "pointer" : undefined,
        position: "absolute",
        display: "flex",
        borderRadius: "30px",
        zIndex: 900 - elementIndex
      }}
    />
  );
};

SelectOverlayElement.displayName = "SelectOverlayElement";

export default SelectOverlayElement;
