import { AdElementImage } from "@adflow/types";
import { FC } from "react";
import { JerseyColour } from "./types";
import getLayerImage from "./jersey/getLayerImage";
import React from "react";
import { Gender, LayerId, Layers, Type } from "./jersey/types";
import getSvgParts, { layers } from "./jersey/getSvgParts";
import { DEFAULT_GENDER, SVG_VIEWBOX } from "./jersey/consts";
interface Props {
  type: Type;
  position: AdElementImage["position"];
  size: AdElementImage["size"];
  colour: JerseyColour;
  gender?: Gender;
  hasGradient?: boolean;
  svgOverlay?: boolean;
}

const DEFAULT_FILL = "#FFFFFF";

const fillColour = (
  type: Type,
  layerId: LayerId,
  jerseyColour: JerseyColour
): string | undefined => {
  if (layerId === "base") {
    return jerseyColour.background ? jerseyColour.background : DEFAULT_FILL;
  }
  if (layerId === "sleevelong") {
    return jerseyColour.sleeve;
  }
  if (layerId === "sleeve") {
    return jerseyColour.outline;
  }
  if (layerId === "stripes") {
    return jerseyColour.verticalStripe;
  }
  if (layerId === "horizontalstripes") {
    return jerseyColour.horizontalStripe;
  }
  if (layerId === "squares") {
    return jerseyColour.squares;
  }
  if (layerId === "split") {
    return jerseyColour.split;
  }
  return;
};

export const JerseyRealistic: FC<Props> = ({
  type,
  position,
  size,
  colour: jerseyColour,
  gender = DEFAULT_GENDER,
  hasGradient,
  svgOverlay = false
}) => {
  const gradientStyle = hasGradient
    ? { maskImage: "linear-gradient(180deg, #000 40%, transparent 75%)" }
    : {};

  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        overflow: "hidden",
        top: position.y,
        left: position.x,
        zIndex: position.z,
        ...size,
        ...gradientStyle
      }}
    >
      {/* Body */}
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: size.width,
          aspectRatio: "1/1"
        }}
      >
        <svg
          viewBox={`0 0 ${SVG_VIEWBOX} ${SVG_VIEWBOX}`}
          xmlns='http://www.w3.org/2000/svg'
        >
          {layers.map(layer => {
            const fill = fillColour(type, layer.id, jerseyColour);
            if (!fill) {
              return null;
            }
            return (
              <React.Fragment key={layer.id}>
                {getSvgParts({
                  fill: fill,
                  type: type,
                  gender: gender,
                  layer: layer.id
                })}
              </React.Fragment>
            );
          })}
          {svgOverlay && (
            <>
              <image
                width='100%'
                height='100%'
                href={getLayerImage(type, "shadow", gender, size)}
              />

              <image
                width='100%'
                height='100%'
                href={getLayerImage(type, "highlight", gender, size)}
              />
            </>
          )}
        </svg>
      </div>
      {/* Overlay */}
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: size.width,
          aspectRatio: "1/1"
        }}
      >
        <img
          style={{ width: size.width, aspectRatio: "1/1" }}
          src={getLayerImage(type, "shadow", gender, size)}
        />
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: size.width,
          aspectRatio: "1/1"
        }}
      >
        <img
          style={{ width: size.width, aspectRatio: "1/1" }}
          src={getLayerImage(type, "highlight", gender, size)}
        />
      </div>
    </div>
  );
};

JerseyRealistic.displayName = "JerseyRealistic";

export default JerseyRealistic;
