import { createContext, useContext, MutableRefObject } from "react";

type ProgressContextType = {
  elementsReadyState: MutableRefObject<
    Record<string, { id: string; ready: boolean }>
  >;
  updateElementReadyState: (id: string, ready: boolean) => void;
};

export const ProgressContext = createContext<ProgressContextType | undefined>(
  undefined
);

export function useProgress() {
  const context = useContext(ProgressContext);
  if (context === undefined) {
    throw new Error(
      "useProgress must be used within a ProgressContext.Provider"
    );
  }
  return context;
}

export function everyElementReady(
  elementsReadyState: MutableRefObject<
    Record<string, { id: string; ready: boolean }>
  >
) {
  return Object.values(elementsReadyState.current).every(
    ({ ready }) => ready === true
  );
}

export function noElementsToWaitFor(
  elementsReadyState: MutableRefObject<
    Record<string, { id: string; ready: boolean }>
  >
) {
  return Object.values(elementsReadyState.current).length === 0;
}
