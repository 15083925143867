import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef
} from "react";

import { AdElementSVG, isSVGElement, isTextElement } from "@adflow/types";
import { indexObjArray, useEffectOnce } from "@adflow/utils";
import {
  ProgressContext,
  everyElementReady,
  noElementsToWaitFor
} from "../ProgressContext";
import { AdElementRenderProps } from "../types";
import ImageElement from "./ImageElement";
import SVGElement from "./SVGElement";
import TeamKit from "./TeamKit";
import TextElement from "./TextElement";
import VectorElement from "./VectorElement";

import "../css-reset.css";

type Props = {
  // Probably want to update this type. This component doesn't
  // require a lot of the props on an AdTemplate.
  adElements: Array<AdElementRenderProps>;
  isPreview?: boolean;
};

function ElementSelector({
  element,
  isPreview
}: {
  element: AdElementRenderProps;
  isPreview?: boolean;
}) {
  if (element.type === "SVG") {
    return <SVGElement svgString={element.data.svg} />;
  }

  if (element.type === "TEXT") {
    return (
      <TextElement
        id={element.id}
        elData={element.data}
        sources={element.sources}
        position={element.position}
        size={element.size}
      />
    );
  }

  if (element.type === "TEAM_ASSET") {
    if (element.data.display?.type === "kit") {
      return (
        <TeamKit
          position={element.position}
          size={element.size}
          kitProps={element.data.display.data}
        />
      );
    }

    if (element.data.display?.type === "badge") {
      return (
        <ImageElement
          rotation={element.rotation}
          id={element.id}
          position={element.position}
          size={element.size}
          data={element.data.display.data}
          isPreview={isPreview}
        />
      );
    }
  }

  if (element.type === "IMAGE") {
    console.log(
      `testing that we have the current version deployed: ${JSON.stringify(
        element
      )}`
    );
    return (
      <ImageElement
        id={element.id}
        position={element.position}
        size={element.size}
        rotation={element.rotation}
        data={element.data}
        isPreview={isPreview}
      />
    );
  }

  if (element.type === "VECTOR") {
    return (
      <VectorElement
        data={element.data}
        position={element.position}
        name={element.name}
      ></VectorElement>
    );
  }

  return <></>;
}

const Ad: FC<Props> = ({ adElements, isPreview }) => {
  const elementsReadyState = useRef(
    indexObjArray(
      // Auto-fitted text needs to wait for fonts to load before it can
      // work out the best size. If something cannot be guaranteed to be ready
      // for capture 📸 by ad-readiness-check.js (in ad-renderer) you should consider
      // tracking the element here.
      //
      // Static text is also included because system fonts are loaded async
      // when rendering the ad in the cloud
      adElements.filter(isTextElement).map(el => ({ id: el.id, ready: false }))
    )
  );

  const updateElementReadyState = useCallback((id: string, ready: boolean) => {
    elementsReadyState.current[id] = {
      id: id,
      ready
    };

    if (everyElementReady(elementsReadyState)) {
      window.adReady = true;
    }
  }, []);

  // It is possible that an ad may not need to wait on anything, in this case
  // we should immediately set it as ready for capture 📸
  useEffectOnce(() => {
    if (noElementsToWaitFor(elementsReadyState)) {
      window.adReady = true;
    }
  });

  const rootElement = useMemo(() => {
    return adElements.find(isSVGElement);
  }, [adElements]);

  return (
    <AdWrapper rootElement={rootElement}>
      <ProgressContext.Provider
        value={{ elementsReadyState, updateElementReadyState }}
      >
        {adElements?.map(element => (
          <ElementSelector
            key={element.id}
            element={element}
            isPreview={isPreview}
          />
        ))}
      </ProgressContext.Provider>
    </AdWrapper>
  );
};

Ad.displayName = "AdflowAd";

export default Ad;

type AdWrapperProps = PropsWithChildren<{
  rootElement: AdElementSVG | undefined;
}>;

const AdWrapper: FC<AdWrapperProps> = ({ rootElement, children }) => {
  if (!rootElement) {
    return null;
  }
  const width = rootElement.size.width;
  const height = rootElement.size.height;
  return (
    <div
      id='ad_root'
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
        overflow: "hidden"
      }}
    >
      {children}
    </div>
  );
};

AdWrapper.displayName = "AdWrapper";
