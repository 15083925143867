import { AdElementImage } from "@adflow/types";
import { FC, useMemo } from "react";

type Props = {
  id: string;
  data: AdElementImage["data"];
  position: AdElementImage["position"];
  size: AdElementImage["size"];
  rotation?: AdElementImage["rotation"];
  isPreview?: boolean;
};

const ImageElement: FC<Props> = ({
  id,
  position,
  size,
  rotation,
  data,
  isPreview
}) => {
  const content = useMemo(() => {
    return data.content.startsWith("https://cdn.fresh8.co") && size.width
      ? `${data.content}?width=${size.width * 2}`
      : data.content;
  }, [data.content, size.width]);

  const src = useMemo(() => {
    if (content) {
      return content;
    }
    if (isPreview) {
      return `https://placehold.co/${size.width}x${size.height}`;
    }
    throw new Error(`Image element {${id}} content is empty`);
  }, [content, size.width, size.height]);
  return (
    <img
      id={id}
      src={src}
      width={size.width}
      height={size.height}
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        width: size.width,
        height: size.height,
        objectFit: "cover", // "contain" does ot work for images, as they dont have a wrapping div
        zIndex: position.z,
        transform: rotation ? `rotate(${rotation}rad)` : undefined
      }}
    />
  );
};

ImageElement.displayName = "ImageElement";

export default ImageElement;
