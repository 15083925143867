import { FC, useCallback, useContext, useMemo } from "react";
import DynamicStringInput, {
  Props as DynamicStringInputProps
} from "../DynamicStringInput";

import {
  AdElementImage,
  AdElementTeamAsset,
  AdElementText,
  Datasources,
  notEmpty
} from "@adflow/types";
import { EditorStoreContext } from "../..";

import { Heading } from "@chakra-ui/react";
import { useFlag } from "@unleash/proxy-client-react";
import { shallow } from "zustand/shallow";
import { useStore } from "../../hooks/useStore";
import { LabeledMultiDynamicStringInput } from "../MultiDynamicStringInput";

type Props = {
  selectedElement: AdElementTeamAsset | AdElementText | AdElementImage;
};

const DynamicContentTemplateInput: FC<Props> = ({ selectedElement }) => {
  const store = useStore(
    state => ({
      dataSources: state.dataSources,
      updateElementData: state.updateElementData,
      updateElementDataSources: state.updateElementDataSources
    }),
    shallow
  );
  const ctx = useContext(EditorStoreContext);
  const dataSourceDefinitions = ctx.dataSources;
  const dataSourceDefinitionsDataPoints = useMemo(() => {
    return dataSourceDefinitions.reduce(
      (acc, curr) => acc.concat(curr.dataPoints.map(dp => dp.name)),
      [] as Array<Datasources.AllowableDataPoints>
    );
  }, [dataSourceDefinitions]);

  const suggestions: Array<{
    id: string;
    display: string;
    sourceName: string;
    sourceTypeShort: string;
  }> = useMemo(() => {
    return store.dataSources
      .map(dSource => {
        const dsDef = dataSourceDefinitions.find(
          dsDef => dsDef.type === dSource.type
        );
        if (!dsDef) return null;

        return dsDef.getSuggestions(selectedElement.type);
      })
      .filter(notEmpty)
      .flat();
  }, [dataSourceDefinitions, selectedElement.type, store.dataSources]);

  const handleDynamicStringInputChange: DynamicStringInputProps["onChange"] =
    useCallback(
      (templatedString, mentions) => {
        if (!selectedElement) {
          return;
        }
        store.updateElementData(selectedElement.id, {
          ...selectedElement?.data,
          content: templatedString
        });

        const sources = mentions.map(mention => {
          const [sourceId, dataPointName] = mention.id.split(":");
          return {
            sourceId,
            name: Datasources.parseDataPointName(
              dataPointName,
              dataSourceDefinitionsDataPoints
            )
          };
        });

        store.updateElementDataSources(selectedElement.id, sources);
      },
      [dataSourceDefinitionsDataPoints, store, selectedElement]
    );

  return (
    <>
      <Heading fontSize='lg' fontWeight='semibold' mb='2'>
        Content template
      </Heading>
      <LabeledMultiDynamicStringInput
        value={selectedElement.data.content}
        suggestions={suggestions}
        onChange={handleDynamicStringInputChange}
      />
    </>
  );
};

DynamicContentTemplateInput.displayName = "DynamicContentTemplateInput";

export default DynamicContentTemplateInput;
export type { Props as DynamicContentTemplateInputProps };
