import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { OverlayProps, getElementIdsAndPositions } from "../../types";

const PreviewOverlay: FC<OverlayProps> = ({ adElements, selectedId }) => {
  const previewOverlayElements = getElementIdsAndPositions(adElements);

  if (selectedId) {
    const selectedElement = previewOverlayElements[selectedId];
    return (
      <Box
        style={{
          left: selectedElement?.position.x,
          top: selectedElement?.position.y,
          width: selectedElement?.size.width,
          height: selectedElement?.size.height,
          position: "absolute",
          display: "flex",
          border: "2px solid rgb(0, 92, 255)",
          background: "rgba(51, 68, 238, 0.2)",
          borderRadius: "30px",
          transition: "0.25s",
          zIndex: 800
        }}
      />
    );
  } else return <Box />;
};

PreviewOverlay.displayName = "PreviewOverlay";

export default PreviewOverlay;
