import { createContext, FC, PropsWithChildren, useContext } from "react";

import { AdElement } from "@adflow/types";

import { FigmaComponent, SavedComponent } from "./apiClient";

export interface State {
  fileId: string;
  error: string;
  components: Array<FigmaComponent>;
  listSavedComponents: Array<SavedComponent>;
  elements: Array<AdElement>;
  previewURL: string;
  componentThumbnailURL: string;
  thumbnailURL: string;
  activeStep: number;
  savedComponentId: string | null;
}

export const initialState: State = {
  error: "",
  fileId: "",
  components: [],
  listSavedComponents: [],
  elements: [],
  previewURL: "",
  componentThumbnailURL: "",
  thumbnailURL: "",
  activeStep: 1,
  savedComponentId: null
};
const ComponentImportStateContext = createContext<State>(initialState);

const ComponentImportDispatchContext = createContext<React.Dispatch<Action>>(
  {} as React.Dispatch<Action>
);

export const useComponentImportState = () => {
  return useContext(ComponentImportStateContext);
};

export const useComponentImportDispatch = () => {
  return useContext(ComponentImportDispatchContext);
};

type ProviderProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const ComponentImportContextProvider: FC<
  PropsWithChildren<ProviderProps>
> = props => {
  return (
    <ComponentImportStateContext.Provider value={props.state}>
      <ComponentImportDispatchContext.Provider value={props.dispatch}>
        {props.children}
      </ComponentImportDispatchContext.Provider>
    </ComponentImportStateContext.Provider>
  );
};

type Action =
  | {
      type: "step-one";
      payload: {
        components: Array<FigmaComponent>;
        fileId: string;
        thumbnailURL?: string;
      };
    }
  | {
      type: "step-two";
      payload: {
        previewURL: string;
        componentThumbnailURL: string;
        elements: Array<AdElement>;
      };
    }
  | {
      type: "update-previously-imported";
      payload: {
        listSavedComponents: Array<SavedComponent>;
      };
    }
  | {
      type: "error";
      error: string;
    }
  | {
      type: "reset";
      payload: State;
    }
  | {
      type: "use-saved-component";
      payload: string;
    };

export const stateReducer = (prevState: State, action: Action): State => {
  switch (action.type) {
    case "step-one":
      return {
        ...prevState,
        components: action.payload.components,
        fileId: action.payload.fileId,
        thumbnailURL: action.payload.thumbnailURL ?? "",
        activeStep: prevState.activeStep + 1
      };
    case "step-two":
      return {
        ...prevState,
        previewURL: action.payload.previewURL,
        componentThumbnailURL: action.payload.componentThumbnailURL,
        elements: action.payload.elements,
        activeStep: prevState.activeStep + 1
      };
    case "update-previously-imported":
      return {
        ...prevState,
        listSavedComponents: action.payload.listSavedComponents
      };
    case "use-saved-component":
      return {
        ...prevState,
        savedComponentId: action.payload
      };
    case "reset":
      return {
        ...action.payload
      };
    case "error":
      return {
        ...prevState,
        error: action.error
      };
    default:
      return prevState;
  }
};
