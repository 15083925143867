import { AdElementImage } from "@adflow/types";
import { FC } from "react";
import { HeadGearColour } from "./types";

interface Props {
  position: AdElementImage["position"];
  size: AdElementImage["size"];
  colour: HeadGearColour;
  rotate?: boolean;
}

const FootballHelmet: FC<Props> = ({ position, size, colour, rotate }) => {
  return (
    <div
      style={{
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        overflow: "hidden",
        top: position.y,
        left: position.x,
        transform: rotate ? "rotateY(180deg)" : "none",
        zIndex: position.z
      }}
    >
      {/* Body */}
      <div
        style={{
          alignSelf: "baseline",
          justifyContent: "center",
          display: "flex",
          ...size
        }}
      >
        <svg
          height='90%'
          width='90%'
          viewBox='0 0 600 600'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_701_3)'>
            <path
              d='M518.88 170.196C414.45 36.8905 174.51 76.7166 121.01 235.692C331.98 280.825 159.18 370.554 232.47 463.415C248.98 479.677 280.57 510.833 319.96 512.18C365.05 511.611 384.76 489.434 421.84 482.43C453.54 474.768 468.25 485.313 487.92 481.273C582.56 440.669 588.41 248.532 518.88 170.196V170.196Z'
              fill={colour.background}
            />
            <path
              d='M566.24 322.647C563.35 369.666 557.54 413.034 523.68 455.234C503.53 479.038 488.27 486.59 457.04 480.066C456.08 474.668 455.97 470.887 461.31 464.722C464.27 461.669 467.65 458.596 471.46 456.87C526.89 430.034 537.96 360.188 536.07 304.709C535.14 86.3439 222.61 53.212 149.76 244.86C139.77 241.797 130.01 238.804 120.26 235.812C208.13 2.74101 581.4 57.3024 566.24 322.647Z'
              fill={colour.accent}
            />
            <path
              opacity='0.1'
              d='M566.24 322.647C564.11 386.786 540.5 454.197 494.32 479.517C482.9 483.857 471.05 481.951 459.2 480.455C437.47 476.075 402.2 486.491 379.08 497.136C441.47 413.253 481.75 191.955 409.01 98.7746C509.58 125.771 571.3 219.879 566.24 322.647V322.647Z'
              fill='black'
            />
            <path
              opacity='0.1'
              d='M332.25 86.7928C323.41 86.9525 294.18 88.0399 258.02 100.6C249.47 103.573 225.91 111.914 202.26 127.836C153.46 160.699 130.19 208.386 120.25 233.397C159.77 240.939 215.09 257.002 228.69 300C237.38 240.471 281 121.162 340.92 86.7928C338.04 86.7529 335.15 86.733 332.24 86.7928H332.25Z'
              fill='white'
            />
            <g filter='url(#filter0_i_701_3)'>
              <path
                d='M232.952 327.422C233.582 318.088 232.403 309.013 229.895 301.255C224.121 331.411 222.432 353.191 222.103 358.197C227.128 340.715 232.603 328.21 232.952 327.422Z'
                fill='white'
                fillOpacity='0.03'
              />
              <path
                d='M519.237 169.572C414.907 36.3225 175.198 76.1317 121.749 235.039C332.517 280.154 159.883 369.844 233.102 462.666C249.596 478.921 281.156 510.064 320.508 511.41C365.555 510.842 385.246 488.673 422.29 481.673C453.96 474.014 468.656 484.555 488.307 480.516C582.856 439.929 588.7 247.874 519.237 169.572V169.572Z'
                fill='white'
                fillOpacity='0.03'
              />
              <path
                d='M566.552 321.957C563.664 368.957 557.86 412.306 524.032 454.489C503.902 478.282 488.656 485.831 457.456 479.309C456.497 473.914 456.387 470.135 461.722 463.972C464.679 460.921 468.056 457.849 471.863 456.124C527.239 429.299 538.299 359.483 536.411 304.027C535.481 85.7549 223.252 52.6371 150.472 244.204C140.491 241.142 130.741 238.151 121 235.159C208.786 2.18757 581.697 56.7257 566.552 321.957Z'
                fill='white'
                fillOpacity='0.03'
              />
            </g>
            <path
              d='M319.49 385.209L272.67 283.629C271.66 281.434 269.8 279.728 267.51 278.92C233.98 266.998 155.21 239.074 120.93 226.883C116.25 225.227 111.1 227.661 109.43 232.33C107.76 236.999 110.21 242.137 114.89 243.803L198.31 273.413L176.69 365.685L50.33 345.972C45.42 345.204 40.82 348.556 40.05 353.454C38.7 362.224 47.7 364.319 54.49 364.797L52.64 401.391L44.38 400.104C32.87 398.129 30.03 416.206 41.59 417.842L51.72 419.429L48.11 490.82C47.71 496.148 52.71 500.877 58.01 500.208L159.83 489.823C163.65 489.434 166.8 486.67 167.68 482.929L177.93 439.172C181.79 438.983 235.51 450.376 237.3 446.216L316.08 396.533C319.89 394.128 321.33 389.28 319.45 385.189L319.49 385.209ZM215.38 279.479L257.9 294.573L274.71 331.037C268.54 330.209 256.7 324.542 252.53 332.055L225.24 373.277L194.54 368.489L215.39 279.489L215.38 279.479ZM72.38 367.591L172.58 383.224L164.23 418.86L70.53 404.195L72.38 367.591V367.591ZM151.64 472.623L66.64 481.293L69.62 422.242L160.13 436.409L151.64 472.633V472.623ZM230.58 429.246L182.07 421.653L190.42 386.007C250.75 395.555 226.75 397.879 264.13 347.089L284.14 351.499L299.84 385.568L230.58 429.246V429.246Z'
              fill='#2F2F2F'
              stroke={colour.background}
              strokeWidth='2'
              strokeMiterlimit='10'
            />
            <g filter='url(#filter1_f_701_3)'>
              <ellipse
                cx='367.419'
                cy='212.825'
                rx='94'
                ry='73.5'
                transform='rotate(10.0855 367.419 212.825)'
                fill='white'
                fillOpacity='0.43'
              />
            </g>
          </g>
          <defs>
            <filter
              id='filter0_i_701_3'
              x='121'
              y='88'
              width='446'
              height='423.41'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='BackgroundImageFix'
                result='shape'
              />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset />
              <feGaussianBlur stdDeviation='18.5' />
              <feComposite
                in2='hardAlpha'
                operator='arithmetic'
                k2='-1'
                k3='1'
              />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.47 0'
              />
              <feBlend
                mode='normal'
                in2='shape'
                result='effect1_innerShadow_701_3'
              />
            </filter>
            <filter
              id='filter1_f_701_3'
              x='173.969'
              y='38.5961'
              width='386.899'
              height='348.458'
              filterUnits='userSpaceOnUse'
              colorInterpolationFilters='sRGB'
            >
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='BackgroundImageFix'
                result='shape'
              />
              <feGaussianBlur
                stdDeviation='50'
                result='effect1_foregroundBlur_701_3'
              />
            </filter>
            <clipPath id='clip0_701_3'>
              <rect
                width='532.17'
                height='424.18'
                fill='white'
                transform='translate(34 88)'
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

FootballHelmet.displayName = "FootballHelmet";

export default FootballHelmet;
