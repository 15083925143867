import { SvgConfig, Hex } from "./types";

import {
  Base as BaseShortSleeve,
  SleeveLong as SleeveLongShortSleeve,
  Sleeve as SleeveShortSleeve,
  StripesVertical as StripesVerticalShortSleeve,
  StripesHorizontal as StripesHorizontalShortSleeve,
  Squares as SquaresShortSleeve,
  Split as SplitShortSleeve
} from "./shortSleeves/men/svgParts";

import {
  Base as BaseNoSleeve,
  Sleeve as SleeveNoSleeve,
  StripesVertical as StripesVerticalNoSleeve,
  StripesHorizontal as StripesHorizontalNoSleeve,
  Squares as SquaresNoSleeve,
  Split as SplitNoSleeve
} from "./noSleeves/men/svgParts";

import {
  Base as BaseLongSleeve,
  SleeveLong as SleeveLongLongSleeve,
  Sleeve as SleeveLongSleeve,
  StripesVertical as StripesVerticalLongSleeve,
  StripesHorizontal as StripesHorizontalLongSleeve,
  Squares as SquaresLongSleeve
} from "./longSleeves/men/svgParts";

export default function getConfigMen(fill: Hex): SvgConfig {
  return {
    no_sleeves: {
      base: <BaseNoSleeve fill={fill} />,
      horizontalstripes: <StripesHorizontalNoSleeve fill={fill} />,
      sleeve: <SleeveNoSleeve fill={fill} />,
      squares: <SquaresNoSleeve fill={fill} />,
      stripes: <StripesVerticalNoSleeve fill={fill} />,
      split: <SplitNoSleeve fill={fill} />
    },
    short_sleeves: {
      base: <BaseShortSleeve fill={fill} />,
      horizontalstripes: <StripesHorizontalShortSleeve fill={fill} />,
      sleeve: <SleeveShortSleeve fill={fill} />,
      sleevelong: <SleeveLongShortSleeve fill={fill} />,
      squares: <SquaresShortSleeve fill={fill} />,
      stripes: <StripesVerticalShortSleeve fill={fill} />,
      split: <SplitShortSleeve fill={fill} />
    },
    long_sleeves: {
      base: <BaseLongSleeve fill={fill} />,
      sleeve: <SleeveLongSleeve fill={fill} />,
      sleevelong: <SleeveLongLongSleeve fill={fill} />,
      stripes: <StripesVerticalLongSleeve fill={fill} />,
      horizontalstripes: <StripesHorizontalLongSleeve fill={fill} />,
      squares: <SquaresLongSleeve fill={fill} />
    }
  };
}
