import { FC, PropsWithChildren, useCallback, useState } from "react";

import { useAPIClient } from "../../context/apiClient";
import {
  useComponentImportDispatch,
  useComponentImportState
} from "../../context/componentImport";
import { AdElement, AdElementSVG, Nullable } from "@adflow/types";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  Image,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  StackDivider,
  VStack
} from "@chakra-ui/react";

import { StepProps } from "./";
import { useSearchParam } from "react-use";

export type StepTwoSubmit = (
  componentId: Nullable<string>,
  componentSVG: Nullable<string>,
  componentSVGURL: Nullable<string>,
  componentElements: Nullable<Array<AdElement>>,
  error: Nullable<Error>
) => void;

const ComponentImportStepTwo: FC<PropsWithChildren<StepProps>> = ({
  children,
  onClose
}) => {
  const client = useAPIClient();
  const [componentId, setComponentId] = useState<string>("");
  const useProcessSVG = useSearchParam("useProcessSVG");
  const [loading, setLoading] = useState(false);
  const state = useComponentImportState();
  const dispatch = useComponentImportDispatch();
  const [error, setError] = useState("");

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    async evt => {
      evt.preventDefault();
      if (!state.fileId || !componentId) {
        return;
      }
      try {
        setLoading(true);
        setError("");
        const selectedSavedComponent = state.listSavedComponents.find(
          comp => comp.id === componentId
        );

        const _figmaComponentId =
          selectedSavedComponent?.figmaId ?? componentId;

        if (selectedSavedComponent) {
          if (
            !componentId ||
            !selectedSavedComponent.svgElement ||
            selectedSavedComponent.svgUrl === null
          ) {
            throw new Error("Unable to rerieve saved component");
          }

          const getKeys = Object.keys as <T extends object>(
            obj: T
          ) => Array<keyof T>;

          const elements: AdElement[] = [];

          getKeys(selectedSavedComponent).forEach(
            (key: keyof typeof selectedSavedComponent) => {
              const value = selectedSavedComponent[key];
              if (Array.isArray(value)) {
                elements.push(...value);
              }
            }
          );

          dispatch({
            type: "step-two",
            payload: {
              previewURL: selectedSavedComponent.svgUrl,
              componentThumbnailURL: selectedSavedComponent.thumbnail ?? "",
              elements: [selectedSavedComponent.svgElement, ...elements]
            }
          });
          dispatch({
            type: "use-saved-component",
            payload: selectedSavedComponent.id
          });
          return;
        }

        // retrieve new component from figma
        const { componentSVG, componentSVGURL, componentThumbnailURL } =
          await client.getComponentImage({
            fileId: state.fileId,
            componentId,
            useProcessSVG: useProcessSVG === "true"
          });

        const { root, componentAdElements } =
          await client.getExportableComponentAdElements({
            fileId: state.fileId,
            componentId: _figmaComponentId
          });

        if (
          !root ||
          !componentSVG ||
          !componentAdElements ||
          componentSVGURL === null
        ) {
          throw new Error("Unable to rerieve component from figma");
        }

        const svgElement: AdElementSVG = {
          ...root,
          data: {
            svg: componentSVG
          }
        };

        dispatch({
          type: "step-two",
          payload: {
            previewURL: componentSVGURL,
            componentThumbnailURL,
            elements: [svgElement, ...componentAdElements]
          }
        });
      } catch {
        dispatch({
          type: "error",
          error: "An unexpected error occurred"
        });
      } finally {
        setLoading(false);
      }
    },
    [
      state.fileId,
      componentId,
      dispatch,
      state.listSavedComponents,
      client,
      useProcessSVG
    ]
  );
  return (
    <>
      <ModalBody>
        <Box borderWidth={1} borderRadius={"md"} mb={5}>
          {state.thumbnailURL && (
            <Image
              h={300}
              m='auto'
              objectFit={"contain"}
              src={state.thumbnailURL}
              data-testid='stepTwoThumbnail'
              fallback={<Skeleton height={300} width={600} m='auto' />}
            ></Image>
          )}
        </Box>
        <Heading as='h1' size='sm' noOfLines={1} mb={2}>
          Components
        </Heading>
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={4}
          pl={3}
          align='stretch'
        >
          <RadioGroup
            onChange={value => setComponentId(value)}
            value={componentId}
          >
            <Stack data-testid='componentsList'>
              {state.components?.map(comp => {
                return (
                  <Radio
                    key={comp.id}
                    size='md'
                    name={comp.name}
                    value={comp.id}
                  >
                    {comp.name}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        </VStack>
        {state.listSavedComponents?.length > 0 && (
          <>
            <Heading as='h1' size='sm' noOfLines={1} mb={2} mt={6}>
              Previously Imported
            </Heading>
            <VStack
              divider={<StackDivider borderColor='gray.200' />}
              spacing={4}
              pl={3}
              align='stretch'
            >
              <RadioGroup
                onChange={value => setComponentId(value)}
                value={componentId}
              >
                <Stack>
                  {state.listSavedComponents?.map(comp => (
                    <Radio
                      key={comp.id}
                      size='md'
                      name={comp.name}
                      value={comp.id}
                    >
                      {comp.name}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </VStack>
            <Box pt={2}>{children}</Box>
          </>
        )}
        {error !== "" && (
          <Alert status='error'>
            <AlertIcon />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          data-testid='componentsCloseButton'
          variant='ghost'
          mr={3}
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          colorScheme='blue'
          onClick={handleSubmit}
          isLoading={loading}
          data-testid='importComponent'
        >
          Import Component
        </Button>
      </ModalFooter>
    </>
  );
};

ComponentImportStepTwo.displayName = "ComponentImportStepTwo";
export default ComponentImportStepTwo;
