export interface FontFiles {
  variant: string;
  file: File;
}
type ValidationErrors = {
  indexPosition: Set<number>;
  message: Set<string>;
};

const MAX_TOTAL_SIZE_MB = 1;
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

function validateFontFiles(
  fontFiles: FontFiles[],
  validationErrors: ValidationErrors
) {
  const specialCharPattern = /[^a-zA-Z0-9 ]/;
  const fontVariantSet = new Set<string>();
  let totalSize = 0;

  fontFiles.forEach((file, index) => {
    totalSize += file.file.size;

    if (!file.variant) {
      validationErrors.indexPosition.add(index);
      validationErrors.message.add("Variant name is required");
      return;
    }
    if (specialCharPattern.test(file.variant)) {
      validationErrors.indexPosition.add(index);
      validationErrors.message.add(
        "Font variant must not contain any special characters"
      );
      return;
    }
    if (fontVariantSet.has(file.variant)) {
      validationErrors.indexPosition.add(index);
      validationErrors.message.add("Variant names must be unique");
    } else {
      fontVariantSet.add(file.variant);
    }
  });

  if (fontFiles.length === 0) {
    validationErrors.message.add("At least one font file is required");
  }

  if (fontFiles.length > 10) {
    validationErrors.message.add("Maximum of 10 font files are allowed");
  }

  if (totalSize >= MAX_TOTAL_SIZE_BYTES) {
    validationErrors.message.add(
      "Total size of font files must be less than 1MB"
    );
  }
}

function validateFontFamily(
  fontFamily: string,
  validationErrors: ValidationErrors
): boolean {
  const specialCharPattern = /[^a-zA-Z0-9 ]/;
  let isFontFamilyValid = true;

  if (fontFamily === "") {
    validationErrors.message.add("Font Family is required");
    isFontFamilyValid = false;
  }
  if (specialCharPattern.test(fontFamily)) {
    validationErrors.message.add(
      "Font Family must not contain any special characters"
    );
    isFontFamilyValid = false;
  }

  return isFontFamilyValid;
}

export function validateFontUpload(fontFiles: FontFiles[], fontFamily: string) {
  const validationErrors: ValidationErrors = {
    indexPosition: new Set<number>(),
    message: new Set<string>()
  };

  validateFontFiles(fontFiles, validationErrors);
  const isFontFamilyValid = validateFontFamily(fontFamily, validationErrors);
  const isValid =
    validationErrors.indexPosition.size === 0 &&
    isFontFamilyValid &&
    !validationErrors.message.has(
      "Total size of font files must be less than 1MB"
    );

  return {
    isValid,
    isFontFamilyValid,
    indexPosition: validationErrors.indexPosition,
    message: validationErrors.message
  };
}
