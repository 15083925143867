import { FC, useCallback } from "react";

import { useEditorStore } from "@adflow/editor";
import { EditorSidebarButtonProps } from "@adflow/types";
import { IconButton, Image, useDisclosure } from "@chakra-ui/react";

import SelectShadowBrand, {
  type SelectShadowBrandProps
} from "../SelectShadowBrand";
import fresh8IconUrl from "./fresh8-icon.svg";

// In the future we will move this modal somewhere else
// It's a little bit weird having the modal open up in this button
const MenuButton: FC<EditorSidebarButtonProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const s = useEditorStore(state => ({
    brand: state.brand,
    addBrand: state.addBrand,
    setCustomFonts: state.setCustomFonts
  }));
  const handleSubmit = useCallback<SelectShadowBrandProps["onSubmit"]>(
    (brand, fonts) => {
      s.addBrand(brand);
      s.setCustomFonts(fonts);
      onClose();
    },
    [onClose, s]
  );

  return (
    <>
      <IconButton
        size='lg'
        px={3}
        aria-label='Select Shadow Brand'
        icon={<Image src={fresh8IconUrl} />}
        borderRadius='100%'
        aspectRatio='1/1'
        onClick={onOpen}
        data-testid='shadowBrandButton'
      />
      {/* Note: un-mount this stateful component when closed. By default
          this won't be removed from the component tree */}
      {isOpen && (
        <SelectShadowBrand
          brand={s.brand}
          isOpen={isOpen}
          handleClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};
MenuButton.displayName = "FigmaMenuButton";

export default MenuButton;
