import { EditorStoreContext, useEditorStore } from "@adflow/editor";
import {
  Alert,
  AlertIcon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { FC, useCallback, useContext, useReducer } from "react";
import {
  ComponentImportContextProvider,
  initialState,
  stateReducer
} from "../../context/componentImport";
import ComponentImportStepOne from "./StepOne";
import ComponentImportStepThree, { StepThreeSubmit } from "./StepThree";
import ComponentImportStepTwo from "./StepTwo";

export interface StepProps {
  onClose: () => void;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FigmaComponentImportModal: FC<Props> = ({ isOpen, onClose }) => {
  const ctx = useContext(EditorStoreContext);
  const addElements = useEditorStore(state => state.addElements);
  const addElement = useEditorStore(state => state.addElement);
  const removeNodesFromSVG = useEditorStore(state => state.removeNodesFromSVG);
  const generateUniqueIDs = useEditorStore(state => state.generateUniqueIDs);
  const getImageLinkForImageElementNameFromSVG = useEditorStore(
    state => state.getImageLinkForImageElementNameFromSVG
  );
  const s = useEditorStore(state => ({
    elements: state.elements,
    dataSources: state.dataSources,
    resetElementData: state.resetElementData,
    upsertDataSources: state.upsertDataSources
  }));
  const setThumbnailUrl = useEditorStore(state => state.setThumbnailURL);
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const handleClose = useCallback(async () => {
    await dispatch({ type: "reset", payload: initialState });
    onClose();
  }, [onClose]);

  const handleSubmit: StepThreeSubmit = useCallback(
    (elementsToImport, thumbnailURL, dataSourceTypes) => {
      // We only import 1 svg from Figma. If we were to import
      // more this would need to work differently.
      const svgElement = elementsToImport.find(el => el.type === "SVG");
      const allOtherElements = elementsToImport.filter(el => el.type !== "SVG");
      const nodeIdsToRemoveFromSVG = allOtherElements.map(el => el.name);
      // Ensure that no elements exist before importing new elements
      // Weird things happen if we don't do this.
      s.resetElementData();
      if (svgElement) {
        addElement(svgElement);
      }
      setThumbnailUrl(thumbnailURL);
      addElements(
        elementsToImport.map(el => {
          if (el.type === "IMAGE" && svgElement) {
            const imageLink = getImageLinkForImageElementNameFromSVG(
              svgElement.id,
              el.name
            );
            el.data.content = imageLink;
            el.data.initialContent = imageLink;
          }
          return el;
        })
      );

      if (svgElement) {
        removeNodesFromSVG(svgElement.id, nodeIdsToRemoveFromSVG);
        // Fix svg id collision by adding unique prefix to each id
        // and update their references.
        generateUniqueIDs(svgElement.id);
      }

      if (!dataSourceTypes?.length) {
        handleClose();
        return;
      }
      s.upsertDataSources(dataSourceTypes, ctx.dataSources);

      handleClose();
    },
    [
      s,
      setThumbnailUrl,
      addElements,
      ctx.dataSources,
      handleClose,
      addElement,
      getImageLinkForImageElementNameFromSVG,
      removeNodesFromSVG,
      generateUniqueIDs
    ]
  );

  const ErrorAlert = () =>
    state.error == null || state.error == "" ? null : (
      <Alert status='error'>
        <AlertIcon />
        {state.error}
      </Alert>
    );

  return (
    <ComponentImportContextProvider state={state} dispatch={dispatch}>
      <Modal isOpen={isOpen} onClose={handleClose} size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader data-testid='importFromFigmaDialogTitle'>
            Import from Figma
          </ModalHeader>
          <ModalCloseButton />
          {state.activeStep === 1 && (
            <ComponentImportStepOne onClose={handleClose}>
              <ErrorAlert />
            </ComponentImportStepOne>
          )}
          {state.activeStep === 2 && (
            <ComponentImportStepTwo onClose={handleClose}>
              <ErrorAlert />
            </ComponentImportStepTwo>
          )}
          {state.activeStep === 3 && (
            <ComponentImportStepThree
              onClose={handleClose}
              onSubmit={handleSubmit}
            >
              <ErrorAlert />
            </ComponentImportStepThree>
          )}
        </ModalContent>
      </Modal>
    </ComponentImportContextProvider>
  );
};

FigmaComponentImportModal.displayName = "FigmaComponentImportModal";

export default FigmaComponentImportModal;
