import { useContext, useEffect, useState } from "react";
import UserContext from "../../UserProvider/contexts/UserContext";
import { canCreate } from "../../Templates/utils/permissions";
import { IMarketingCloudUser, MarketingCloudUser } from "@ads/iam-library";

export default function usePermissionToUpload() {
  const userContext = useContext(UserContext);

  const [hasCreatePermissions, setHasCreatePermissions] = useState(false);
  const [isUploadToDefault, setIsUploadToDefault] = useState(false);

  useEffect(() => {
    const checkPermissions = async () => {
      if (!userContext.user) {
        setHasCreatePermissions(false);
        setIsUploadToDefault(false);
        return;
      }

      const marketingCloudUser = new MarketingCloudUser(userContext.user);

      const isAdmin = marketingCloudUser.isAdmin();
      const hasCreateRight = await marketingCloudUser.canCreate(
        "creative-hub",
        ""
      );
      const holdingIsSet = marketingCloudUser.getHoldingIds()?.length > 0;

      setHasCreatePermissions(isAdmin || (hasCreateRight && holdingIsSet));
      setIsUploadToDefault(isAdmin);
    };

    checkPermissions();
  }, [userContext.user]);

  return { hasCreatePermissions, isUploadToDefault };
}
