import { AdElementText } from "@adflow/types";
import { FormLabel, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useStore } from "../../../../hooks/useStore";

type Props = {
  textElement: AdElementText;
};

export const isMonthPresent = (textElement: AdElementText) => {
  if (!textElement.sources[0]) return false;
  return textElement.data.content.includes(
    `${textElement.sources[0].sourceId}:MONTH}`
  );
};

const MonthFormatControls: FC<Props> = ({ textElement }) => {
  const s = useStore(state => ({
    displayOptions: state.displayOptions,
    setDisplayOptions: state.setDisplayOptions
  }));

  const handleUpdateMonthFormat = useCallback(
    (value: string) => {
      let monthFormat = s.displayOptions.dateTimeFormat?.monthFormat;
      if (monthFormat == null) {
        monthFormat = "long";
      }

      if (value !== "short" && value !== "long") return;
      monthFormat = value;
      s.setDisplayOptions("dateTimeFormat", {
        ...s.displayOptions.dateTimeFormat,
        monthFormat: monthFormat
      });
      console.log(s.displayOptions.dateTimeFormat?.monthFormat);
    },
    [s]
  );

  return (
    <>
      <Stack direction='row' mb={2}>
        <FormLabel
          htmlFor='time-format'
          mb={"0"}
          style={{ fontVariant: "all-small-caps" }}
        >
          Month
        </FormLabel>
        <RadioGroup
          value={s.displayOptions.dateTimeFormat?.monthFormat || "long"}
          id='time-format'
          ml={"auto"}
          mr={0}
          onChange={handleUpdateMonthFormat}
        >
          <Stack direction='row'>
            <Radio value='short'>Abbreviated</Radio>
            <Radio value='long'>Full</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </>
  );
};

MonthFormatControls.displayName = "MonthFormatControls";

export default MonthFormatControls;
