import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavigation from "../SideNavigation/main.js";

import "./index.css";
import { useEffect, useMemo } from "react";

export function Root() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("templates");
    }
  }, [location]);

  const theme = useMemo(() => {
    return extendTheme({
      fonts: {
        heading: "Source Sans Pro",
        body: "Source Sans Pro"
      }
    });
  }, []);

  return (
    <div id='creative-hub-container'>
      <ChakraProvider theme={theme}>
        <SideNavigation />
        <div id='main'>
          <Outlet />
        </div>
      </ChakraProvider>
    </div>
  );
}
