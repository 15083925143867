import { AdElementText } from "@adflow/types";
import { FormLabel, Input, Switch, HStack, Heading } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useStore } from "../../../../hooks/useStore";

type Props = {
  textElement: AdElementText;
};

export const isCountdownPresent = (textElement: AdElementText) => {
  if (!textElement.sources[0]) return false;
  return textElement.data.content.includes(
    `${textElement.sources[0].sourceId}:COUNTDOWN}`
  );
};

const CountdownFormatControls: FC<Props> = () => {
  const s = useStore(state => ({
    displayOptions: state.displayOptions,
    setDisplayOptions: state.setDisplayOptions
  }));

  const handleUpdateDaysSeparator = useCallback(
    (value: string) => {
      let showDays =
        s.displayOptions.dateTimeFormat?.countdownDaysSeparator || "d";

      if (!value && value) return;
      showDays = value;
      s.setDisplayOptions("dateTimeFormat", {
        ...s.displayOptions.dateTimeFormat,
        countdownDaysSeparator: showDays
      });
    },
    [s]
  );

  const handleUpdateShowDays = useCallback(
    (isEnabled: boolean) => {
      s.setDisplayOptions("dateTimeFormat", {
        ...s.displayOptions.dateTimeFormat,
        countdownShowDays: isEnabled
      });
    },
    [s]
  );

  return (
    <>
      <Heading fontSize='lg' fontWeight='semibold' mt='2' noOfLines={1}>
        Countdown Format
      </Heading>
      <HStack spacing='48px' mt={"2"}>
        <HStack mb={"0"} direction='row' alignItems='center'>
          <FormLabel fontWeight={"semibold"} mb={"0"}>
            Show days?
          </FormLabel>
          <Switch
            isChecked={
              s.displayOptions.dateTimeFormat?.countdownShowDays || false
            }
            onChange={evt => handleUpdateShowDays(evt.target.checked)}
          />
        </HStack>
        <HStack spacing='0' direction='row' alignItems='center'>
          <FormLabel fontWeight={"semibold"} mb={"0"}>
            Separator
          </FormLabel>

          <Input
            value={
              s.displayOptions.dateTimeFormat?.countdownDaysSeparator || "days"
            }
            id='countdown-days-separator'
            onChange={e => handleUpdateDaysSeparator(e.target.value)}
            placeholder='days'
          />
        </HStack>
      </HStack>
    </>
  );
};

CountdownFormatControls.displayName = "TimeFormatControls";

export default CountdownFormatControls;
