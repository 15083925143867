import { AdElementVectorColor } from "@adflow/types";
import { Divider, Heading, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { FC, useCallback, useMemo } from "react";
import { useStore } from "../../../hooks/useStore";

type Props = {
  vectorColor: AdElementVectorColor;
  onChange: (color: AdElementVectorColor) => void;
};

const VectorElementColorControl: FC<Props> = ({ vectorColor, onChange }) => {
  const defaultSource = "OFF";
  const defaultType = "PRIMARY";

  const dataSources = useStore(state => ({
    dataSources: state.dataSources
  }));
  const competitorfocused = useMemo(() => {
    return dataSources.dataSources.some(
      ds => ds.type === "EVENT_COMPETITOR_FOCUSED"
    );
  }, [dataSources]);
  const handleSourceChange = useCallback(
    (value: string) => {
      let type = vectorColor.type;
      if (type === null) {
        type = defaultType;
      }

      let source: string | null = value;
      if (
        source !== "HOME_COLOR" &&
        source !== "AWAY_COLOR" &&
        source !== "COMPETITOR_COLOR" &&
        source !== "OPPONENT_COLOR" &&
        source !== "OFF"
      )
        return;
      if (source === "OFF") {
        source = null;
        type = null;
      }
      onChange({
        ...vectorColor,
        source,
        type
      });
    },
    [vectorColor, onChange]
  );
  const handleTypeChange = useCallback(
    (type: string) => {
      if (type !== "PRIMARY" && type !== "SECONDARY") return;
      onChange({
        ...vectorColor,
        type
      });
    },
    [vectorColor, onChange]
  );

  const typeChangeDisabled = useMemo(() => {
    return vectorColor.source ? false : true;
  }, [vectorColor]);
  return (
    <>
      <Heading fontSize='lg' fontWeight='semibold' mb='2'>
        Team Colors
      </Heading>
      <RadioGroup
        value={vectorColor.source || defaultSource}
        onChange={handleSourceChange}
        mb={3}
      >
        <Stack direction='column'>
          <Radio value='OFF'>Figma Colors</Radio>
          <Radio value='HOME_COLOR'>Home Team Colors</Radio>
          <Radio value='AWAY_COLOR'>Away Team Colors</Radio>
          {competitorfocused && (
            <Radio value='COMPETITOR_COLOR'>Competitor Colors</Radio>
          )}
          {competitorfocused && (
            <Radio value='OPPONENT_COLOR'>Opponent Colors</Radio>
          )}
        </Stack>
      </RadioGroup>
      <Divider orientation='vertical' />
      <Heading fontSize='md' fontWeight='semibold' mt='3' mb={2}>
        Type
      </Heading>
      <RadioGroup
        value={vectorColor.type || defaultType}
        onChange={handleTypeChange}
      >
        <Stack direction='column'>
          <Radio isDisabled={typeChangeDisabled} value='PRIMARY'>
            Primary
          </Radio>
          <Radio isDisabled={typeChangeDisabled} value='SECONDARY'>
            Secondary
          </Radio>
        </Stack>
      </RadioGroup>
    </>
  );
};

VectorElementColorControl.displayName = "TeamColorControls";

export default VectorElementColorControl;
