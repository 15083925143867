// src/index.ts
import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
var theme = createTheme({
  typography: {
    h1: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h2: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.5
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.15px"
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5
    },
    fontFamily: "Source Sans Pro",
    button: {
      textTransform: "none",
      fontWeight: 800
    }
  },
  palette: {
    primary: {
      main: "#556cd6"
    },
    secondary: {
      main: "#E4F3FF",
      contrastText: "#3367D6"
    },
    error: {
      main: red.A400
    },
    mcRed: {
      main: "#FF0000",
      light: "#FF4D4D",
      lighter: "#FF9999",
      dark: "#C1022C",
      darker: "#890000",
      // These don't exist in the design system
      contrastText: "#FFFFFF"
    },
    mcNavy: {
      main: "#00003C",
      light: "#4D4D77",
      lighter: "#9999B1",
      // These don't exist in the design system
      dark: "#00003C",
      contrastText: "#FFFFFF"
    },
    mcBlue: {
      main: "#3367D6",
      light: "#7095E2",
      lighter: "#ADC2EF",
      dark: "#2750A8",
      darker: "#19346E",
      // These don't exist in the design system
      contrastText: "#FFFFFF"
    },
    mcGreen: {
      main: "#149436",
      light: "#5BB472",
      lighter: "#A1D4AF",
      dark: "#0F6B27",
      darker: "#084919",
      // These don't exist in the design system
      contrastText: "#149436"
    },
    mcOrange: {
      main: "#F49B12",
      // These don't exist in the design system
      light: "#F49B12",
      lighter: "#F49B12",
      dark: "#F49B12",
      darker: "#F49B12",
      contrastText: "#F49B12"
    },
    mcYellow: {
      main: "#E7E738",
      // These don't exist in the design system
      light: "#E7E738",
      lighter: "#E7E738",
      dark: "#E7E738",
      darker: "#E7E738",
      contrastText: "#E7E738"
    },
    mcGrey: {
      main: "#E3E3E3",
      light: "#F3F3F3",
      lighter: "#E3E3E3",
      dark: "#9F9F9F",
      darker: "#404040",
      // These don't exist in the design system
      contrastText: "#E3E3E3"
    }
  }
});
var src_default = theme;
export {
  src_default as default
};
