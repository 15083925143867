import { FC, forwardRef, useCallback, useMemo } from "react";

import { FaShapes } from "react-icons/fa";
import { GiAmericanFootballHelmet } from "react-icons/gi";
import {
  LuCalendarClock,
  LuDribbble,
  LuImage,
  LuPercent,
  LuTarget,
  LuTimer,
  LuType,
  LuXCircle
} from "react-icons/lu";
import { PiBoundingBox } from "react-icons/pi";
import { shallow } from "zustand/shallow";

import { FaTrashAlt } from "react-icons/fa";

import {
  Box,
  Divider,
  Flex,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Tag,
  Text
} from "@chakra-ui/react";

import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import { AdElement, Datasources as DataSources } from "@adflow/types";
import { IconType } from "react-icons";
import { useStore } from "../../hooks/useStore";
import DeleteConfirmation from "../DeleteConfirmation";
import Typography from "../ThemedTypography";
import ElementItem from "./ElementItem";

type Props = object;

const LeftSidebar: FC<Props> = () => {
  const s = useStore(
    state => ({
      elements: state.elements,
      dataSources: state.dataSources,
      brand: state.brand,
      removeDataSource: state.removeDataSource,
      removeElementDataSource: state.removeElementDataSource
    }),
    shallow
  );

  const compareZIndex = (
    a: { position: { z: number } },
    b: { position: { z: number } }
  ): number => {
    if (a.position.z < b.position.z) {
      return 1;
    }
    if (a.position.z > b.position.z) {
      return -1;
    }
    return 0;
  };

  const listItems = useMemo(
    () =>
      s.elements.sort(compareZIndex).map(el => ({
        id: el.id,
        type: el.type,
        name: el.name
      })),

    [s.elements]
  );

  const handleDelete = useCallback(
    (dataSourceId: string) => {
      s.removeDataSource(dataSourceId);
      s.elements.forEach(el => {
        s.removeElementDataSource(el.id, dataSourceId);
      });
    },
    [s]
  );
  return (
    <Flex
      borderRightWidth={1}
      direction='column'
      minW='200'
      maxW='15vw'
      height='100%'
    >
      <ElementList listItems={listItems} />
      <DisplayShadowBrand brand={s.brand} />
      <Datasources dataSources={s.dataSources} onDelete={handleDelete} />
    </Flex>
  );
};

export default LeftSidebar;

const DeleteBtn = forwardRef<HTMLButtonElement, { onClick: () => void }>(
  (props, ref) => {
    return (
      <IconButton
        ref={ref}
        onClick={props.onClick}
        variant='ghost'
        size='xs'
        aria-label='Search database'
        data-testid='deleteDataSource'
        icon={<FaTrashAlt />}
      />
    );
  }
);

DeleteBtn.displayName = "DeleteBtn";

// Data Sources
type DataSourcesProps = {
  dataSources: {
    id: string;
    type: DataSources.SourceType;
  }[];
  onDelete: (id: string) => void;
};
// TODO: add appropriate icons to the new markets
const Datasources: FC<DataSourcesProps> = ({ dataSources, onDelete }) => {
  const getIcon = useCallback((type: DataSources.SourceType) => {
    const iconName = DataSources.getIconNameForSourceType(type);
    const iconMap: Record<string, IconType> = {
      LuCalendarClock: LuCalendarClock,
      LuTarget: LuTarget,
      LuXCircle: LuXCircle,
      LuTimer: LuTimer,
      LuPercent: LuPercent,
      LuDribbble: LuDribbble
    };
    return iconMap[iconName] ?? LuXCircle;
  }, []);

  return (
    <Flex
      flex={dataSources.length ? 1 : undefined}
      overflow='auto'
      direction='column'
      data-testid='dataSources'
    >
      <Typography
        muiTypographyProps={{
          variant: "h3",
          sx: {
            py: 1,
            px: 2
          }
        }}
      >
        Data Sources
      </Typography>
      <Divider />
      {dataSources.length > 0 ? (
        <>
          <List overflowY='scroll'>
            {dataSources.map(item => {
              // This won't scale as we add more element types
              const icon = getIcon(item.type);

              const friendlyName =
                DataSources.getFriendlySourceNameFromSourceType(item.type);
              const dataSourceTypeLabel = DataSources.getDataTypeFromSourceType(
                item.type
              );

              return (
                <ListItem
                  px={5}
                  py={2}
                  key={item.id}
                  _hover={{
                    color: "blue.600",
                    bg: "blue.50"
                  }}
                  display='flex'
                  justifyContent='space-between'
                >
                  <Box>
                    <ListIcon as={icon} />
                    {friendlyName}
                    <Tag
                      size={"sm"}
                      fontWeight={"bold"}
                      borderRadius='full'
                      variant='solid'
                      colorScheme='green'
                      m={0.5}
                    >
                      {dataSourceTypeLabel?.toLocaleLowerCase()}
                    </Tag>
                  </Box>
                  <DeleteConfirmation
                    onConfirm={() => {
                      onDelete(item.id);
                    }}
                    confirmationText='Are you sure you want to delete this datasource?'
                    TriggerTarget={DeleteBtn}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      ) : (
        <Text fontSize='xs' px={5} py={3}>
          None
        </Text>
      )}
    </Flex>
  );
};

Datasources.displayName = "Datasources";

type ElementListProps = {
  listItems: {
    id: string;
    type: AdElement["type"];
    name: string;
  }[];
};

const ElementList: FC<ElementListProps> = ({ listItems }) => {
  const s = useStore(state => ({
    setSelectedElement: state.setElementSelected
  }));

  const handleClick = useCallback(() => {
    s.setSelectedElement(null);
  }, [s]);

  return (
    <Flex
      py={3}
      flex={3}
      flexShrink={0}
      overflow='auto'
      direction='column'
      borderBottomWidth='1px'
    >
      <Typography
        muiTypographyProps={{
          variant: "h3",
          sx: {
            pb: 1.5,
            px: 2
          }
        }}
      >
        Elements
      </Typography>
      <Divider />
      {listItems.length > 0 ? (
        <>
          <List overflowY='scroll'>
            {listItems.map(item => {
              // This won't scale as we add more element types
              const icon =
                item.type === "SVG"
                  ? FaShapes
                  : item.type === "IMAGE"
                  ? LuImage
                  : item.type === "TEAM_ASSET"
                  ? GiAmericanFootballHelmet
                  : item.type === "VECTOR"
                  ? PiBoundingBox
                  : LuType;

              return (
                <ElementItem
                  key={item.id}
                  itemId={item.id}
                  name={item.name}
                  elementIcon={icon}
                >
                  <ListIcon as={icon} />
                </ElementItem>
              );
            })}
          </List>
        </>
      ) : (
        <Text fontSize='xs' px={5} py={3}>
          None
        </Text>
      )}
      <Box onClick={handleClick} flexGrow={1} />
    </Flex>
  );
};

ElementList.displayName = "ElementList";

type DisplayShadowBrandProps = {
  brand: ConsoleAPIGraph.Brand | null;
};

const DisplayShadowBrand: FC<DisplayShadowBrandProps> = ({ brand }) => {
  return (
    <Flex
      overflow='auto'
      direction='column'
      borderBottomWidth='1px'
      data-testid='shadowBrandInfo'
    >
      <Typography
        muiTypographyProps={{
          variant: "h3",
          sx: {
            py: 1,
            px: 2
          }
        }}
      >
        Shadow Brand
      </Typography>

      {brand && (
        <>
          <Divider mb={3} />
          <Typography
            muiTypographyProps={{
              variant: "body1",
              sx: {
                py: 1,
                px: 2
              }
            }}
          >
            Name: {brand?.name}
          </Typography>
        </>
      )}
    </Flex>
  );
};

DisplayShadowBrand.displayName = "DisplayShadowBrand";
