import { FC, useCallback, useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";

import { Flex, useDisclosure, useToast } from "@chakra-ui/react";

import { Datasources } from "@adflow/types";
import { useStore } from "../../hooks/useStore";
import { DATA_SOURCE_REMOVED } from "../../store";
import { CancelConfirmation } from "../CancelConfirmation";
import EditableHeading from "../EditableHeading";
import { SaveConfirmation } from "../SaveConfirmation";
import { WarningModal } from "../WarningModal";

import Button from "../../components/ThemedButton";

export interface Props {
  onSave: (name: string) => Promise<void>;
  saveButtonVisible: boolean;
}
const Topbar: FC<Props> = ({ onSave, saveButtonVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isWarningOpen, setWarningOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    name,
    setName,
    bgColour,
    setBgColour,
    brand,
    validateSelectedFonts,
    validateElementContents,
    isLegacyEventTemplate,
    isAnyElementContentInvalid,
    isMarketSelected,
    businessEntityId
  } = useStore(
    state => ({
      name: state.name,
      brand: state.brand,
      setName: state.setName,
      bgColour: state.bgColour,
      setBgColour: state.setBgColour,
      validateSelectedFonts: state.validateSelectedFonts,
      validateElementContents: state.validateElementContents,
      isLegacyEventTemplate: state.dataSources.some(ds => ds.type === "EVENT"),
      isAnyElementContentInvalid: state.elements.some(el =>
        el.type === "TEXT" ? el.data.content === DATA_SOURCE_REMOVED : false
      ),
      isMarketSelected: state.dataSources.some(ds =>
        Datasources.isMarketDataType(ds.type)
      ),
      businessEntityId: state.businessEntityId
    }),
    shallow
  );
  // repass the query received to the next page
  const location = useLocation();
  const query = useMemo(() => {
    return location.search ? `${location.search}` : "";
  }, [location]);

  const handleSaveConfirm = useCallback(async () => {
    if (onSave) {
      setIsLoading(true);
      try {
        await onSave(name);
        navigate(`/${query}`);
      } catch (error) {
        toast({
          title: "Save failed",
          description: "Something went really wrong",
          status: "error",
          duration: 5000,
          isClosable: true
        });
      } finally {
        setIsLoading(false);
        setConfirmationOpen(false);
      }
    }
  }, [onSave, name, toast, navigate]);

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleWarningClose = () => {
    setWarningOpen(false);
    setWarning("");
  };

  const handleSaveConfirmOpen = () => {
    if (isLegacyEventTemplate) {
      setWarningOpen(true);
      setWarning(
        "This template needs to be updated. Please update the Datasources and the content of the elements before saving."
      );
      return;
    }

    if (!isMarketSelected) {
      setWarningOpen(true);
      setWarning("Please select a market before saving.");
      return;
    }
    if (isAnyElementContentInvalid) {
      setWarningOpen(true);
      setWarning(
        "One or more of your elements are invalid. Please update the content before saving."
      );
      return;
    }

    // All new templates created outside testbed must have a business entity selected
    if (
      !businessEntityId &&
      // assume testbed is hosted on adflow-testbed or localhost:3001
      !(
        window.location.href.includes("adflow-testbed") ||
        window.location.href.includes("localhost:3001")
      )
    ) {
      setWarningOpen(true);
      setWarning("Please select an advertiser before saving.");
      return;
    }

    if (name === "New Ad Template" || name === "") {
      setBgColour("red.100");
      setWarningOpen(true);
      setWarning("Please update the template name before saving.");
      return;
    }
    if (!brand) {
      setWarningOpen(true);
      setWarning("Please select a shadow brand before saving.");
      return;
    }
    if (!validateSelectedFonts()) {
      setWarningOpen(true);
      setWarning(
        "One or more of your selected fonts are not available. Please select a different font."
      );
      return;
    }
    if (!validateElementContents()) {
      setWarningOpen(true);
      setWarning(
        "One or more of your elements are invalid. Please update the content strings before saving."
      );
      return;
    }
    setConfirmationOpen(true);
  };
  return (
    <Flex px={2} py={3} borderWidth={1}>
      <EditableHeading
        name={name}
        setName={setName}
        bgColour={bgColour}
        setBgColour={setBgColour}
      />
      <Flex justifyContent='flex-end' flex={1} gap={4}>
        <CancelConfirmation onClose={onClose} onOpen={onOpen} isOpen={isOpen} />
        {saveButtonVisible && (
          <Button
            isLoading={isLoading}
            testId='saveButtonTopbar'
            muiButtonProps={{
              onClick: handleSaveConfirmOpen,
              color: "primary"
            }}
          >
            Save
          </Button>
        )}
        <SaveConfirmation
          isOpen={isConfirmationOpen}
          onClose={handleConfirmationClose}
          onConfirm={handleSaveConfirm}
          isLoading={isLoading}
        />
        <WarningModal
          isOpen={isWarningOpen}
          onClose={handleWarningClose}
          warning={warning}
        />
      </Flex>
    </Flex>
  );
};

Topbar.displayName = "Topbar";
export default Topbar;
