import { AdElementText } from "@adflow/types";
import { ButtonGroup, Heading, IconButton } from "@chakra-ui/react";
import { FC } from "react";
import {
  LuCaseLower,
  LuCaseSensitive,
  LuCaseUpper,
  LuMinus
} from "react-icons/lu";
import { useStore } from "../../../hooks/useStore";
import { shallow } from "zustand/shallow";

type Props = {
  selectedElement: AdElementText;
};

const TextCaseControls: FC<Props> = ({ selectedElement }) => {
  const store = useStore(
    state => ({
      updateElementData: state.updateElementData
    }),
    shallow
  );
  const handleTextCase = (
    textCase: "uppercase" | "lowercase" | "capitalize" | "none"
  ) => {
    if (selectedElement.data.style.textCase === textCase) {
      return;
    }
    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      style: {
        ...selectedElement.data.style,
        textCase: textCase
      }
    });
  };

  return (
    <>
      <Heading fontSize='md' fontWeight='semibold' mt={5}>
        Case
      </Heading>
      <ButtonGroup isAttached size='sm' variant='outline' mt={2}>
        <IconButton
          backgroundColor={
            selectedElement.data.style.textCase === "none" ? "#F0F0F0" : "none"
          }
          aria-label='Clear'
          icon={<LuMinus />}
          onClick={() => handleTextCase("none")}
        />
        <IconButton
          backgroundColor={
            selectedElement.data.style.textCase === "uppercase"
              ? "#F0F0F0"
              : "none"
          }
          aria-label='Upper Case'
          icon={<LuCaseUpper />}
          onClick={() => handleTextCase("uppercase")}
        />
        <IconButton
          backgroundColor={
            selectedElement.data.style.textCase === "lowercase"
              ? "#F0F0F0"
              : "none"
          }
          aria-label='Lower Case'
          icon={<LuCaseLower />}
          onClick={() => handleTextCase("lowercase")}
        />
        <IconButton
          backgroundColor={
            selectedElement.data.style.textCase === "capitalize"
              ? "#F0F0F0"
              : "none"
          }
          aria-label='Capitalize'
          icon={<LuCaseSensitive />}
          onClick={() => handleTextCase("capitalize")}
        />
      </ButtonGroup>
    </>
  );
};

TextCaseControls.displayName = "TextCaseControls";

export default TextCaseControls;
export type { Props as TextCaseControlsProps };
