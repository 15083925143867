import { Flex, Skeleton } from "@chakra-ui/react";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  NavLink as ReactRouterLink,
  useLocation,
  useNavigate
} from "react-router-dom";
import { LazyImage } from "../components/LazyImage";
import { trpc } from "../utils/apiClient";
import MuiButton from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MuiBox from "@mui/material/Box";
import { Link, Typography } from "@mui/material";
import UserContext from "../../UserProvider/contexts/UserContext";
import { canCreate } from "../utils/permissions";
import NavbarUserDropdown from "../../NavUserDropdown/NavUserDropdown";
import { ScopedBusinessEntity } from "@ads/iam-library";

type ListItem = {
  id: string;
  name: string;
  thumbnail: string;
};

export function Index() {
  const userContext = useContext(UserContext);
  const [hasCreatePermissions, setHasCreatePermissions] = useState(false);

  useEffect(() => {
    const checkPermissions = async () => {
      if (!userContext.user) {
        setHasCreatePermissions(false);
        return;
      }
      const result = await canCreate(userContext.user);
      setHasCreatePermissions(result);
    };

    checkPermissions();
  }, [userContext.user]);

  // repass the query received to the next page
  const location = useLocation();
  const query = useMemo(() => {
    return location.search ? `${location.search}` : "";
  }, [location]);

  const scopedBusinessEntity = new ScopedBusinessEntity();
  const selectedBusinessEntityId: string | null = scopedBusinessEntity.getId();

  const adTemplatesQuery = trpc.adTemplate.listThumbnails.useQuery({
    selectedBusinessEntityId: selectedBusinessEntityId || undefined
  });

  if (adTemplatesQuery.error) {
    throw new Error(adTemplatesQuery.error.message);
  }

  const adTemplates = adTemplatesQuery.data?.thumbnails ?? [];
  const isLoading = adTemplatesQuery.status === "loading";

  return (
    <>
      <Flex direction='column' pl='48px' pr='48px' pt='19px' pb='28px'>
        <Flex justifyContent='space-between'>
          <Typography data-testid='templatePageTitle' variant='h1'>
            Templates
          </Typography>
          {hasCreatePermissions && (
            <MuiButton
              component={ReactRouterLink}
              to={`create${query}`}
              variant='contained'
              data-testid='createNewTemplate'
              sx={{ color: "mcBlue" }}
            >
              Create Template
            </MuiButton>
          )}
        </Flex>
        <Divider
          sx={{
            paddingTop: "24px"
          }}
        />
      </Flex>
      <Flex
        gap={6}
        justifyContent='flex-start'
        overflow={"auto"}
        maxHeight={"100%"}
        flexWrap={"wrap"}
        pb={200}
        pl='48px'
        pr='48px'
      >
        {isLoading ? (
          <>
            <Skeleton height={"300px"} width={"300px"} />
            <Skeleton height={"300px"} width={"300px"} />
            <Skeleton height={"300px"} width={"300px"} />
            <Skeleton height={"300px"} width={"300px"} />
          </>
        ) : (
          adTemplates.map(item => (
            <MuiAdTemplateCard key={item.id} item={item}></MuiAdTemplateCard>
          ))
        )}
      </Flex>
    </>
  );
}

const MuiAdTemplateCard: FC<{
  item: ListItem;
}> = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => {
    return location.search ? `${location.search}` : "";
  }, [location]);

  const menuItems = [
    {
      name: "Duplicate",
      templateId: item.id,
      action: () => {
        navigate(`duplicate/${item.id}${query}`, {
          state: {
            thumbnail: item.thumbnail
          }
        });
      }
    }
  ];

  return (
    <MuiBox
      data-testid={`template-${item.name}`}
      height={"fit-content"}
      width={"min-content"}
      sx={{
        borderWidth: 2,
        borderRadius: "4px",
        borderStyle: "solid",
        borderColor: "mcGrey"
      }}
    >
      <Flex justify='space-between'>
        <Typography
          variant={"body2"}
          paddingX={2}
          paddingY={1}
          sx={{
            textDecoration: "none"
          }}
        >
          {item.name}
        </Typography>
        <NavbarUserDropdown testId='templateActions' menuItems={menuItems} />
      </Flex>
      <Divider />
      <Link
        component={ReactRouterLink}
        to={`edit/${item.id}${query}`}
        data-testid='editTemplate'
        variant='body2'
        underline='none'
        color='#1F1F1F'
      >
        <MuiBox width={"300px"} height={"300px"} p={2}>
          <LazyImage
            src={item.thumbnail}
            alt={item.name}
            boxSize='268px'
            objectFit='contain'
            borderRadius={"3px"}
          />
        </MuiBox>
      </Link>
    </MuiBox>
  );
};

export default Index;
