import { useState, type FC, type PropsWithChildren, useMemo } from "react";
import UserContext from "./contexts/UserContext";
import UserControl from "./components/UserControl";
import env from "./utils/env";
import { IMarketingCloudUser } from "@ads/iam-library";

const UserProvider: FC<
  PropsWithChildren<{ user: IMarketingCloudUser | null }>
> = ({ user, children }) => {
  const [userState, setUser] = useState<IMarketingCloudUser | null>(user);
  const value = useMemo(() => ({ user: userState, setUser }), [userState]);
  return (
    <UserContext.Provider value={value}>
      {env.enableLocalUserControl && <UserControl user={user} />}
      {children}
    </UserContext.Provider>
  );
};

UserProvider.displayName = "UserProvider";
export default UserProvider;
