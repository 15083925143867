import { FC, ReactNode } from "react";
import { VStack } from "@chakra-ui/react";

const Toolbox: FC<{ children?: ReactNode | undefined }> = ({ children }) => {
  return (
    <VStack alignItems='center' borderRightWidth={1} py={3} px={3} spacing={3}>
      {children}
    </VStack>
  );
};

Toolbox.displayName = "Toolbox";

export default Toolbox;
