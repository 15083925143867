import { FC, useCallback, useEffect, useMemo } from "react";

import { Console } from "@adflow/types";
import { useObjectState } from "@adflow/utils";
import {
  Button,
  FormControl,
  FormHelperText,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList
} from "@choc-ui/chakra-autocomplete";
import { useAPIClient } from "../../context/apiClient";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (advertiser: number) => void;
  selectedAdvertiserId: number;
};

type SelectAdvertiserState = {
  selectedAdvertiserId: number;
  advertisers: Console.Advertiser[];
};

type LoadingState = {
  loadingAdvertisers: boolean;
};

const SelectAdvertiser: FC<Props> = ({
  selectedAdvertiserId,
  isOpen,
  handleClose,
  onSubmit
}) => {
  const [state, setState] = useObjectState<SelectAdvertiserState>({
    selectedAdvertiserId,
    advertisers: []
  });

  const [loading, setLoading] = useObjectState<LoadingState>({
    loadingAdvertisers: false
  });
  const apiContext = useAPIClient();

  useEffect(() => {
    setLoading({ loadingAdvertisers: true });
    async function fetchAdvertisers() {
      const response = await apiContext.listAdvertisers();
      setState({ advertisers: response });
      setLoading({ loadingAdvertisers: false });
    }

    function wait(delay: number) {
      return new Promise(resolve => setTimeout(resolve, delay));
    }

    let isCanceled = false;
    const retries = 10;

    function retryFetchAdvertisers(
      token: string,
      delay: number,
      tries: number
    ): Promise<void> {
      const onError = (err: Error) => {
        const backoff = delay * (retries + 1 - tries);
        const triesLeft = tries - 1;
        if (triesLeft < 0) {
          throw err;
        }
        return wait(delay).then(() => {
          if (isCanceled) {
            return;
          }
          retryFetchAdvertisers(token, backoff, triesLeft);
        });
      };
      return fetchAdvertisers().catch(onError);
    }

    const token = localStorage.getItem("token");

    retryFetchAdvertisers(token as string, 2000, retries);

    return () => {
      isCanceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdvertiserSelection = useCallback(
    async (item: any) => {
      const parsedAdvertiserId = Number.isInteger(parseInt(item))
        ? parseInt(item)
        : undefined;

      setState({
        selectedAdvertiserId: parsedAdvertiserId
      });
    },
    [setState]
  );

  const handleSubmit = useCallback(() => {
    if (!state.advertisers) {
      return; //TODO: handle this
    }
    onSubmit(state.selectedAdvertiserId);
  }, [onSubmit, state.advertisers, state.selectedAdvertiserId]);

  const sortedAdvertisers = useMemo(() => {
    return state.advertisers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [state.advertisers]);

  const value =
    state.selectedAdvertiserId === -1 ||
    state.selectedAdvertiserId === undefined
      ? undefined
      : state.selectedAdvertiserId.toString();

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='3xl'>
      <ModalOverlay />
      <ModalContent data-testid='selectAdvertiserModal'>
        <ModalHeader data-testid='selectAdvertiserTitle'>
          Select an advertiser
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* This is done to force the component to re-render, otherwise the input
          displays the value, not label. Appears to be a bug with the library */}
          {loading.loadingAdvertisers ? (
            <Spinner />
          ) : (
            <FormControl w='60'>
              <AutoComplete
                openOnFocus
                isLoading={loading.loadingAdvertisers}
                value={value}
                multiple={false}
                onChange={handleAdvertiserSelection}
                restoreOnBlurIfEmpty={false}
                closeOnBlur={true}
                submitKeys={["Enter"]}
              >
                <AutoCompleteInput
                  onChange={evt => {
                    if (!evt.target.value) {
                      setState({ selectedAdvertiserId: undefined });
                    }
                  }}
                  loadingIcon={<Spinner />}
                  variant='outline'
                  placeholder='Select an advertiser'
                  data-testid='selectAdvertiserInput'
                />
                <AutoCompleteList>
                  {sortedAdvertisers.map(advertiser => (
                    <AutoCompleteItem
                      key={advertiser._id.toString()}
                      value={advertiser._id.toString()}
                      label={advertiser.name}
                      textTransform='capitalize'
                    >
                      {advertiser.name}
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteList>
              </AutoComplete>
              <FormHelperText>
                All new templates require an advertiser
              </FormHelperText>
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={handleClose}>
            Close
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleSubmit}
            data-testid='confirmAdvertiserSelection'
            disabled={!state.selectedAdvertiserId}
          >
            Confirm selection
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

SelectAdvertiser.displayName = "SelectAdvertiser";

export default SelectAdvertiser;
export type { Props as SelectAdvertiserProps };
