import { ButtonGroup, Heading, IconButton, Box } from "@chakra-ui/react";
import { FC } from "react";
import { LuAlignJustify, LuAlignLeft, LuAlignRight } from "react-icons/lu";
import { useStore } from "../../../hooks/useStore";
import { shallow } from "zustand/shallow";
import { AdElementText } from "@adflow/types";

type Props = {
  selectedElement: AdElementText;
};

const TextAlignmentControls: FC<Props> = ({ selectedElement }) => {
  const store = useStore(
    state => ({
      selectedElementId: state.selectedElementId,
      updateElementData: state.updateElementData
    }),
    shallow
  );

  const handleTextAlignment = (
    alignment: "left" | "center" | "right" | null
  ) => {
    store.updateElementData(selectedElement.id, {
      ...selectedElement.data,
      style: {
        ...selectedElement.data.style,
        textAlign: alignment
      }
    });
  };
  return (
    <Box>
      <Heading fontSize='md' fontWeight='semibold'>
        Align
      </Heading>
      <ButtonGroup size='sm' isAttached variant='outline' mt={2}>
        <IconButton
          backgroundColor={
            selectedElement.data.style.textAlign === "left" ? "#F0F0F0" : "none"
          }
          aria-label='Align Left'
          icon={<LuAlignLeft />}
          onClick={() => handleTextAlignment("left")}
        />

        <IconButton
          backgroundColor={
            selectedElement.data.style.textAlign === "center"
              ? "#F0F0F0"
              : "none"
          }
          aria-label='Align Center'
          icon={<LuAlignJustify />}
          onClick={() => handleTextAlignment("center")}
        />

        <IconButton
          backgroundColor={
            selectedElement.data.style.textAlign === "right"
              ? "#F0F0F0"
              : "none"
          }
          aria-label='Align Right'
          icon={<LuAlignRight />}
          onClick={() => handleTextAlignment("right")}
        />
      </ButtonGroup>
    </Box>
  );
};

TextAlignmentControls.displayName = "TextAlignmentControls";

export default TextAlignmentControls;
export type { Props as TextAlignmentControlsProps };
