import { AdElementVector } from "@adflow/types";
import { FC, useEffect, useMemo } from "react";

type Props = {
  data: AdElementVector["data"];
  position: AdElementVector["position"];
  name: AdElementVector["name"];
};

export function updateVectorTeamColors(
  el: Element,
  fillColor: string | null,
  strokeColor: string | null
) {
  const shapes = el.querySelectorAll("path,circle,ellipse,polygon,rect");
  shapes.forEach(shape => {
    if (shape.getAttribute("fill") && fillColor) {
      shape.setAttribute("fill", fillColor);
    }
    if (shape.getAttribute("stroke") && strokeColor) {
      shape.setAttribute("stroke", strokeColor);
    }
    // remove fill opacity because fill and stroke support rgba, and overrides it
    shape.removeAttribute("fill-opacity");
  });
}

const VectorElement: FC<Props> = ({ data, position, name }) => {
  const svgId = name;
  const content = useMemo(() => {
    return data.content.startsWith("https://cdn.fresh8.co") &&
      data.viewBox.width
      ? `${data.content}?width=${data.viewBox.width * 2}`
      : data.content;
  }, [data.content, data.viewBox.width]);

  const vectorSvg = useMemo(() => {
    return (
      <svg
        viewBox={`0 0 ${data.viewBox.width} ${data.viewBox.height}`}
        width={data.viewBox.width}
        height={data.viewBox.height}
        fill='none' // It has to be none or the elements will be black
        id={svgId}
      >
        {data.effectFilters && (
          <defs dangerouslySetInnerHTML={{ __html: data.effectFilters }}></defs>
        )}
        <g
          dangerouslySetInnerHTML={{ __html: content }}
          type={"figma_vector"}
          id={svgId}
          fill={data.color.fill?.value}
          stroke={data.color.stroke?.value}
        ></g>
      </svg>
    );
  }, [data, svgId, content]);

  useEffect(() => {
    const strokeColor = data.color.stroke?.value || null;
    const fillColor = data.color.fill?.value || null;

    const svgElement = document.querySelector(
      '[id="' + svgId.replace(/["\\]/g, "\\$&").replace(/£/g, "Â£") + '"]'
    );

    if (svgElement) {
      updateVectorTeamColors(svgElement, fillColor, strokeColor);
    }
  }, [vectorSvg, data, svgId]);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: position.z,
        width: data.viewBox.width,
        height: data.viewBox.height
      }}
    >
      {vectorSvg}
    </div>
  );
};

VectorElement.displayName = "VectorElement";

export default VectorElement;
