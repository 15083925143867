import { useMemo, useState } from "react";
import { useEffectOnce, useInterval } from "@adflow/utils";

type FontSpec = {
  fontFamily: string | null | undefined;
  fontWeight: number | null | undefined;
  fontStyle: string | null | undefined;
};

export function useWaitForFontLoad(
  { fontFamily, fontWeight, fontStyle }: FontSpec,
  cb: () => void
) {
  const [fontReady, setFontReady] = useState(
    fontFamily == null || fontFamily === ""
  );

  const spec = useMemo(() => {
    let spec = `12px "${fontFamily}"`;
    if (fontWeight) {
      spec = `${fontWeight} ${spec}`;
    }
    if (fontStyle) {
      spec = `${fontStyle} ${spec}`;
    }
    return spec;
  }, [fontFamily, fontWeight, fontStyle]);

  useInterval(
    () => {
      if (document.fonts.check(spec)) {
        setFontReady(true);
        cb();
      }
    },
    fontReady ? null : 50
  );

  useEffectOnce(() => {
    if (fontReady) {
      cb();
    }
  });
}
