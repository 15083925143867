import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Link as ChakraLink
} from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { NavLink as ReactRouterLink, useLocation } from "react-router-dom";
import Button from "../ThemedButton";

export interface CancelConfirmationProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const CancelConfirmation: FC<CancelConfirmationProps> = ({
  onOpen,
  isOpen,
  onClose
}) => {
  // repass the query received to the next page
  const location = useLocation();
  const query = useMemo(() => {
    return location.search ? `${location.search}` : "";
  }, [location]);

  return (
    <>
      <Button
        testId='cancelButtonTopbar'
        muiButtonProps={{ onClick: onOpen, color: "secondary" }}
      >
        Cancel
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>All unsaved changes will be lost.</ModalBody>
          <ModalFooter>
            <Button
              muiButtonProps={{
                sx: { mr: "1rem" },
                color: "secondary",
                onClick: onClose
              }}
            >
              Cancel
            </Button>

            <ChakraLink as={ReactRouterLink} to={`/${query}`}>
              <Button muiButtonProps={{ color: "mcRed" }}>
                Yes, lose changes
              </Button>
            </ChakraLink>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
