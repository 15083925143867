import { AdElementRenderProps } from "@adflow/ad";
import {
  AdElementText,
  FontFace as AdflowFontFace,
  isTextElement,
  notEmpty
} from "@adflow/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useFonts(customFonts: AdflowFontFace[]) {
  const installedFonts = useRef<Set<string>>(new Set());
  const [ready, setReady] = useState(false);
  const [fontLoadResults, setFontLoadResults] = useState<
    Array<{
      font: AdflowFontFace;
      result: PromiseSettledResult<void>;
    }>
  >([]);

  const installFont = useCallback((fontFamily: string) => {
    installedFonts.current.add(fontFamily);
  }, []);

  const loadFont = useCallback(
    async (font: AdflowFontFace) => {
      if (installedFonts.current.has(font.fontFamily)) {
        return;
      }
      const ff = new FontFace(font.fontFamily, `url("${font.src}")`, {
        style: font.style,
        weight: font.weight.toString()
      });

      await ff.load();

      installFont(font.fontFamily);

      // Technically this is an exprimental API so the types
      // don't really work
      (document.fonts as any).add(ff);

      return;
    },
    [installFont, installedFonts]
  );

  useEffect(() => {
    let mounted = true;

    (async () => {
      setReady(false);
      const result = await Promise.allSettled(customFonts.map(loadFont));

      if (mounted) {
        setReady(true);
        setFontLoadResults(
          customFonts.map((f, i) => ({ font: f, result: result[i] }))
        );
      }
    })();

    return () => {
      mounted = false;
    };
  }, [customFonts, loadFont]);

  return [ready, fontLoadResults] as const;
}

const getCustomFontsToLoad = (
  customFonts: AdflowFontFace[],
  textElements: AdElementText[]
): AdflowFontFace[] => {
  const fontFamiliesInUse = textElements
    .map(el => el.data.style.fontFamily)
    .filter(notEmpty);

  return customFonts.filter(ff =>
    fontFamiliesInUse.some(family => ff.fontFamily === family)
  );
};

export function useLoadFontsForAd(
  adElements: AdElementRenderProps[],
  customFonts: AdflowFontFace[]
) {
  const fontsToLoad = useMemo(() => {
    const textElements = adElements.filter(isTextElement);
    return getCustomFontsToLoad(customFonts, textElements);
  }, [adElements, customFonts]);

  return useFonts(fontsToLoad);
}
