import { useEffect, useMemo, useState, useContext } from "react";

import * as Adflow from "adflow";
import { useLocation, useParams } from "react-router-dom";

import {
  APIClientContext as FigmaAPIClientContext,
  name as FigmaName,
  EditorSidebarButton as FigmaSidebarButton
} from "@adflow/figma";
import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import {
  APIClientContext as ShadowBrandAPIClientContext,
  name as ShadowBrandName,
  EditorSidebarButton as ShadowBrandSidebarButton,
  EditorSidebarView as ShadowBrandSidebarView
} from "@adflow/shadow-brand";
import {
  APIClientContext as SelectAdvertiserAPIClientContext,
  name as MarketingCloudName,
  EditorSidebarButton as MarketingCloudSidebarButton,
  EditorSidebarView as MarketingCloudSidebarView
} from "@adflow/marketing-cloud";
import { AdTemplate, Datasources, EditorState, FontFace } from "@adflow/types";

import { TRPCClient } from "../utils/apiClient";
import UserContext from "../../UserProvider/contexts/UserContext";
import { canEdit } from "../utils/permissions";

function Duplicate() {
  const userContext = useContext(UserContext);
  const [hasEditPermissions, sethasEditPermissions] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    const checkPermissions = async () => {
      if (!userContext.user) {
        sethasEditPermissions(false);
        return;
      }
      const result = await canEdit(userContext.user);
      sethasEditPermissions(result);
    };

    checkPermissions();
  }, [userContext.user]);

  const modules = useMemo(
    () => [
      {
        name: FigmaName,
        EditorSidebarButton: FigmaSidebarButton
      },
      {
        name: ShadowBrandName,
        EditorSidebarButton: ShadowBrandSidebarButton,
        EditorSidebarView: ShadowBrandSidebarView
      },
      {
        name: MarketingCloudName,
        EditorSidebarButton: MarketingCloudSidebarButton,
        EditorSidebarView: MarketingCloudSidebarView
      }
    ],
    []
  );

  const { id: idParam } = useParams<{ id: string | undefined }>();
  const id = idParam || "";

  const [adTemplate, setAdTemplate] = useState<AdTemplate | null>(null);

  const [brand, setBrand] = useState<ConsoleAPIGraph.Brand | null>(null);

  const [fonts, setFonts] = useState<Array<FontFace>>([]);
  const [ready, setReady] = useState(false);

  const defaultStoreValues = useMemo<Partial<EditorState>>(() => {
    if (!adTemplate) {
      return {};
    }
    if (!brand) {
      return {};
    }
    if (!fonts) {
      return {};
    }
    setReady(true);

    // this is to preserve the current configuration from the shadow brand when
    // haven't already used the template configuration.
    let teamAssetsConfig = adTemplate.displayOptions?.teamAssets;
    if (!teamAssetsConfig) {
      teamAssetsConfig = {
        type: "JERSEY", // default
        fallback: "NONE"
      };
    }
    return {
      ...adTemplate,
      name: adTemplate.name + "_DUPLICATE",
      elements: adTemplate.adElements,
      brand,
      customFonts: fonts,
      teamAssets: teamAssetsConfig
    };
  }, [adTemplate, brand, fonts]);

  const dataSources = useMemo(() => {
    return Datasources.createDataSourceDefinitions(
      adTemplate?.dataSources || null
    );
  }, [adTemplate]);

  useEffect(() => {
    async function fetchAdTemplate() {
      try {
        const data = await TRPCClient.client.adTemplate.findOne.query({ id });
        setAdTemplate(data);
      } catch (err) {
        console.log(err);
      }
    }
    async function fetchBrand(brandId: string) {
      try {
        const data = await TRPCClient.client.consoleAPIGraph.getBrand.query(
          brandId
        );
        if (!data) {
          throw new Error("no data when getting brand for id:" + brandId);
        }
        setBrand(data);
        if (data.team) {
          const fonts = await TRPCClient.client.consoleAPIGraph.listFonts.query(
            {
              team: data.team
            }
          );
          if (fonts) {
            setFonts(fonts.fontFaces);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (!adTemplate) {
      fetchAdTemplate();
    }
    if (adTemplate?.brandId) {
      fetchBrand(adTemplate?.brandId);
    }
  }, [adTemplate, id]);

  if (!ready) {
    return <span>Loading</span>;
  }

  return (
    <div
      className='container'
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Adflow.EditorProvider
        dataSources={dataSources}
        defaultStoreValues={defaultStoreValues}
      >
        <FigmaAPIClientContext.Provider {...TRPCClient.figmaClient}>
          <ShadowBrandAPIClientContext.Provider {...TRPCClient.consoleClient}>
            <SelectAdvertiserAPIClientContext.Provider
              {...TRPCClient.marketingCloudClient}
            >
              <Adflow.Editor
                saveButtonVisible={hasEditPermissions}
                onSave={async editorState => {
                  // brandID is mandatory before saving but the
                  // brand could possibly be null in the editor state.
                  if (
                    !editorState.brand ||
                    typeof editorState.brand._id !== "string"
                  ) {
                    return;
                  }
                  await TRPCClient.client.adTemplate.create.mutate({
                    name: editorState.name,
                    brandId: editorState.brand?._id,
                    dataSources: editorState.dataSources,
                    adElements: editorState.elements,
                    displayOptions: editorState.displayOptions,
                    thumbnail: state.thumbnail,
                    businessEntityId: editorState.businessEntityId
                  });
                }}
                modules={modules}
                renderPreview={args => (
                  <Adflow.Preview
                    elements={args.elements}
                    brand={args.brand}
                    fonts={args.fonts}
                    displayOptions={args.displayOptions}
                    selectedElementId={args.selectedElementId}
                    isIndexPreview={false}
                  ></Adflow.Preview>
                )}
              ></Adflow.Editor>
            </SelectAdvertiserAPIClientContext.Provider>
          </ShadowBrandAPIClientContext.Provider>
        </FigmaAPIClientContext.Provider>
      </Adflow.EditorProvider>
    </div>
  );
}
export default Duplicate;
