export function formatDate(
  date: Date,
  options: Intl.DateTimeFormatOptions = {}
): string {
  // Default options for formatting
  const defaultOptions: Intl.DateTimeFormatOptions = {
    year: "numeric", // e.g., 2021
    month: "numeric", // e.g., July
    day: "numeric", // e.g., 19
    hour: "numeric", // e.g., 13
    minute: "numeric", // e.g., 30

    ...options // Merge with custom options
  };

  // Use Intl.DateTimeFormat for formatting the date
  return new Intl.DateTimeFormat(window.navigator.language, defaultOptions)
    .format(date)
    .replace(/,/g, "");
}
