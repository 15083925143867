import { useMemo } from "react";

import * as Adflow from "adflow";

import {
  APIClientContext as FigmaAPIClientContext,
  name as FigmaName,
  EditorSidebarButton as FigmaSidebarButton
} from "@adflow/figma";
import {
  APIClientContext as ShadowBrandAPIClientContext,
  name as ShadowBrandName,
  EditorSidebarButton as ShadowBrandSidebarButton,
  EditorSidebarView as ShadowBrandSidebarView
} from "@adflow/shadow-brand";
import {
  APIClientContext as SelectAdvertiserAPIClientContext,
  name as MarketingCloudName,
  EditorSidebarButton as MarketingCloudSidebarButton,
  EditorSidebarView as MarketingCloudSidebarView
} from "@adflow/marketing-cloud";
import { Datasources } from "@adflow/types";
import { TRPCClient } from "../utils/apiClient";

function Create() {
  const modules = useMemo(
    () => [
      {
        name: FigmaName,
        EditorSidebarButton: FigmaSidebarButton
      },
      {
        name: ShadowBrandName,
        EditorSidebarButton: ShadowBrandSidebarButton,
        EditorSidebarView: ShadowBrandSidebarView
      },
      {
        name: MarketingCloudName,
        EditorSidebarButton: MarketingCloudSidebarButton,
        EditorSidebarView: MarketingCloudSidebarView
      }
    ],
    []
  );
  const dataSources = Datasources.createDataSourceDefinitions(null);
  return (
    <div
      className='container'
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Adflow.EditorProvider dataSources={dataSources}>
        <FigmaAPIClientContext.Provider {...TRPCClient.figmaClient}>
          <ShadowBrandAPIClientContext.Provider {...TRPCClient.consoleClient}>
            <SelectAdvertiserAPIClientContext.Provider
              {...TRPCClient.marketingCloudClient}
            >
              <Adflow.Editor
                onSave={async editorState => {
                  // brandID is mandatory before saving but the
                  // brand could possibly be null in the editor state.
                  if (
                    !editorState.brand ||
                    typeof editorState.brand._id !== "string"
                  ) {
                    return;
                  }
                  await TRPCClient.client.adTemplate.create.mutate({
                    name: editorState.name,
                    brandId: editorState.brand?._id,
                    dataSources: editorState.dataSources,
                    adElements: editorState.elements,
                    displayOptions: editorState.displayOptions,
                    thumbnail: editorState.thumbnailURL,
                    businessEntityId: editorState.businessEntityId
                  });
                }}
                modules={modules}
                renderPreview={args => (
                  <Adflow.Preview
                    elements={args.elements}
                    brand={args.brand}
                    fonts={args.fonts}
                    displayOptions={args.displayOptions}
                    selectedElementId={args.selectedElementId}
                    isIndexPreview={false}
                  ></Adflow.Preview>
                )}
              ></Adflow.Editor>
            </SelectAdvertiserAPIClientContext.Provider>
          </ShadowBrandAPIClientContext.Provider>
        </FigmaAPIClientContext.Provider>
      </Adflow.EditorProvider>
    </div>
  );
}

export default Create;
