import { FC, PropsWithChildren } from "react";

import { FigmaComponent, FigmaNodeTree, NodeTree } from "../types";
import {
  AdElement,
  AdElementImage,
  AdElementSVG,
  AdElementTeamAsset,
  AdElementText,
  AdElementVector
} from "@adflow/types";
import { createGenericContext } from "@adflow/utils";

type GetComponentsResponse = {
  components: Record<
    string,
    {
      key: string;
      name: string;
    }
  >;
};
export type SavedComponent = {
  id: string;
  name: string;
  figmaId: string;
  fileId: string;
  svg: string | null;
  svgUrl: string | null;
  thumbnail: string | null;
  svgElement: AdElementSVG | null;
  textElements: Array<AdElement>;
  imageElements: Array<AdElement>;
  vectorElements?: Array<AdElement>;
};
type SVGString = string;
interface APIClientContext {
  getComponents: (params: {
    fileId: string;
  }) => Promise<{ components: Array<FigmaComponent>; thumbnailURL?: string }>;
  getComponentImage: (params: {
    fileId: string;
    componentId: string;
    useProcessSVG?: boolean;
  }) => Promise<{
    componentSVG: SVGString;
    componentSVGURL: string;
    componentThumbnailURL: string;
    componentNodeTree: never[];
  }>;
  getExportableComponentAdElements: (param: {
    fileId: string;
    componentId: string;
  }) => Promise<{ root: AdElementSVG; componentAdElements: Array<AdElement> }>;
  listSavedComponents: (params: { fileId: string }) => Promise<{
    components: Array<SavedComponent>;
  }>;
  upsertFigmaComponent: (params: {
    figmaId: string;
    fileId: string;
    name: string;
    svg: string | null;
    svgUrl: string | null;
    thumbnail: string | null;
    svgElement: AdElementSVG | null;
    textElements: Array<AdElementText>;
    imageElements: Array<AdElementImage>;
    vectorElements?: Array<AdElementVector>;
    teamAssetElements: Array<AdElementTeamAsset>;
  }) => Promise<{
    id: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    figmaId: string;
    fileId: string;
    svg: string | null;
    svgUrl: string | null;
    thumbnail: string | null;
  }>;
}

const [useAPIClient, APIClientContextProvider] =
  createGenericContext<APIClientContext | null>();

const Provider: FC<PropsWithChildren<APIClientContext>> = ({
  children,
  ...value
}) => {
  return (
    <APIClientContextProvider value={value}>
      {children}
    </APIClientContextProvider>
  );
};

export { Provider, useAPIClient };
export type {
  APIClientContext as APIClient,
  FigmaComponent,
  FigmaNodeTree,
  GetComponentsResponse,
  NodeTree
};
