import { ChangeEventHandler, FC, useCallback } from "react";
import {
  DisplayOptionsTeamAssetFallback,
  DisplayOptionsTeamAssetType,
  stringIsOfUnionType
} from "@adflow/types";
import { Box, Heading, Select, Text } from "@chakra-ui/react";
import { useStore } from "../../../hooks/useStore";

type TeamAssetGlobalControlsProps = object;

const teamAssetTypeOptions: Array<DisplayOptionsTeamAssetType> = [
  "BADGE",
  "JERSEY"
];
const teamAssetFallbackOptions: Array<DisplayOptionsTeamAssetFallback> = [
  "NONE",
  "BADGE",
  "JERSEY"
];

export const TeamAssetGlobalControls: FC<TeamAssetGlobalControlsProps> = () => {
  const { teamAssets, setDisplayOptions } = useStore(state => ({
    teamAssets: state.displayOptions.teamAssets,
    setDisplayOptions: state.setDisplayOptions
  }));

  const handleTeamAssetTypeChange: ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      evt => {
        if (!stringIsOfUnionType(evt.target.value, teamAssetTypeOptions)) {
          return;
        }

        setDisplayOptions("teamAssets", {
          ...teamAssets,
          type: evt.target.value
        });
      },
      [setDisplayOptions, teamAssets]
    );

  const handleTeamAssetFallbackChange: ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      evt => {
        if (!stringIsOfUnionType(evt.target.value, teamAssetFallbackOptions))
          return;
        setDisplayOptions("teamAssets", {
          ...teamAssets,
          fallback: evt.target.value
        });
      },
      [setDisplayOptions, teamAssets]
    );

  return (
    <Box mt={10} pt={5} borderTop={"1px solid"} borderTopColor={"gray.200"}>
      <Heading as='h2' size='md' mb='4'>
        Team Asset Configuration
      </Heading>
      <Heading fontSize='sm' fontWeight='medium' mb='4'>
        Select the type of asset you want to use
      </Heading>
      <TeamAssetConfigSelectProps
        value={teamAssets.type}
        onChange={handleTeamAssetTypeChange}
        options={teamAssetTypeOptions}
      />
      {teamAssets.type === "BADGE" || teamAssets.type === "JERSEY" ? (
        <Text mt={4} fontStyle={"italic"} fontSize={"sm"}>
          When badges or Jerseys are used, international events (USA vs UK) will
          display country flags.
        </Text>
      ) : teamAssets.type === "CUSTOM" ? (
        <>
          <Text my={4} fontStyle={"italic"} fontSize={"sm"}>
            Incase we can&apos;t find a custom asset for either of the matching
            teams, please choose a fallback behaviour
          </Text>
          <TeamAssetConfigSelectProps
            value={teamAssets.fallback}
            onChange={handleTeamAssetFallbackChange}
            options={teamAssetFallbackOptions}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

TeamAssetGlobalControls.displayName = "TeamAssetGlobalControls";

type TeamAssetConfigSelectProps = {
  value: string;
  options: Array<string>;
  onChange: ChangeEventHandler<HTMLSelectElement>;
};

const TeamAssetConfigSelectProps: FC<TeamAssetConfigSelectProps> = ({
  value,
  onChange,
  options
}) => {
  return (
    <Select placeholder='select option' value={value} onChange={onChange}>
      {options.map(opt => {
        const display =
          opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase();
        return (
          <option key={opt} value={opt}>
            {display}
          </option>
        );
      })}
    </Select>
  );
};

TeamAssetConfigSelectProps.displayName = "TeamAssetConfigSelectProps";
