import { FC } from "react";

import { EditorSidebarButtonProps } from "@adflow/types";
import { IconButton, Image, useDisclosure } from "@chakra-ui/react";

import FigmaComponentImportModal from "../FigmaComponentImportModal";
import figmaIconUrl from "./figma-icon.svg";

// In the future we will move this modal somewhere else
// It's a little bit weird having the modal open up in this button
const MenuButton: FC<EditorSidebarButtonProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IconButton
        size='lg'
        px={4}
        aria-label='Search database'
        icon={<Image src={figmaIconUrl} />}
        borderRadius='100%'
        aspectRatio='1/1'
        onClick={onOpen}
        data-testid='figmaImportButton'
      />
      {/* Note: un-mount this stateful component when closed. By default
          this won't be removed from the component tree */}
      {isOpen && (
        <FigmaComponentImportModal onClose={onClose} isOpen={isOpen} />
      )}
    </>
  );
};

MenuButton.displayName = "FigmaMenuButton";

export default MenuButton;
