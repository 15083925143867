import MuiTypography, {
  TypographyProps as MuiTypographyProps
} from "@mui/material/Typography";

interface TypographyProps {
  muiTypographyProps: MuiTypographyProps;
  children: React.ReactNode;
  testId?: string;
}

const Typography = (props: TypographyProps) => {
  return (
    <MuiTypography {...props.muiTypographyProps} data-testid={props.testId}>
      {props.children}
    </MuiTypography>
  );
};
export default Typography;
