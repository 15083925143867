import theme from "@adflow/theme";
import { FeatureFlagProvider } from "@adflow/utils";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Router } from "@remix-run/router";
import { FC, StrictMode } from "react";
import { RouterProvider } from "react-router-dom";
import { userToRequestContextAuthorizationHeader } from "./UserProvider/models/User";
import UserProvider from "./UserProvider";
import { trpc } from "./Templates/utils/apiClient";
import env from "./env";
import { IMarketingCloudUser } from "@ads/iam-library";
import client from "./apolloClient";
import { ApolloProvider } from "@apollo/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { useTRPCReact } from "./Templates/utils/apiClient";

export const App: FC<{ router: Router; user: IMarketingCloudUser | null }> = ({
  router,
  user
}) => {
  const { trpcClient, queryClient } = useTRPCReact(
    env.localMode && user
      ? userToRequestContextAuthorizationHeader(user)
      : undefined
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <StrictMode>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ApolloProvider client={client}>
              <FeatureFlagProvider>
                <UserProvider user={user}>
                  <RouterProvider
                    router={router}
                    fallbackElement={<p>Initial Load...</p>}
                  />
                </UserProvider>
              </FeatureFlagProvider>
            </ApolloProvider>
          </ThemeProvider>
        </StrictMode>
      </QueryClientProvider>
    </trpc.Provider>
  );
};
