import { Menu, MenuItem, IconButton } from "@mui/material";
import React, { MouseEvent, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi"; // Import the 3 dots icon

interface MenuItemType {
  name: string;
  templateId: string;
  action: () => void;
}

interface NavbarUserDropdownProps {
  menuItems: MenuItemType[];
  testId: string;
}

const NavbarUserDropdown: React.FC<NavbarUserDropdownProps> = ({
  menuItems,
  testId
}) => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.currentTarget;
    setAnchorMenu(target);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <div className='navMenu' style={{ position: "relative" }}>
      {/* 3-dots Icon Button */}
      <IconButton onClick={toggleMenu} data-testid={testId}>
        <BiDotsVerticalRounded size={24} /> {/* Adjust size as needed */}
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            sx: {
              borderRadius: ".1rem",
              boxShadow:
                "rgba(60, 64, 67, 0.2) 0px .1rem .2rem 0px, rgba(60, 64, 67, 0.05) 0px .1rem .3rem .1rem",
              zIndex: 1,
              transform: "translateX(-.5rem)"
            }
          }
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            sx={{ fontSize: "1.1rem" }}
            data-testid={`template${item.templateId}`}
            onClick={() => {
              item.action();
              closeMenu(); // Close menu after action
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default NavbarUserDropdown;
