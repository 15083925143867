import { IMarketingCloudUser } from "@ads/iam-library";
import { createContext } from "react";

const UserContext = createContext<{
  user: IMarketingCloudUser | null;
  setUser: (user: IMarketingCloudUser | null) => void;
}>({ user: null, setUser: () => null });
UserContext.displayName = "UserContext";

export const Provider = UserContext.Provider;
export const Consumer = UserContext.Consumer;
export default UserContext;
