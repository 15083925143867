import { useContext } from "react";
import { useStore as useZustandStore } from "zustand";

import StoreContext from "../contexts/EditorContext";

// uncomment if you don't want to go through in the import flow everytime
import mock from "./mock.json";
import { AdElement } from "@adflow/types";
import { createEditorStore } from "../store";

type EditorStoreApi = ReturnType<typeof createEditorStore>;

type ExtractState<S> = S extends {
  getState: () => infer T;
}
  ? T
  : never;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const useMock = urlParams.has("useMock");

export function useStore<StateSlice = ExtractState<EditorStoreApi>>(
  selector: (state: ExtractState<EditorStoreApi>) => StateSlice,
  equalityFn?: (a: StateSlice, b: StateSlice) => boolean
) {
  const ctx = useContext(StoreContext);

  if (ctx.store === null) {
    throw new Error("Expected EditorContext but got null.");
  }

  if (import.meta.env.DEV) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(window as any).__STORE__) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__STORE__ = ctx.store;
    }
    // uncomment if you don't want to go through in the import flow everytime
    if (!ctx.store.getState().elements.length && useMock) {
      ctx.store.getState().addElements(mock as AdElement[]);
    }
  }

  return useZustandStore(ctx.store, selector, equalityFn);
}
