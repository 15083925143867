import { FC, useCallback, useState } from "react";

import { EditorSidebarButtonProps } from "@adflow/types";

import { IconButton, Image, useDisclosure } from "@chakra-ui/react";
import { useEditorStore } from "@adflow/editor";
import sportradarIconUrl from "./sportradar-logo.svg";
import SelectAdvertiser, { SelectAdvertiserProps } from "../SelectAdvertiser";

// In the future we will move this modal somewhere else
// It's a little bit weird having the modal open up in this button
const MenuButton: FC<EditorSidebarButtonProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const s = useEditorStore(state => ({
    setBusinessEntityId: state.setBusinessEntityId,
    businessEntityId: state.businessEntityId
  }));
  const [selectedAdvertiserId, setSelectedAdvertiserId] = useState<number>(
    s.businessEntityId || -1
  );
  const handleSubmit = useCallback<SelectAdvertiserProps["onSubmit"]>(
    selectedAdvertiserId => {
      setSelectedAdvertiserId(selectedAdvertiserId);
      s.setBusinessEntityId(selectedAdvertiserId);
      onClose();
    },
    [onClose, s]
  );

  return (
    <>
      <IconButton
        size='lg'
        px={4}
        aria-label='MACL button'
        icon={<Image src={sportradarIconUrl} />}
        borderRadius='100%'
        aspectRatio='1/1'
        onClick={onOpen}
        data-testid='maclButton'
      />
      {/* Note: un-mount this stateful component when closed. By default
          this won't be removed from the component tree */}
      {isOpen && (
        <SelectAdvertiser
          selectedAdvertiserId={selectedAdvertiserId}
          isOpen={isOpen}
          handleClose={onClose}
          onSubmit={handleSubmit}
        />
      )}{" "}
    </>
  );
};

MenuButton.displayName = "MarketingCloudMenuButton";

export default MenuButton;
