import { useState, useEffect, useRef, ComponentProps } from "react";
import { Image } from "@chakra-ui/react";

type ImageProps = ComponentProps<typeof Image>;

function LazyImage({ ...props }: ImageProps) {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
    if (imgRef.current) observer.observe(imgRef.current);

    return () => observer.disconnect();
  }, []);

  return <div ref={imgRef}>{isVisible && <Image {...props} />}</div>;
}

export { LazyImage };
