import { nanoid } from "nanoid/non-secure";
import { createStore } from "zustand";

import {
  AdElementSchema,
  Datasources,
  EditorState,
  EditorStateActions,
  isImageElement,
  isSVGElement,
  isTeamAssetElement,
  isTextElement,
  isVectorElement
} from "@adflow/types";
import { Element, SVG } from "@svgdotjs/svg.js";

type Set = (
  partial:
    | EditorState
    | Partial<EditorState>
    | ((state: EditorState) => EditorState | Partial<EditorState>),
  replace?: boolean | undefined
) => void;

type Get = () => EditorState;

const standardFonts = [
  "Arial",
  "Helvetica",
  "Times New Roman",
  "Courier New",
  "Verdana",
  "Georgia",
  "Impact",
  "Trebuchet MS",
  "Comic Sans MS",
  "Tahoma"
];

export const DATA_SOURCE_REMOVED = "DATA_SOURCE_REMOVED";

export const createEditorStore = (
  defaultStoreValues?: Partial<EditorState>
) => {
  const createInitialState = (get: Get, set: Set) =>
    ({
      name: defaultStoreValues?.name || "New Ad Template",
      thumbnailURL: "",
      bgColour: defaultStoreValues?.bgColour || "",
      elements: defaultStoreValues?.elements || [],
      selectedElementId: defaultStoreValues?.selectedElementId || null,
      dataSources: defaultStoreValues?.dataSources || [],
      brand: defaultStoreValues?.brand || null,
      standardFonts,
      customFonts: defaultStoreValues?.customFonts || [],
      displayOptions: defaultStoreValues?.displayOptions || {
        teamAssets: {
          type: "JERSEY",
          fallback: "NONE"
        },
        dateTimeFormat: {
          timeFormat: "hour12",
          dayFormat: "long",
          monthFormat: "long",
          showTimezone: true,
          countdownShowDays: true,
          countdownDaysSeparator: "days"
        },
        odds: {
          type: "decimalOdds"
        }
      },
      businessEntityId: defaultStoreValues?.businessEntityId || null,
      userId: defaultStoreValues?.userId || null,
      ...storeActions(set, get)
    } satisfies EditorState);

  const s = createStore<ReturnType<typeof createInitialState>>()((set, get) =>
    createInitialState(get, set)
  );

  return s;
};

function storeActions(set: Set, get: Get) {
  const actions = {
    setName: name => set(() => ({ name })),
    setBgColour: bgColour => set(() => ({ bgColour })),
    addElement: el =>
      set(state => {
        if (!el.sources) el.sources = [];
        return { elements: [...state.elements, el] };
      }),
    removeNodesFromSVG: removeNodesFromSVG(set, get),
    getImageLinkForImageElementNameFromSVG:
      getImageLinkForImageElementNameFromSVG(set, get),
    generateUniqueIDs: generateUniqueIDs(set, get),
    setElementSelected: maybeId => {
      set(() => {
        return {
          selectedElementId: maybeId
        };
      });
    },
    removeElement: id => {
      set(state => {
        const elements = state.elements.filter(el => el.id !== id);
        return { elements, selectedElementId: null };
      });
    },
    addElements: els => set(() => ({ elements: els })),
    addDataSource: (type, dataType = "UNKNOWN", id = nanoid(11)) =>
      set(state => ({
        dataSources: [{ id, type, dataType }, ...state.dataSources]
      })),
    removeDataSource: id =>
      set(state => ({
        dataSources: state.dataSources.filter(source => source.id !== id)
      })),
    updateElementName: (id, updatedName) =>
      set(state => {
        const itemIndex = state.elements.findIndex(el => el.id === id);

        if (itemIndex == -1) {
          console.warn(
            `Tried to update element with id:${id} but no element was found.`
          );
          return {};
        }

        const element = state.elements[itemIndex];

        const updatedElement = {
          ...element,
          name: updatedName
        };

        return {
          elements: insertItem(state.elements, itemIndex, updatedElement)
        };
      }),
    updateElementData: (id, updatedData) =>
      set(state => {
        const itemIndex = state.elements.findIndex(el => el.id === id);

        if (itemIndex == -1) {
          console.warn(
            `Tried to update element with id:${id} but no element was found.`
          );
          return {};
        }

        const element = state.elements[itemIndex];

        const updatedElement = AdElementSchema.parse({
          ...element,
          data: updatedData
        });

        return {
          elements: insertItem(state.elements, itemIndex, updatedElement)
        };
      }),
    updateElementDataSources: (id, dataSources) =>
      set(state => {
        const itemIndex = state.elements.findIndex(
          existing => existing.id === id
        );

        if (itemIndex == -1) {
          console.warn(
            `Tried to update element with id:${id} but no element was found.`
          );
          return {};
        }

        const element = state.elements[itemIndex];

        return {
          elements: insertItem(state.elements, itemIndex, {
            ...element,
            sources: dataSources
          })
        };
      }),
    removeElementDataSource: (id, dataSourceId) =>
      set(state => {
        const itemIndex = state.elements.findIndex(
          existing => existing.id === id
        );

        if (itemIndex == -1) {
          console.warn(
            `Tried to update element with id:${id} but no element was found.`
          );
          return {};
        }

        const element = { ...state.elements[itemIndex] };

        // Remove any templated strings from the text element
        if (isTextElement(element)) {
          element.data.content = DATA_SOURCE_REMOVED;
        }

        return {
          elements: insertItem(state.elements, itemIndex, {
            ...element,
            sources: element.sources?.filter(
              source => source.sourceId !== dataSourceId
            )
          })
        };
      }),
    addBrand: brand => set(() => ({ brand })),
    setCustomFonts: customFonts => set(() => ({ customFonts })),
    setDisplayOptions: (key, value) => {
      return set(state => ({
        displayOptions: { ...state.displayOptions, [key]: value }
      }));
    },

    setThumbnailURL: url => set(() => ({ thumbnailURL: url })),
    validateSelectedFonts: () => {
      const state = get();
      const textElements = state.elements.filter(isTextElement);
      const customFonts = state.customFonts.map(f => f.fontFamily);
      const allFonts = [...state.standardFonts, ...customFonts];
      return textElements.every(
        el =>
          el.data.style.fontFamily &&
          allFonts.includes(el.data.style.fontFamily)
      );
    },
    validateElementContents: () => {
      const state = get();
      const dataSourceIds = state.dataSources.map(ds => ds.id);
      const textElements = state.elements.filter(isTextElement);
      const imageElements = state.elements.filter(isImageElement);
      const teamAssetElements = state.elements.filter(isTeamAssetElement);

      return [...textElements, ...imageElements, ...teamAssetElements].every(
        el =>
          el.data.content !== DATA_SOURCE_REMOVED &&
          validateContentDataSource(el.data.content, dataSourceIds)
      );
    },
    resetElementData: () =>
      set(() => ({
        elements: [],
        selectedElementId: null,
        dataSources: []
      })),
    upsertDataSources: (desiredDataSourceTypes, allDataSources) => {
      const state = get();
      let newDatasources = state.dataSources.slice();

      const getDatasourceId = (dsType: string) => {
        const dataSource = allDataSources.find(
          dataSource => dataSource.type === dsType
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return dataSource!.id;
      };

      // Add any new data sources
      desiredDataSourceTypes.forEach(type => {
        const dataSourceExists = newDatasources.some(
          dataSource => dataSource.type === type
        );
        if (!dataSourceExists) {
          newDatasources.push({
            type,
            dataType: Datasources.getDataTypeFromSourceType(type),
            id: getDatasourceId(type)
          });
        }
      });

      newDatasources = newDatasources
        // Remove any data sources that are not selected
        .filter(ds => {
          return desiredDataSourceTypes.includes(ds.type);
        })
        .sort((a, b) => {
          return a.type.localeCompare(b.type);
        });

      return set({
        dataSources: newDatasources
      });
    },
    setBusinessEntityId(id: number) {
      return set({
        businessEntityId: id
      });
    }
  } as const satisfies EditorStateActions;

  return actions;
}

const bracketsRegex = /\(([^)]+)\)/;

const removeNodesFromSVG =
  (set: Set, get: Get) => (svgId: string, ids: Array<string>) => {
    const state = get();
    const elements = state.elements;

    const root = elements.filter(isSVGElement).find(el => el.id === svgId);
    const _root = structuredClone(root);
    const rootIndex = elements.findIndex(el => el.id === svgId);

    // handle better?
    if (!_root) return;

    const rootNode = SVG(_root.data.svg);

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const svgEl = rootNode.findOne(
        '[id="' + id.replace(/["\\]/g, "\\$&").replace(/£/g, "Â£") + '"]'
      );
      if (svgEl == null) {
        console.warn(`Could not find el with id #${id} when removing from svg`);
        continue;
      }
      svgEl.remove();

      // Remove any large base64 embeded images from <defs/>
      const fillValue = svgEl.attr("fill") ?? "";
      const patternId = getPatternIdFromFill(fillValue);

      if (patternId != null) {
        const patternEl = rootNode.findOne(patternId);
        if (patternEl == null) {
          continue;
        }
        const base64ImageId = patternEl.findOne("USE")?.attr("xlink:href");
        rootNode.findOne(base64ImageId)?.remove();
      }
    }
    // Update the svg string to reflect the changes made above
    const newString = rootNode.svg();
    _root.data.svg = newString;
    set(state => ({
      ...state,
      elements: insertItem(state.elements, rootIndex, _root)
    }));
  };

const getImageLinkForImageElementNameFromSVG =
  (set: Set, get: Get) =>
  (svgId: string, name: string): string => {
    const state = get();
    const elements = state.elements;
    const root = elements.filter(isSVGElement).find(el => el.id === svgId);
    const _root = structuredClone(root);

    if (!_root) return "";
    const rootNode = SVG(_root.data.svg);

    let svgEl = rootNode.findOne(
      '[id="' + name.replace(/["\\]/g, "\\$&").replace(/£/g, "Â£") + '"]'
    );
    if (svgEl?.type === "g") {
      svgEl = svgEl.findOne("rect");
    }
    if (svgEl) {
      const fillValue = svgEl.attr("fill") ?? "";
      const patternId = getPatternIdFromFill(fillValue);
      const patternEl = rootNode.findOne(patternId as string);
      const imageId = patternEl?.findOne("use")?.attr("xlink:href");

      return rootNode.findOne(imageId)?.attr("xlink:href") || "";
    }
    return "";
  };

const generateUniqueIDs = (set: Set, get: Get) => (svgId: string) => {
  const state = get();
  const elements = state.elements;

  const root = elements.filter(isSVGElement).find(el => el.id === svgId);
  const _root = structuredClone(root);
  const rootIndex = elements.findIndex(el => el.id === svgId);

  // handle better?
  if (!_root) return;

  const rootNode = addPrefixToSvgIDs(_root.data.svg, nanoid(16));
  // Update the svg string to reflect the changes made above
  _root.data.svg = rootNode.svg();
  set(state => ({
    ...state,
    elements: insertItem(state.elements, rootIndex, _root)
  }));

  elements.filter(isVectorElement).forEach(vectorElement => {
    const _vectorElement = structuredClone(vectorElement);
    const vectorElementIndex = elements.findIndex(
      el => el.id === vectorElement.id
    );

    const svgString = `<svg>${vectorElement.data.content}<defs>${
      vectorElement.data.effectFilters ?? ""
    }</defs></svg>`;
    const rootNode = addPrefixToSvgIDs(svgString, nanoid(16));
    // Update the svg string to reflect the changes made above
    let contentString = "";
    rootNode
      .children()
      .filter(element => element.type !== "defs")
      .forEach(element => {
        contentString += element.svg();
      });
    _vectorElement.data.content = contentString;

    if (_vectorElement.data.effectFilters) {
      let effectFilters = "";
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rootNode
        .findOne("defs")!
        .children()
        .forEach(filterElement => (effectFilters += filterElement.svg()));
      _vectorElement.data.effectFilters = effectFilters;
    }

    set(state => ({
      ...state,
      elements: insertItem(state.elements, vectorElementIndex, _vectorElement)
    }));
  });
};

function addPrefixToSvgIDs(svgString: string, prefix: string): Element {
  const rootNode = SVG(svgString);
  // add prefix to id to make it globally unique.
  // save the mapping for reference substitution.
  const originalIDs = new Map<string, string>();
  rootNode.each((i, children) => {
    const element = children[i];
    const id = element.attr("id");
    if (!id) {
      return;
    }
    const uniqueID = `${prefix}--${id}`;
    originalIDs.set(`#${id}`, `#${uniqueID}`);
    element.attr("id", uniqueID);
  }, true);

  // traverse all attribute to find if any reference exists
  // and global id mapping is also exists then replace it.
  rootNode.each((i, children) => {
    const element = children[i];
    Object.entries(element.attr()).forEach(x => {
      const [name, value] = x;
      if (typeof value === "string" || value instanceof String) {
        const newValue = value.replace(/#[\w_-]+/g, x => {
          const uniqueID = originalIDs.get(x);
          return uniqueID ? uniqueID : x;
        });
        element.attr(name, newValue);
      }
    });
  }, true);
  return rootNode;
}

function insertItem<T>(arr: Array<T>, itemIndex: number, newItem: T): Array<T> {
  return [...arr.slice(0, itemIndex), newItem, ...arr.slice(itemIndex + 1)];
}

// url(#pattern1) --> #pattern1
function getPatternIdFromFill(wrappedFillValue: string) {
  const matches = wrappedFillValue.match(bracketsRegex);
  if (matches == null || matches.length < 2) {
    return null;
  }
  return matches[1];
}

const bracesRegex = /{([^{}]+)}/g;

/**
 * Validates the datapoint source ids match data sources on the template
 * Example string: "{someid:DATA_POINT} some text {someotherid:DATA_POINT}"
 * @param content
 * @param sourceIds
 * @returns {Boolean}
 */
export function validateContentDataSource(
  content: string,
  sourceIds: Array<string>
) {
  const matches = [...content.matchAll(bracesRegex)].map(match => match[1]);
  if (matches == null || matches.length == 0) {
    return true;
  }
  return matches.every(match => {
    const [sourceId] = match.split(":");
    return sourceIds.includes(sourceId);
  });
}
