import { FontPreviewInline } from "./fontsPreview";
import { formatDate } from "../../lib/format-date";

import {
  Card,
  Box,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Collapse,
  TableContainer,
  LinearProgress
} from "@mui/material";
import React, { useState } from "react";
import { BrandKitTypes } from "@adflow/graphqlgen";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

export function FontsTable(props: {
  rows:
    | BrandKitTypes.GetFontFamiliesByOwnerQuery["getFontFamiliesByOwner"]
    | [];
  loading: boolean;
}) {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleRowClick = (name: string, index: number) => {
    setExpandedRows(prev => ({
      [name + index]: !prev[name + index]
    }));
  };

  return (
    <div
      style={{
        minWidth: 650,
        minHeight: 466,
        height: 466
      }}
    >
      <TableContainer
        component={Card}
        variant='outlined'
        sx={{
          maxHeight: "466px",
          minWidth: 650,
          minHeight: "280px",
          overflowY: "scroll"
        }}
      >
        <Table size='small' stickyHeader aria-label='font table'>
          <TableHead sx={{ height: "48px" }}>
            <TableRow>
              {["Name", "Number of Variants", "Created"].map(header => (
                <FontsTableHead key={header}>{header}</FontsTableHead>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.loading && (
              <TableRow>
                <TableCell
                  colSpan={3}
                  sx={{ width: "100%", padding: "0 !important" }}
                >
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}

            {props.rows.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow
                  data-testid={`${row.name}-font-row`}
                  onClick={() => handleRowClick(row.name, index)}
                  sx={{
                    "& > td": {
                      height: "48px",
                      border: "none",
                      fontSize: "14px"
                    },
                    ":hover": {
                      backgroundColor: "#F4F4F4"
                    },
                    cursor: "pointer",
                    backgroundColor: expandedRows[row.name + index]
                      ? "#F4F4F4"
                      : "white"
                  }}
                >
                  <TableCell align='left' width={300}>
                    <Box
                      data-testid='row-name'
                      display='flex'
                      alignItems='center'
                    >
                      {expandedRows[row.name + index] ? (
                        <KeyboardArrowDown fontSize='small' />
                      ) : (
                        <KeyboardArrowRight fontSize='small' />
                      )}
                      {row.name}
                    </Box>
                  </TableCell>

                  <TableCell
                    data-testid='row-variant-count'
                    align='left'
                    width={300}
                  >
                    {row.fonts?.length}
                  </TableCell>
                  <TableCell data-testid='row-created-date' align='left'>
                    {formatDate(new Date(row.createdAt))}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    ":last-child": {
                      "& > td": {
                        border: "none"
                      }
                    },
                    "& > td": {
                      padding: 0
                    }
                  }}
                >
                  <TableCell colSpan={3}>
                    <Collapse
                      in={expandedRows[row.name + index]}
                      timeout={{ enter: 200, exit: 200 }}
                      easing={{ enter: "ease-out", exit: "ease-in" }}
                      unmountOnExit
                    >
                      <Table size='small' sx={{ width: "100%" }}>
                        <TableBody
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            backgroundColor: "#F4F4F4"
                          }}
                        >
                          <TableRow
                            sx={{
                              "& > td": {
                                padding: 2,
                                border: "none"
                              }
                            }}
                          >
                            {row.fonts.map(font => (
                              <TableCell
                                key={font.variant}
                                align='left'
                                width={150}
                                sx={{
                                  border: "none"
                                }}
                              >
                                <FontPreviewInline
                                  family={row.name}
                                  variant={font.variant}
                                  src={font.path}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function FontsTableHead({ children }: { children: React.ReactNode }) {
  return (
    <TableCell
      color='#00003C'
      align='left'
      sx={{ paddingBlock: "0", color: "#00003C" }}
    >
      {children}
    </TableCell>
  );
}
