import {
  Flex,
  IconButton,
  Input,
  ListIcon,
  ListItem,
  Popover,
  PopoverTrigger,
  Text,
  useBoolean,
  useDisclosure,
  useOutsideClick
} from "@chakra-ui/react";

import {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { IconType } from "react-icons";
import { shallow } from "zustand/shallow";
import { useStore } from "../../hooks/useStore";
import { DeleteConfirmationPopoverContent } from "../DeleteConfirmation";
import { LuTrash2 } from "react-icons/lu";

type Props = {
  itemId: string;
  name: string;
  elementIcon: IconType;
};

const ElementItem: FC<PropsWithChildren<Props>> = ({
  itemId,
  name,
  elementIcon
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const listItemRef = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDeleteShown, setIsDeleteShown] = useState(false);
  const s = useStore(
    state => ({
      elements: state.elements,
      updateElementName: state.updateElementName,
      selectedElementId: state.selectedElementId,
      setSelectedElement: state.setElementSelected,
      removeElement: state.removeElement
    }),
    shallow
  );
  const [showEdit, setShowEdit] = useBoolean();
  const [newName, setNewName] = useState(name);

  const elementType = useMemo(() => {
    const element = s.elements.find(el => el.id === itemId);
    return element?.type;
  }, [itemId, s.elements]);

  const handleClick = useCallback(() => {
    s.setSelectedElement(itemId);
    listItemRef.current?.focus();
  }, [itemId, s]);

  const handleDoubleClick: MouseEventHandler<HTMLLIElement> =
    useCallback(() => {
      s.setSelectedElement(itemId);
      setShowEdit.on();
    }, [s, itemId, setShowEdit]);

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    evt => {
      evt.stopPropagation();
      if (evt.key !== "Enter" && evt.key !== "Escape") {
        return;
      }
      if (evt.key === "Enter") {
        s.updateElementName(itemId, newName);
      }
      if (evt.key === "Escape") {
        setNewName(name);
      }
      setShowEdit.off();
    },
    [itemId, name, newName, s, setShowEdit]
  );

  const handleListItemKeyDown: KeyboardEventHandler<HTMLLIElement> =
    useCallback(
      evt => {
        if (evt.key === "Backspace" && s.selectedElementId === itemId) {
          onOpen();
        }
      },
      [onOpen, s.selectedElementId, itemId]
    );

  const handleDelete = useCallback(() => {
    s.removeElement(itemId);
  }, [itemId, s]);

  useOutsideClick({
    ref: inputRef,
    handler: () => {
      setNewName(name);
      setShowEdit.off();
    }
  });

  useEffect(() => {
    if (showEdit && inputRef.current) {
      inputRef.current.selectionStart = 0;
      inputRef.current.selectionEnd = -1;
      inputRef.current.focus();
      // Return focus to the list item when the user
      // has finished editing the element's name
    } else if (!showEdit && listItemRef.current) {
      listItemRef.current.focus();
    }
  }, [showEdit]);

  return (
    <DeletePopover isOpen={isOpen} onClose={onClose} onDelete={handleDelete}>
      <ListItem
        ref={listItemRef}
        pl={4}
        py={0}
        height={8}
        key={itemId}
        display={"flex"}
        alignItems={"center"}
        onClick={handleClick}
        _hover={{
          outlineWidth: 1,
          outlineColor: "blue.100",
          outlineStyle: "solid",
          outlineOffset: "inset"
        }}
        data-testid={`element-${elementType}`}
        color={itemId === s.selectedElementId ? "blue.600" : "inherit"}
        bg={itemId === s.selectedElementId ? "blue.50" : "inherit"}
        cursor='pointer'
        onDoubleClick={handleDoubleClick}
        onKeyDown={handleListItemKeyDown}
        onMouseOver={() => setIsDeleteShown(true)}
        onMouseLeave={() => setIsDeleteShown(false)}
        tabIndex={-1}
      >
        <Flex alignItems={"center"} width='100%'>
          <ListIcon as={elementIcon} mr='0.5' />
          {showEdit ? (
            <Input
              ref={inputRef}
              marginLeft='-1px'
              marginRight='1px'
              size='xs'
              fontSize='sm'
              value={newName}
              onKeyDown={handleOnKeyDown}
              onChange={event => setNewName(event.target.value)}
              onClick={evt => {
                console.log("stop bubble");
                evt.stopPropagation();
              }}
            />
          ) : (
            <Text
              cursor='text'
              flex={1}
              pl='2'
              as='span'
              fontSize='sm'
              maxW='100%'
              overflow='hidden'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
            >
              {name}
            </Text>
          )}
          <IconButton
            ml={2}
            mr={1}
            visibility={isDeleteShown ? "visible" : "hidden"}
            size='xs'
            icon={<LuTrash2 size='16' />}
            aria-label='trash-icon'
            onClick={onOpen}
            colorScheme='red'
            variant='ghost'
            rounded='full'
          />
        </Flex>
      </ListItem>
    </DeletePopover>
  );
};

ElementItem.displayName = "ElementItem";

export default ElementItem;

type DeletePopoverProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}>;

const DeletePopover: FC<DeletePopoverProps> = ({
  isOpen,
  onClose,
  onDelete,
  children
}) => {
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement='right'
      isLazy
      autoFocus
      returnFocusOnClose
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <DeleteConfirmationPopoverContent
        initialFocusRef={initialFocusRef}
        onClose={onClose}
        confirmationText='Are you sure you want to delete this element?'
        onConfirm={onDelete}
      />
    </Popover>
  );
};
