import { FC } from "react";
import { OverlayProps, getElementIdsAndPositions } from "../../types";
import SelectOverlayElement from "../SelectOverlayElement";

const SelectOverlay: FC<OverlayProps> = ({ adElements }) => {
  const selectArray = Object.values(getElementIdsAndPositions(adElements));
  return (
    <>
      {selectArray.map((element, index) => {
        return (
          <SelectOverlayElement
            key={element.id}
            adElement={element}
            elementIndex={index}
          />
        );
      })}
    </>
  );
};

SelectOverlay.displayName = "SelectOverlay";

export default SelectOverlay;
